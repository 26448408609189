import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Container, Row, Col, Button} from "reactstrap";


import FormList from "../../components/FormList";
import ListErrores from "./ListErrores";
import ListRankingErrores from "./ListRankingErrores";

class DashboardErrores extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      
      component: "DashboardErrores",

    }
  }

  render () {

    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Errores</h1>
        <ListErrores />
        <ListRankingErrores />
      </Container>
    );
  }
}

export default DashboardErrores;
