import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  landing as landingRoutes,
  dashboard as dashboardRoutes,
  page as pageRoutes,
} from "./index";

import DashboardLayout from "../layouts/Dashboard";
import LandingLayout from "../layouts/Landing";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";

import ScrollToTop from "../components/ScrollToTop";

import LoginSignIn from "../login/LoginSignIn";
import LoginForgot from "../login/LoginForgot";
import LoginPassword from "../login/LoginPassword";

import EditRegister from "../registro/EditRegister";
import ConfirmRegister from "../registro/ConfirmRegister";

class RoutesLogin extends React.Component {

  render() {
   return (
      <Router>
        <ScrollToTop>
          <Switch>       
            {/* <Route
              key={101}
              path="/"
              exact
              render={(props) => (
                <AuthLayout>
                  <FormEditSignIn  {...props}/> 
                </AuthLayout>
              )}
            /> */}
            <Route
              key={201}
              path="/"
              exact
              render={(props) => (
                <AuthLayout>
                  <LoginSignIn  {...props}/> 
                </AuthLayout>
              )}
            />
            <Route
              key={202}
              path="/olvide/"
              exact
              render={(props) => (
                <AuthLayout>
                  <LoginForgot  {...props}/> 
                </AuthLayout>
              )}
            />
            <Route
              key={203}
              path="/contrasenya/:keystring"
              exact
              render={(props) => (
                <AuthLayout>
                  <LoginPassword  {...props}/> 
                </AuthLayout>
              )}
            />
            <Route
              key={204}
              path="/registro/:keystring"
              exact
              render={(props) => (
                <AuthLayout>
                  <EditRegister  {...props}/> 
                </AuthLayout>
              )}
            />
            <Route
              key={205}
              path="/registro/confirmar/:keystring"
              exact
              render={(props) => (
                <AuthLayout>
                  <ConfirmRegister  {...props}/> 
                </AuthLayout>
              )}
            />
          </Switch>
        </ScrollToTop>
      </Router>
    );
  }

}

export default RoutesLogin;
