import React from "react";
// import { connect } from "react-redux";
// import { toggleSidebar } from "../redux/actions/sidebarActions";

import {
  Row,
  Col,
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  ListGroupItem,
  Form,
  Input
} from "reactstrap";

// import {
//   AlertCircle,
//   Bell,
//   BellOff,
//   Home,
//   MessageCircle,
//   PieChart,
//   Settings,
//   User,
//   UserPlus
// } from "react-feather";

// import enFlag from "../assets/img/flags/gb.png";
// import esFlag from "../assets/img/flags/es.png";
// import deFlag from "../assets/img/flags/de.png";

// import avatar1 from "../assets/img/avatars/avatar.jpg";
// import avatar3 from "../assets/img/avatars/avatar-3.jpg";
// import avatar4 from "../assets/img/avatars/avatar-4.jpg";
// import avatar5 from "../assets/img/avatars/avatar-5.jpg";

class NavbarDropdownItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
    // const NavbarDropdownItem = ({ icon, title, description, time, spacing }) => (
  }
  render() {
    
    return (
      //<span>Navbar Dropdown item</span>
         <ListGroupItem>
           <Row noGutters className="align-items-center">
             <Col xs={2}>{this.props.icon}</Col>
             <Col xs={10} className={this.props.spacing ? "pl-2" : null}>
               <div className="text-dark">{this.props.title}</div>
               <div className="text-muted small mt-1">{this.props.description}</div>
               <div className="text-muted small mt-1">{this.props.time}</div>
             </Col>
           </Row>
         </ListGroupItem>
    );
  }
}
export default NavbarDropdownItem;