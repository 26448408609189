import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  landing as landingRoutes,
  dashboard as dashboardRoutes,
  page as pageRoutes
} from "./index";

import DashboardLayout from "../layouts/Dashboard";
import LandingLayout from "../layouts/Landing";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";

import ScrollToTop from "../components/ScrollToTop";

import EditThreads from "../assistants/threads/EditThreads";

import EditTarifas from "../productos/tarifas/EditTarifas";
import EditFamilias from "../productos/familias/EditFamilias";
import EditProductos from "../productos/productos/EditProductos";
import EditProductosEnvases from "../productos/productos/EditProductosEnvases";
import EditProductosEnvasesDescuentos from "../productos/productos/EditProductosEnvasesDescuentos";
import EditProductosRendimientos from "../productos/productos/EditProductosRendimientos";

import EditProductosAgrupados from "../productos/agrupados/EditProductosAgrupados";
import EditProductosAgrupadosProductos from "../productos/agrupados/EditProductosAgrupadosProductos";
import EditProductosAgrupadosRendimientos from "../productos/agrupados/EditProductosAgrupadosRendimientos";

import EditSistemas from "../sistemas/sistemas/EditSistemas";
import EditDocumentos from "../sistemas/documentos/EditDocumentos";
import EditDocumentosSistemas from "../sistemas/documentos/EditDocumentosSistemas";
import EditDocumentosFamilias from "../sistemas/documentos/EditDocumentosFamilias";
import EditDocumentosProductos from "../sistemas/documentos/EditDocumentosProductos";

import EditGuias from "../guias/guias/EditGuias";
import EditGuiasImagenes from "../guias/guias/EditGuiasImagenes";
import EditGuiasProductos from "../guias/guias/EditGuiasProductos";
import EditGuiasSistemas from "../guias/guias/EditGuiasSistemas";
import EditGuiasFamilias from "../guias/guias/EditGuiasFamilias";
import EditGuiasProductosRel from "../guias/guias/EditGuiasProductosRel";
import EditGuiasTextos from "../guias/textos/EditGuiasTextos";

import EditSliderFakolith from "../recursos/slider_fakolith/EditSliderFakolith";
import EditSliderFakolithIng from "../recursos/slider_fakolith_ing/EditSliderFakolithIng";
import EditSliderPinturaalimentaria from "../recursos/slider_pinturaalimentaria/EditSliderPinturaalimentaria";
import EditSliderFoodgradepaint from "../recursos/slider_foodgradepaint/EditSliderFoodgradepaint";
import EditLogos from "../recursos/logos/EditLogos";
import EditImagenes from "../recursos/imagenes/EditImagenes";
import EditVideos from "../recursos/videos/EditVideos";
import EditVideosSistemas from "../recursos/videos/EditVideosSistemas";
import EditVideosFamilias from "../recursos/videos/EditVideosFamilias";
import EditVideosProductos from "../recursos/videos/EditVideosProductos";

import EditNoticias from "../blog/noticias/EditNoticias";
import EditNoticiasSistemas from "../blog/noticias/EditNoticiasSistemas";
import EditNoticiasFamilias from "../blog/noticias/EditNoticiasFamilias";
import EditNoticiasProductos from "../blog/noticias/EditNoticiasProductos";
import EditPosts from "../blog/posts/EditPosts";
import EditPostsEtiquetas from "../blog/posts/EditPostsEtiquetas";
import EditPostsSistemas from "../blog/posts/EditPostsSistemas";
import EditPostsFamilias from "../blog/posts/EditPostsFamilias";
import EditPostsProductos from "../blog/posts/EditPostsProductos";
import EditEtiquetas from "../blog/etiquetas/EditEtiquetas";
import EditMailings from "../blog/mailings/EditMailings";
import EditMailingsPosts from "../blog/mailings/EditMailingsPosts";

import EditEnlaces from "../landings/enlaces/EditEnlaces";
import EditLandings from "../landings/landings/EditLandings";
import EditLandingsEnlaces from "../landings/landings/EditLandingsEnlaces";

import EditAccesosPresupuestos from "../presupuestos/accesos/EditAccesosPresupuestos";
import EditErroresPresupuestos from "../presupuestos/errores/EditErroresPresupuestos";
import EditAccionesPresupuestos from "../presupuestos/acciones/EditAccionesPresupuestos";
import EditEmpresas from "../presupuestos/empresas/EditEmpresas";
import EditEmpresasTiendas from "../presupuestos/empresas/EditEmpresasTiendas";
import EditEmpresasUsuarios from "../presupuestos/empresas/EditEmpresasUsuarios";
import EditEmpresasPresupuestos from "../presupuestos/empresas/EditEmpresasPresupuestos";
import EditUsuarios from "../presupuestos/usuarios/EditUsuarios";
import EditUsuariosPresupuestos from "../presupuestos/usuarios/EditUsuariosPresupuestos";
import EditPresupuestos from "../presupuestos/presupuestos/EditPresupuestos";
import EditProvincias from "../presupuestos/provincias/EditProvincias";
import EditCampanyas from "../presupuestos/campanyas/EditCampanyas";

import EditDuplicarTablas from "../configuracion/duplicar/EditDuplicarTablas";
import EditDuplicarTablasRelaciones from "../configuracion/duplicar/EditDuplicarTablasRelaciones";
import EditAccesos from "../observabilidad/accesos/EditAccesos";
import EditErrores from "../observabilidad/errores/EditErrores";
import EditAcciones from "../observabilidad/acciones/EditAcciones";

import LoginPassword from "../login/LoginPassword";
import EditRegister from "../registro/EditRegister";
import ConfirmRegister from "../registro/ConfirmRegister";
import EditApplications from "../knowledge/applications/EditApplications";
import EditProductosAplicaciones from "../productos/productos/EditProductosAplicaciones";

const childRoutes = (Layout, routes) =>
  routes.map(({ children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, component: Component }, index) => (
        <Route
          key={index}
          path={path}
          exact
          render={props => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ))
    ) : (
      // Route item without children
      <Route
        key={index}
        path={path}
        exact
        render={props => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );  

const Routes = () => (
  <Router>
    <ScrollToTop>
      <Switch>
        {childRoutes(LandingLayout, landingRoutes)}
        {childRoutes(DashboardLayout, dashboardRoutes)}
        {childRoutes(AuthLayout, pageRoutes)}

        {/******** RUTAS DE EDITAR NO VISIBLES DESDE EL MENÚ PRINCIPAL ******/}

        <Route
          key={101}
          path="/productos/tarifas/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditTarifas {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={102}
          path="/productos/familias/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditFamilias {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={103}
          path="/productos/productos/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditProductos {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={104}
          path="/productos/productos/envases/:id_producto/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditProductosEnvases {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={105}
          path="/productos/productos/descuentos/:id_producto/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditProductosEnvasesDescuentos {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={106}
          path="/productos/productos/rendimientos/:id_producto/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditProductosRendimientos {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={107}
          path="/productos/agrupados/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditProductosAgrupados {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={108}
          path="/productos/agrupados/productos/:id_agrupado/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditProductosAgrupadosProductos {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={109}
          path="/productos/agrupados/rendimientos/:id_producto/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditProductosAgrupadosRendimientos {...props} />
            </DashboardLayout>
          )}
        />

        <Route
          key={201}
          path="/sistemas/sistemas/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditSistemas {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={202}
          path="/sistemas/documentos/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditDocumentos {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={203}
          path="/sistemas/documentos/sistemas/:id_documento/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditDocumentosSistemas {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={204}
          path="/sistemas/documentos/familias/:id_documento/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditDocumentosFamilias {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={205}
          path="/sistemas/documentos/productos/:id_documento/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditDocumentosProductos {...props} />
            </DashboardLayout>
          )}
        />

        <Route
          key={301}
          path="/guias/guias/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditGuias {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={302}
          path="/guias/guias/imagenes/:id_guia/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditGuiasImagenes {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={303}
          path="/guias/guias/productos/:id_guia/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditGuiasProductos {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={304}
          path="/guias/guias/sistemas/:id_guia/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditGuiasSistemas {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={305}
          path="/guias/guias/familias/:id_guia/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditGuiasFamilias {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={306}
          path="/guias/guias/productos_rel/:id_guia/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditGuiasProductosRel {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={307}
          path="/guias/textos/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditGuiasTextos {...props} />
            </DashboardLayout>
          )}
        />

        <Route
          key={401}
          path="/recursos/slider_fakolith/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditSliderFakolith {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={402}
          path="/recursos/slider_fakolith_ing/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditSliderFakolithIng {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={403}
          path="/recursos/slider_pinturaalimentaria/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditSliderPinturaalimentaria {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={404}
          path="/recursos/slider_foodgradepaint/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditSliderFoodgradepaint {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={405}
          path="/recursos/imagenes/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditImagenes {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={406}
          path="/recursos/logos/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditLogos {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={407}
          path="/recursos/videos/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditVideos {...props} />
            </DashboardLayout>
          )}
        />        
        <Route
          key={408}
          path="/recursos/videos/sistemas/:id_video/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditVideosSistemas {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={409}
          path="/recursos/videos/familias/:id_video/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditVideosFamilias {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={410}
          path="/recursos/videos/productos/:id_video/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditVideosProductos {...props} />
            </DashboardLayout>
          )}
        />

        <Route
          key={501}
          path="/blog/noticias/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditNoticias {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={502}
          path="/blog/noticias/sistemas/:id_noticia/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditNoticiasSistemas {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={503}
          path="/blog/noticias/familias/:id_noticia/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditNoticiasFamilias {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={504}
          path="/blog/noticias/productos/:id_noticia/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditNoticiasProductos {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={505}
          path="/blog/posts/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditPosts {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={506}
          path="/blog/posts/etiquetas/:id_post/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditPostsEtiquetas {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={507}
          path="/blog/posts/sistemas/:id_post/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditPostsSistemas {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={508}
          path="/blog/posts/familias/:id_post/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditPostsFamilias {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={509}
          path="/blog/posts/productos/:id_post/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditPostsProductos {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={510}
          path="/blog/etiquetas/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditEtiquetas {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={511}
          path="/blog/mailings/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditMailings {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={512}
          path="/blog/mailings/posts/:id_mailing/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditMailingsPosts {...props} />
            </DashboardLayout>
          )}
        />

        <Route
          key={609}
          path="/presupuestos/accesos/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditAccesosPresupuestos {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={610}
          path="/presupuestos/errores/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditErroresPresupuestos {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={611}
          path="/presupuestos/acciones/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditAccionesPresupuestos {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={601}
          path="/presupuestos/provincias/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditProvincias {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={602}
          path="/presupuestos/campanyas/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditCampanyas {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={602}
          path="/presupuestos/empresas/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditEmpresas {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={603}
          path="/presupuestos/empresas/tiendas/:id_empresa/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditEmpresasTiendas {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={604}
          path="/presupuestos/empresas/usuarios/:id_empresa/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditEmpresasUsuarios {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={605}
          path="/presupuestos/empresas/presupuestos/:id_empresa/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditEmpresasPresupuestos {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={606}
          path="/presupuestos/usuarios/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditUsuarios {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={607}
          path="/presupuestos/usuarios/presupuestos/:id_usuario/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditUsuariosPresupuestos {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={608}
          path="/presupuestos/presupuestos/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditPresupuestos {...props} />
            </DashboardLayout>
          )}
        />

        <Route
          key={701}
          path="/configuracion/duplicar/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditDuplicarTablas {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={702}
          path="/configuracion/duplicar/:id_duplicar_tabla/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditDuplicarTablasRelaciones {...props} />
            </DashboardLayout>
          )}
        />

        <Route
          key={703}
          path="/observabilidad/accesos/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditAccesos {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={704}
          path="/observabilidad/errores/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditErrores {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={705}
          path="/observabilidad/acciones/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditAcciones {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={801}
          path="/landings/landings/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditLandings {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={802}
          path="/landings/enlaces/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditEnlaces {...props} />
            </DashboardLayout>
          )}
        />
        <Route
          key={803}
          path="/landings/landings/enlaces/:id_landing/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditLandingsEnlaces {...props} />
            </DashboardLayout>
          )}
        />

        <Route
          key={901}
          path="/assistants/threads/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditThreads {...props} />
            </DashboardLayout>
          )}
        />

        <Route
          key={902}
          path="/knowledge/applications/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditApplications {...props} />
            </DashboardLayout>
          )}
        />

        <Route
          key={106}
          path="/productos/productos/aplicaciones/:id_producto/:id"
          exact
          render={props => (
            <DashboardLayout>
              <EditProductosAplicaciones {...props} />
            </DashboardLayout>
          )}
        />


        {/* METEMOS AQUI TAMBIEN LAS RUTAS AL RESETEO DE CONTRASEÑA Y AL REGISTRO DEL SISTEMA POR SI TIENE TOKEN VALIDO PERO CLICA EN EL LINK DEL EMAIL PARA RESETEARLA */}
        <Route
          key={301}
          path="/contrasenya/:keystring"
          exact
          render={(props) => (
            <AuthLayout>
              <LoginPassword  {...props}/> 
            </AuthLayout>
          )}
        />
        <Route
          key={302}
          path="/registro/:keystring"
          exact
          render={(props) => (
            <AuthLayout>
              <EditRegister  {...props}/> 
            </AuthLayout>
          )}
        />
        <Route
          key={303}
          path="/registro/confirmar/:keystring"
          exact
          render={(props) => (
            <AuthLayout>
              <ConfirmRegister  {...props}/> 
            </AuthLayout>
          )}
        />

        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />        
      </Switch>
    </ScrollToTop>
  </Router>
);

export default Routes;
