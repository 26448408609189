import React from "react";
import Chart from "react-apexcharts";
import { connect } from "react-redux";

import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

const GraficoLineas = ({ theme }) => {
  const data = [
    {
      name: "2020",
      data: [45, 52, 38, 24, 33, 26, 21, 20, 36, 38, 45, 50]
    },
    {
      name: "2019",
      data: [35, 31, 42, 42, 13, 18, 19, 17, 26, 41, 32, 35]
    },
    // {
    //   name: "Total Visits",
    //   data: [87, 57, 74, 99, 75, 38, 62, 47, 82, 56, 45, 47]
    // }
  ];

  const options = {
    chart: {
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: [5, 7, 5],
      curve: "straight",
      dashArray: [0, 8, 5]
    },
    markers: {
      size: 0,
      style: "hollow" // full, hollow, inverted
    },
    xaxis: {
      categories: [
        "01 Jan",
        "02 Feb",
        "03 Mar",
        "04 Apr",
        "05 May",
        "06 Jun",
        "07 Jul",
        "08 Aug",
        "09 Sep",
        "10 Oct",
        "11 Nov",
        "12 Dec"
      ]
    },
    tooltip: {
      y: [
        // {
        //   title: {
        //     formatter: function(val) {
        //       return val + " (mins)";
        //     }
        //   }
        // },
        // {
        //   title: {
        //     formatter: function(val) {
        //       return val + " per session";
        //     }
        //   }
        // },
        // {
        //   title: {
        //     formatter: function(val) {
        //       return val;
        //     }
        //   }
        // }
      ]
    },
    grid: {
      borderColor: "#f1f1f1"
    },
    colors: [
      theme.primary,
      theme.success,
      theme.warning,
      theme.danger,
      theme.info
    ]
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h5">Unidades presupuestadas</CardTitle>
        <h6 className="card-subtitle text-muted">
          Desglose mensual del total de unidades presupuestadas en el software online de presupuestos.
        </h6>
      </CardHeader>
      <CardBody>
        <div className="chart">
          <Chart options={options} series={data} type="line" height="350" />
        </div>
      </CardBody>
    </Card>
  );
};

export default connect(store => ({
  theme: store.theme.currentTheme
}))(GraficoLineas);
