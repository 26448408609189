import React from "react";
// import { connect } from "react-redux";
// import { toggleSidebar } from "../redux/actions/sidebarActions";

import {
  // Row,
  // Col,
  // Collapse,
  // Navbar,
  // Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  Col,
  // Icon,
  // ListGroupItem,
  // Form,
  // Input
} from "reactstrap";

import {
  AlertCircle,
  Bell,
  BellOff,
  Home,
  MessageCircle,
  PieChart,
  Settings,
  User,
  UserPlus
} from "react-feather";

// import enFlag from "../assets/img/flags/gb.png";
// import esFlag from "../assets/img/flags/es.png";
// import deFlag from "../assets/img/flags/de.png";

// import avatar1 from "../assets/img/avatars/avatar.jpg";
// import avatar3 from "../assets/img/avatars/avatar-3.jpg";
// import avatar4 from "../assets/img/avatars/avatar-4.jpg";
// import avatar5 from "../assets/img/avatars/avatar-5.jpg";


class NavbarDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {

    const Icon = this.props.icon;

    return (
      
      <UncontrolledDropdown nav inNavbar className="mr-2">
        <DropdownToggle nav className="nav-icon dropdown-toggle">
              <div className="position-relative">
              <Icon className="align-middle" size={18} />
              {this.props.showBadge ? <span className="indicator">{this.props.count}</span> : null}
            </div>
          </DropdownToggle>
          <DropdownMenu right className="dropdown-menu-lg py-0">
            <div className="dropdown-menu-header position-relative">
              {this.props.count} {this.props.header}
            </div>
            <ListGroup>{this.props.children}</ListGroup>
            <DropdownItem header className="dropdown-menu-footer">
              <span className="text-muted">{this.props.footer}</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
    );
  }
}
export default NavbarDropdown;