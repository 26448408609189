/*

FORM LOGIN

COMPONENTE BASADO EN LA FUNCIONALIDAD DEL COMPONENTE Formulario PERO REDUCIDO A LO NECESARIO PARA MONTAR LOS FORMULARIOS DE SIGNIN Y RECORDAR CONTRASEÑA


ULTIMAS MODFIFICACIONES:

  - 2021-07-13 > (OBSOLETO) AÑADIDA PROPIEDAD isSignInForm PARA SUSTITUIR handleValidSubmit POR handleValidSignInSubmit
  - 2021-07-16 > AÑADIDA VALIDACION DE CAMPO EMAIL AL ENVIAR
  - 2022-01-10 > HACEMOS fetchApi EN EL componentDidMount PARA IGUALAR CON Formulario.js A ARCHIVO DE ACCIONES CON "cargar_XXX"
  - 2022-03-27 > AÑADIDAS PROPIEDAD Y VARIABLE ESTADO dataIncludeParentComponent, dataIncludeComponent PARA INCLUIR EN FETCH VARIBLES TIPO HIDDEN Y READONLY

(VER DOCUMENTACION COMPONENTE Formulario)

DIFERENCIAS:

  - action > AHORA DEPENDE DEL COMPONENTE Y SE PASA COMO PROPIEDAD
  - isLoginForm > VARIABLE DEL ESTADO PARA PREGUNTAR CONFIRMACION DE REDIRECCION EN EL handleValidSubmit
  - isSignInForm > VARIABLE DEL ESTADO PARA GUARDAR O NO EL TOKEN EN EL handleValidSubmit
  - (*OBSOLETO*) componentDidMount > NO HACE FETCH, SOLO INICIALIZA EL ESTADO CON initialState PARA LOS CAMPOS CON UN defaultValue
  - (*OBSOLETO*) handleValidSignInSubmit > NUEVA FUNCION QUE SUSTITUYE A handleValidSubmit SI ES EL isSignInForm PARA GUARDAR EN localStorage O sessionStorage LOS DATOS DE IDENTIFICACION DEL USUARIO
  - render > CAMBIA LA MAQUETACION DE LOS CAMPOS PARA ADAPTARLA A ESTE TIPO DE FORMULARIOS

*/

import React from "react";
import { Link, withRouter, Router, Redirect } from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faTrashAlt, faSave, faUndo, faReply, faHistory, faArrowLeft, faCopy, faArrowAltCircleLeft, faFolderOpen} from "@fortawesome/free-regular-svg-icons";
//import { faUndo } from "@fortawesome/fontawesome-common-types";
import { faExclamationCircle, faPaperPlane } from "@fortawesome/free-solid-svg-icons";

// import {
//   AvForm,
//   AvField
//   // AvGroup,
//   AvInput
//   // AvFeedback,
//   // AvRadioGroup,
//   // AvRadio,
//   // AvCheckboxGroup,
//   // AvCheckbox
// } from "availity-reactstrap-validation";
import {
  // Card,
  // CardBody,
  // CardHeader,
  // CardTitle,
  Col,
  // Container,
  Button,
  Label,
  Form,
  FormGroup,
  Input,
  CustomInput,
  Modal,
  // ModalBody,
  // ModalFooter,
  // ModalHeader,
  Spinner,
  UncontrolledAlert,
} from "reactstrap";

import { toastr } from "react-redux-toastr";

// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import { Editor } from "@tinymce/tinymce-react";
import TinyEditor from './TinyEditor';

import Select from "react-select";
import Listado from "./FormList";
import InputFile from "./InputFile";
import InputImage from "./InputImage";
import InputImageFile from "./InputImageFile";
import InputEmail from "./InputEmail";
import ModalWindow from "./ModalWindow";
import { Alert } from "bootstrap";

import {
  modalToggle,
  modalCancel,
  modalConfirm,
  handleFileChange,
  handleFileDelete,
  handleImageChange,
  handleImageFileChange,
  handleImageDelete,
  handleImageFileDelete,
  handleDelete,
  handleDuplicate,
  handleSendEmail,
  deleteRegister,
  duplicateRegister,
  sendRegister,
  redirectDuplicateRegister,
  redirectSendRegister,
  continueRegister,
  loadingCompleted,
  updateForm,
  initialState,

  handleValidSubmit,
  handleChange,
  handleKeyDownOnNumber,
  handleChangeOnNumber,
  handleChangeOnSelect,
  handleChangeOnEditor,
  handleChangeOnCheckbox,
  handleChangeOnSwitch,
  handleChangeOnEmail,
  handleErrors,
  showToastr,
  validateEmail,
  validateFields,
  excludeDataFetch,
  fetchApi,
} from "./Functions";

class FormLogin extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      
      // CAMPOS VENTANA MODAL 
      modalType: "",
      modalTitle: "",
      modalText: "",
      modalIsOpen: false,

      // CAMPOS LOADING
      isLoading: true,
      isLoadingModal: true,

      // CAMPOS ACCIONES 
      action: props.action,

      // // CAMPOS FUNCIONES ELIMINAR
      // deleteType: "",
      // numeroEliminar: "",

      // // OTROS CAMPOS
      // updateLists: false,

      // // CAMPOS QUE ESTABAN EN props ANTES
      component: props.component,
      // tablename: props.tablename,
      // id: props.idValue,
      // idParent: props.idParent,
      // idField: props.idField,
      // deleteField: props.deleteField,
      // numberFiles: props.numberFiles,
      // numberImages: props.numberImages,
      // numberImageFiles: props.numberImageFiles,
      // autoSave: props.autoSave,
      // updateListForm: props.updateListForm,
      initialFetch: props.initialFetch,
      isLoginForm: true,
      isSignInForm: props.isSignInForm,

      // CAMPOS ERRORES (DEBEN CONTENER "error" AL PRINCIPIO PARA QUE NO SE EXCLUYAN AL ENVIAR A LA API)
      errorValidation: false,
      errorForm: "",
      errorGeneral: false,
      toastrIsOpen: false,

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES A EXCLUIR DEL ENVIO A LA API
      dataExcludeParentComponent: props.dataExcludeParentComponent,
      dataExcludeComponent: [
        "token",
        "modalType",
        "modalTitle",
        "modalText",
        "modalIsOpen",
        "isLoading",
        "isLoadingModal",
        "updateLists",
        "initialFetch",
        "isLoginForm",
        "isSignInForm",
        "errorValidation",
        "errorForm",
        "errorGeneral",
        "toastrIsOpen",
        "dataExcludeParentComponent",
        "dataExcludeComponent",
        "dataIncludeParentComponent",
        "dataIncludeComponent"
      ],

      dataIncludeParentComponent: props.dataIncludeParentComponent,
      dataIncludeComponent: [
        "id_incrementoHidden"
      ]

    };

    this.modalToggle = modalToggle.bind(this);
    this.modalCancel = modalCancel.bind(this);
    this.modalConfirm = modalConfirm.bind(this);
    this.handleFileChange = handleFileChange.bind(this);
    this.handleFileDelete = handleFileDelete.bind(this);
    this.handleImageChange = handleImageChange.bind(this);
    this.handleImageFileChange = handleImageFileChange.bind(this);
    this.handleImageDelete = handleImageDelete.bind(this);
    this.handleImageFileDelete = handleImageFileDelete.bind(this);
    this.handleDelete = handleDelete.bind(this);
    this.handleDuplicate = handleDuplicate.bind(this);
    this.handleSendEmail = handleSendEmail.bind(this);
    this.deleteRegister = deleteRegister.bind(this);
    this.duplicateRegister = duplicateRegister.bind(this);
    this.sendRegister = sendRegister.bind(this);
    this.redirectDuplicateRegister = redirectDuplicateRegister.bind(this);
    this.redirectSendRegister = redirectSendRegister.bind(this);
    this.continueRegister = continueRegister.bind(this);
    this.loadingCompleted = loadingCompleted.bind(this);
    this.updateForm = updateForm.bind(this);
    this.initialState = initialState.bind(this);
    this.handleValidSubmit = handleValidSubmit.bind(this);
    this.handleChange = handleChange.bind(this);
    this.handleKeyDownOnNumber = handleKeyDownOnNumber.bind(this);
    this.handleChangeOnNumber = handleChangeOnNumber.bind(this);
    this.handleChangeOnSelect = handleChangeOnSelect.bind(this);
    this.handleChangeOnEditor = handleChangeOnEditor.bind(this);
    this.handleChangeOnCheckbox = handleChangeOnCheckbox.bind(this);
    this.handleChangeOnSwitch = handleChangeOnSwitch.bind(this);
    this.handleChangeOnEmail = handleChangeOnEmail.bind(this);
    this.handleErrors = handleErrors.bind(this);
    this.showToastr = showToastr.bind(this);
    this.validateEmail = validateEmail.bind(this);
    this.validateFields = validateFields.bind(this);
    this.excludeDataFetch = excludeDataFetch.bind(this);
    this.fetchApi = fetchApi.bind(this);

    this.componentDidMount = this.componentDidMount.bind(this);
  }
  

  //FUNCION QUE LLAMA A LA API EN routeApiControl Y CREA LAS VARIABLES DE ESTADO CORRESPONDIENTES A LOS CAMPOS DEL REGISTRO
  async componentDidMount() {

    // alert("componentDidMount");
    // this.setState({ isLoading: true });

    // //////////ESTA ERA LA LLAMADA QUE UTILIZABA ANTES PARA LLAMAR A _genericas, etc..this.fetchApi(`${this.props.routeApiControl}?id=${this.state.id}&tablename=${this.props.tablename}`);
    // this.fetchApi(`${this.props.routeApiControl}?modified_field=id&id=${this.state.id}&id_padre=${this.props.idParent}&tablename=${this.props.tablename}`);

    this.setState({ isLoading: true });
    //alert("DIDMOUNT FORMULARIO");

    await this.initialState();

    ////if (this.props.match.params.id>0) { //TENEMOS QUE DESHABILITARLO PARA AUNQUE SEA UN ALTA CREE LAS VARIABLES EN EL STATE

    // if (this.props.idValue==0) { //INICIALIZAMOS EL MINIMO DE VALORES
    //   this.setState({id: "0"});
    //   this.setState({int_status: "3"});
    // }

    // const sentData = new FormData();
    // this.setState({ isLoadingModal: true });

    // SI NO TIENE ASOCIADO UN id NO MODIFICAMOS action PORQUE ES FORMULARIO DE LOGIN O PUBLICO
    if (this.state.id) {
      if (this.state.id == "0") {
        // alert("CREAR");
        await this.setState({ action: "create" })
      } else {
        // alert("GUARDAR");
        await this.setState({ action: "update" })
      }
    }
    
    // const sentData = JSON.stringify(this.state);
    // const sentData = JSON.stringify(this.excludeDataFetch());
    let sentData = new FormData();
    let state = JSON.stringify(this.excludeDataFetch());
    sentData.append( "state", state );
    // console.log(sentData);

    /*****VAMOS A CARGAR LAS OPCIONES DE LOS SELECTS DINAMICOS*****/
    const dynamicFields = this.props.fields.filter((field) => (field.type === "dynamicSelect" || field.type === "controlledRouteDynamicSelect"));

    if (dynamicFields.length>0) {

      let counter=0;

      dynamicFields.map((field, index) => {

        //>>>>>>>>AQUI HABRA QUE INTRODUCIR EL ID EN EL BODY DEL POST PARA CUANDO EL DESPLEGABLE DEPENDE DE UN IDPADRE <<<<<<<
        fetch(`${field.routeApi}` , {
          method: "POST",
          body: sentData,
          headers: { 
            "Token": `${this.state.token}`,
            // "Content-Type": "application/json",
            "Accept": "application/json, text-plain, */*"
          }
        }).then(receivedData => {
          return receivedData.json();
        }).then(receivedData => {

          //alert("CARGO OPCIONES EN " + `options_${field.name}` + " - INDEX: " + index);
          //field.options = receivedData;
          this.setState({ [`options_${field.name}`] : receivedData });

        }).then(() => {        

          counter++;

          // ESTO NO HACE FALTA POR EL CAMBIO DE ORDEN ENTRE SELECTS DINAMICOS Y EL RESTO DE SETSTATES
          // if (counter===dynamicFields.length) { //Si es el último ponemos el semáforo en verde
          //   this.setState({isLoading: false});
          //   this.setState({isLoadingModal: false});
          // }
          
        }).catch(error => console.log(error));
      });
    }

    //console.log(receivedData);

    if (this.state.initialFetch) {

      //CARGAMOS LOS DATOS DESDE LA API
      // alert(`${this.props.routeApiControl}?id=${this.props.idValue}`)
      await fetch(`${this.props.routeApiControl}?modified_field=id` , {
        method: "POST",
        body: sentData,
        headers: { 
          "Token": `${this.state.token}`,
          // "Content-Type": "application/json",
          "Accept": "application/json, text-plain, */*"
        }
      }).then(receivedData => {
        return receivedData.json();
      }).then(receivedData => {

        // this.setState({receivedData: receivedData});
        // console.log(receivedData);

        if (receivedData.length>0) {

          receivedData.map((data) => { 
            
            /**************ASIGNAMOS LOS VALORES DE LOS STATES DE LOS CAMPOS ***********/
            Object.entries(data).map(([key,value]) => {  
                        
              // HACEMOS UNA RECONVERSION PASANDO A CADENA Y OTRA VEZ A OBJETO PARA ASEGURARNOS DE QUE COGE LA FORMA DE OBJETO, PUES DABA ERROR EN LOS JSON ANIDADOS COMO LOS DE options_rendimiento, etc....
              let obj = JSON.parse(JSON.stringify(value));
              this.setState({ [`${key}`] : obj });
              // console.log("AL STATE:: " + `${key}` + ":" + obj );
            })
            
            /**************ASIGNAMOS LOS VALORES DE LOS STATES DE ARCHIVOS ***********/
            for (let i=1; i<=this.props.numberFiles; i++) {
              if (data[`url${i}`]) {
                this.setState({ [`url${i}`] : data[`url${i}`] });
                //this.setState({ [`route${i}`] : process.env.REACT_APP_API_DOMAIN+data[`url${i}`] }); //ASI SI QUEREMOS AÑADIR EL DOMINIO
                this.setState({ [`route${i}`] : data[`url${i}`] }); //ASI SIN DOMINIO
              } else {
                this.setState({ [`route${i}`] : "" });
              }  
              if (data[`titulo${i}`]) { 
                this.setState({ [`title${i}`] : data[`titulo${i}`] })
              } else {
                this.setState({ [`title${i}`] : "" })
              }
            }
              
            /********************VAMOS CON LAS IMAGENES ************************/          
            for (let i=1; i<=this.props.numberImages; i++) {
              if (data[`available_image${i}`] === "1") {
                this.setState({ [`availableImage${i}`] : data[`available_image${i}`] });
                //this.setState({ [`routeImage${i}`] : `${process.env.REACT_APP_API_DOMAIN}/get_img.php?id=${data.id}&n_img=${i}&tablename=img_${this.props.tablename}` }); //ASI SI QUEREMOS AÑADIR EL DOMINIO
                this.setState({ [`routeImage${i}`] : `/get_img.php?id=${data.id}&n_img=${i}&tablename=img_${this.props.tablename}` }); //ASI SIN DOMINO
                this.setState({ [`routeThumbnail${i}`] : `${process.env.REACT_APP_API_DOMAIN}/get_img.php?id=${data.id}&n_img=${i}&tablename=img_${this.props.tablename}` });
              } else {
                this.setState({ [`availableImage${i}`] : "" });
                this.setState({ [`routeImage${i}`] : "" });
                this.setState({ [`routeThumbnail${i}`] : `${process.env.REACT_APP_API_DOMAIN}/${process.env.REACT_APP_NON_AVAILABLE_IMAGE}` });
              }
            }

            /************* VAMOS CON LAS IMAGENES TIPO ARCHIVO ******************/          
            for (let i=1; i<=this.props.numberImageFiles; i++) {
              if (data[`imagen${i}`] !== "") {
                this.setState({ [`availableImage${i}`] : "1" });
                // this.setState({ [`routeImage${i}`] : `${process.env.REACT_APP_API_DOMAIN}/` + data[`imagen${i}`] }); //ASI SI QUEREMOS AÑADIR EL DOMINIO
                this.setState({ [`routeImage${i}`] : data[`imagen${i}`] }); 
                // this.setState({ [`routeThumbnail${i}`] : `${process.env.REACT_APP_API_DOMAIN}/` + data[`imagen${i}`] }); //ASI SI QUEREMOS AÑADIR EL DOMINIO
                this.setState({ [`routeThumbnail${i}`] : data[`imagen${i}`] });
              } else {
                this.setState({ [`availableImage${i}`] : "" });
                this.setState({ [`routeImage${i}`] : "" });
                this.setState({ [`routeThumbnail${i}`] : `${process.env.REACT_APP_API_DOMAIN}/${process.env.REACT_APP_NON_AVAILABLE_IMAGE}` });
              }
            }
          })

        }
        ////this.setState({isLoading: false}); //NO LO HACEMOS AQUI POR SI HAY selectsDinamicos
      }).then(() => {

        /*****VAMOS A CARGAR LOS SELECTED DE LOS SELECTS DINAMICOS*****/
        const dynamicFields = this.props.fields.filter((field) => (field.type === "dynamicSelect" || field.type === "controlledRouteDynamicSelect"));

        if (dynamicFields.length>0) {

          let counter=0;

          dynamicFields.map((field,index) => {

            //alert("LENGTH: " + this.state[`options_${field.name}`].length);
            let optionsArray = [];
            optionsArray = this.state[`options_${field.name}`];
            //alert("LENGTH: " + optionsArray.length);
            //alert("BUSCO: " + this.state[`${field.name}`]);
            //this.setState({ [`selected_${field.name}`] : optionsArray.filter(obj => obj.value === this.state[`${field.name}`]) });
            let selectedArray = [];
            if (this.state[`${field.name}`]) {
              selectedArray = optionsArray.find(x => x.value === this.state[`${field.name}`]);
            } else {
              selectedArray = {"value": "", "label": ""}
            }
            //alert("POSICION: " + posicion_selected)
            this.setState({ [`selected_${field.name}`] : selectedArray });
            //alert(this.state[`selected_${field.name}`]);
          });
        }

        // this.setState({isLoading: false});
        // this.setState({isLoadingModal: false});
        this.loadingCompleted();

      }).catch(error => console.log(error));
          
      /*} else { //del if (this.props.idValue>0)
        this.setState({id: 0});
        this.setState({int_status: 3});
        this.setState({isLoading: false});
      }*/

      // if (this.state.errorGeneral) {
      //   localStorage.clear();
      //   sessionStorage.clear();
      //   this.props.history.push("/");
      //   window.location.reload();
      // }

      // if (this.state.errorForm != "") {
      //   this.setState({isLoading: false});
      //   this.setState({isLoadingModal: false});
      //   this.showToastr("Error", this.state.errorForm);
      // }
      
    } else {
      this.loadingCompleted();
    }
    
    this.handleErrors();
  }
  
  render () {

    const { isLoading, modalType, modalTitle, modalText, modalIsOpen, isLoadingModal } = this.state;

    //if ((isLoading) || (!dataFetch.length)) { //EL isLoading SOLO NO FUNCIONA
    if (isLoading) { 
      return (
        <div className="loading">
          {/* <Modal isOpen={isLoadingModal} centered> */}
          <Spinner color="primary" type="grow" className="mr-2" size="lg" />
          {/* </Modal> */}
        </div>
      );
    } else {    
      //alert("RENDERIZO FORMULARIO");
      return (
        <div>
          {/* <Form key={0} onSubmit={ (this.state.isSignInForm) ? this.handleValidSignInSubmit : this.handleValidSubmit} > */}
          <Form key={0} onSubmit={this.handleValidSubmit} >
            { this.props.fields.map((field, index) => {
              //this.renderCampo(field, index)
              switch (field.type) {

                case "text":
                  return (                   
                    <FormGroup key={index} row hidden={field.hidden}>
                      <Label for={field.name} sm={2}>{field.label}</Label>
                      <Col sm={10}>
                        <Input
                          type="text"
                          name={field.name}
                          id={field.name}
                          defaultValue={this.state[`${field.name}`]}
                          readOnly={field.readOnly}
                        />
                      </Col>
                    </FormGroup>
                  );

                case "controlledText":
                  return (                   
                    <FormGroup key={index} hidden={this.state[`${field.name}Hidden`]}>
                      <Label for={field.name}>{field.label}</Label>
                      <Input
                        bsSize="lg"
                        type="text"
                        name={field.name}
                        placeholder={field.placeholder}
                        id={field.name}
                        value={this.state[`${field.name}`]}
                        readOnly={this.state[`${field.name}ReadOnly`]}
                        onChange={this.handleChange}
                      />
                      <Label className="text-danger" hidden={!this.state[`${field.name}Error`]} >
                        {this.state[`${field.name}Error`]}
                      </Label>
                    </FormGroup>
                  );

                case "controlledPassword":
                  return (                   
                    <FormGroup key={index} hidden={this.state[`${field.name}Hidden`]}>
                      <Label for={field.name}>{field.label}</Label>
                      <Input
                        bsSize="lg"
                        type="password"
                        name={field.name}
                        placeholder={field.placeholder}
                        id={field.name}
                        value={this.state[`${field.name}`]}
                        readOnly={field.readOnly}
                        onChange={this.handleChange}
                      />
                      <Label className="text-danger" hidden={!this.state[`${field.name}Error`]} >
                        {this.state[`${field.name}Error`]}
                      </Label>
                    </FormGroup>
                  );
  
                case "controlledInformative":
                  return (                   
                    <FormGroup key={index} hidden={this.props.informativeHidden}>
                      <Label for={field.name}>{field.label}</Label>
                      <Input
                        bsSize="lg"
                        type="text"
                        name={field.name}
                        id={field.name}
                        value={this.state[`${field.name}`]}
                        readOnly={field.readOnly}
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                  );
  
                case "select":
                  return (                   
                    <FormGroup key={index} row>
                      <Label for={field.name} sm={2}>{field.label}</Label>
                      <Col sm={10}>
                        <Select
                          name = {field.name}
                          id = {field.name}
                          //forwardref = {field.name}
                          className = "react-select-container"
                          classNamePrefix = "react-select"
                          options = {field.options}
                          defaultValue={field.options.find(obj => obj.value === this.state[`${field.name}`])}
                          selected={this.state[`${field.name}`]}
                          isSearchable
                        />
                      </Col>
                    </FormGroup>
                  );

                  case "dynamicSelect":
                    return (                   
                      <FormGroup key={index} row>
                        <Label for={field.name} sm={2}>{field.label}</Label>
                        <Col sm={10}>
                          <Select
                            name = {field.name}
                            id = {field.name}
                            //forwardref = {field.name}
                            className = "react-select-container"
                            classNamePrefix = "react-select"
                            options = {this.state[`options_${field.name}`]}
                            defaultValue = {this.state[`selected_${field.name}`]}
                            selected = {this.state[`${field.name}`]}
                            isDisabled={ field.readOnly }
                            // isClearable={!field.readOnly}
                            // isSearchable={!field.readOnly}
                            // openMenuOnClick={!field.readOnly}
                            isSearchable
                          />
                        </Col>
                      </FormGroup>
                    );

                  //ESTE TIPO VIENE DEL dynamicSelectConFuncion DE CALCULITH
                  case "controlledDynamicSelect":
                    return (                   
                      <FormGroup key={index} row hidden={this.state[`${field.name}Hidden`]}>
                        <Label for={field.name} sm={2}>{field.label}</Label>
                        <Col sm={10}>
                          <Select
                            name = {field.name}
                            id = {field.name}
                            //forwardref = {field.name}
                            className = "react-select-container"
                            classNamePrefix = "react-select"
                            options = {this.state[`options_${field.name}`]}
                            value = {this.state[`selected_${field.name}`]}
                            // onChange = {this[`${field.functionOnChange}`]}
                            // onChange = {this[`${field.functionOnChange}`]}
                            onChange = {(selectedOption) => this.handleChangeOnSelect(selectedOption,field.name)}
                            selected = {this.state[`${field.name}`]}
                            isDisabled = {field.readOnly}
                            // isClearable={!field.readOnly}
                            // isSearchable={!field.readOnly}
                            // openMenuOnClick={!field.readOnly}
                            isSearchable
                            isClearable
                          />
                          <Label className="text-danger" hidden={!this.state[`${field.name}Error`]} >
                            {this.state[`${field.name}Error`]}
                          </Label>
                        </Col>
                      </FormGroup>
                    );

                case "controlledEmail":
                  return (                   
                    <FormGroup key={index} hidden={this.state[`${field.name}Hidden`]}>
                      <Label for={field.name}>{field.label}</Label>
                      <InputEmail
                        value={this.state[`${field.name}`]}
                        placeholder={field.placeholder}
                        handleChangeOnEmail={this.handleChangeOnEmail}
                        handleSendEmail={this.handleSendEmail}
                        name={field.name}
                        bsSize="lg"
                        readOnly={field.readOnly}
                        options={field.options}
                        emailError={this.state[`${field.name}Error`]}
                      />
                    </FormGroup>
                  );
  
                case "checkbox":
                  return (
                    <FormGroup key={index} hidden={field.hidden}>
                      <CustomInput
                        type="checkbox"
                        id={field.name}
                        name={field.name}
                        label={field.label}
                        defaultChecked={field.defaultValue}
                        onChange={e =>e.target.checked ? this.setState({ [`${field.name}`]: true }) : this.setState({ [`${field.name}`]: false })}
                        disabled={field.readOnly}
                      />
                      <Label className="text-danger" hidden={!this.state[`${field.name}Error`]} >
                        {this.state[`${field.name}Error`]}
                      </Label>
                    </FormGroup>
                  );

                case "controlledCheckbox":
                  return (
                    <FormGroup key={index} hidden={field.hidden}>
                      <CustomInput
                        type="checkbox"
                        id={field.name}
                        name={field.name}
                        label={field.label}
                        defaultChecked={field.defaultValue}
                        checked={this.state[`${field.name}`]}
                        onChange={this.handleChangeOnCheckbox}
                        disabled={field.readOnly}
                      />
                      <Label className="text-danger" hidden={!this.state[`${field.name}Error`]} >
                        {this.state[`${field.name}Error`]}
                      </Label>
                    </FormGroup>
                  );
  
                case "switch":
                  return (
                    <FormGroup key={index} row hidden={field.hidden}>
                      <Label for={field.name} sm={2}>{field.label}</Label>
                      <Col sm={10}>
                        <CustomInput
                          type="switch"
                          // className="custom-switch"
                          id={field.name}
                          name={field.name}
                          // forwardref={field.name}
                          label=""
                          checked={ (this.state[`${field.name}`] == 1) ? true : false }
                          onChange={ e => e.target.checked ? this.setState({ [`${field.name}`]: 1 }) : this.setState({ [`${field.name}`]: 0 })}
                          disabled={field.readOnly}
                        />
                      </Col>
                    </FormGroup>
                  );

                case "controlledSwitch":
                  return (
                    <FormGroup key={index} row hidden={field.hidden}>
                      <Label for={field.name} sm={2}>{field.label}</Label>
                      <Col sm={10}>
                        <CustomInput
                          type="switch"
                          // className="custom-switch"
                          id={field.name}
                          name={field.name}
                          // forwardref={field.name}
                          label=""
                          checked={ (this.state[`${field.name}`] == 1) ? true : false }
                          onChange={this.handleChangeOnSwitch}
                          disabled={field.readOnly}
                        />
                        <Label className="text-danger" hidden={!this.state[`${field.name}Error`]} >
                          {this.state[`${field.name}Error`]}
                        </Label>
                      </Col>
                    </FormGroup>
                  );

                case "olvideContraseña":
                  return (
                    <FormGroup key={index} hidden={field.hidden}>
                      <small>
                        <Link to="/olvide/">Olvidé mi contraseña</Link>
                      </small>
                  </FormGroup>
                  );

                default:
                  return;
              }
            }
            )}

            <FormGroup>
              {/* <Col sm={{ size: 8, offset: 2 }}> */}
                <UncontrolledAlert
                  color="danger"
                  className="alert-outline-coloured my-3"
                  isOpen={this.state.errorForm}
                  toggle=""
                  // key={301}
                >
                  <div className="alert-icon">
                    <FontAwesomeIcon icon={faExclamationCircle} fixedWidth />
                  </div>
                  <div className="alert-message">
                    {this.state.errorForm}
                  </div>
                </UncontrolledAlert>
              {/* </Col> */}
            </FormGroup>

            {/* <FormGroup row> */}
              <div className="text-center mt-3">

              {/* <Col sm={{ size: 10, offset: 2 }}> */}
                { ((!(this.props.readOnly)) && ((!(this.props.autoSave)) || (this.state.id === "0")) && (this.props.options.indexOf("bt_sign_in") !== -1)) ? (
                  <Button name="bt_sign_in" color="primary" size="lg" type="submit" className="mr-1 mt-3 mb-1" >
                    <FontAwesomeIcon icon={faFolderOpen} /> Iniciar sesión
                  </Button>
                ) : null }
                { ((!(this.props.readOnly)) && ((!(this.props.autoSave)) || (this.state.id === "0")) && (this.props.options.indexOf("bt_send") !== -1)) ? (
                  <Button name="bt_send" color="primary" size="lg" type="submit" className="mr-1 mt-3 mb-1" >
                    <FontAwesomeIcon icon={faPaperPlane} /> Enviar
                  </Button>
                ) : null }
                { (this.props.options.indexOf("bt_back") !== -1) ? (
                  <Button name="bt_back" color="success" size="lg" className="mr-1 mt-3 mb-1" tag={Link} to={this.props.routeList} >
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} /> Volver
                  </Button>
                ) : null }
                { ((!(this.props.readOnly)) && (this.props.options.indexOf("bt_duplicate") !== -1) && (this.state.id != 0)) ? (
                  <Button name="bt_duplicate" color="warning" size="lg" className="mr-1 mt-3 mb-1" tag={Link} to="/#" onClick={this.handleDuplicate}>
                    <FontAwesomeIcon icon={faCopy} /> Duplicar
                  </Button>
                ) : null }
                {/* <Button name="bt_back" color="success" className="mr-1 mt-3 mb-1" tag={Link} to="/#" onClick={() => this.props.history.goBack()}>
                  <FontAwesomeIcon icon={faFile} /> Volver al listado
                </Button> */}
                { ((!(this.props.readOnly)) && (this.props.options.indexOf("bt_delete") !== -1)) ? (
                  <Button name="bt_delete" color="danger" size="lg" className="mr-1 mt-3 mb-1" tag={Link} to="/#" onClick={this.handleDelete} >
                    <FontAwesomeIcon icon={faTrashAlt} /> Eliminar
                  </Button>
                ) : null }
              {/* </Col> */}
              </div>
            {/* </FormGroup> */}
          </Form>

          <ModalWindow modalType={modalType} modalTitle={modalTitle} modalText={modalText} isOpen={modalIsOpen} modalToggle={this.modalToggle} modalConfirm={this.modalConfirm} modalCancel={this.modalCancel} />
          <Modal isOpen={isLoadingModal} centered >
            {/* <Spinner animation="grow" /> */}
          </Modal>
        </div>

     )
    } 
  }
}

//export default Formulario;
export default withRouter(FormLogin);
