import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Container, Row, Col, Button} from "reactstrap";


import FormList from "../../components/FormList";

class ListLogos extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      
      columns: [
        // {
        //   dataField: "id",
        //   text: "Id",
        //   sort: true,
        //   headerStyle: { "width": "10%" }
        // },
        {
          dataField: "nombre",
          text: "Nombre",
          sort: true,
          headerStyle: { "width": "46%" }
        },
        {
          dataField: "width",
          text: "Ancho",
          sort: true,
          headerStyle: { "width": "20%" }
        },
        {
          dataField: "height",
          text: "Alto",
          sort: true,
          headerStyle: { "width": "20%" }
          },
          //RESERVAMOS EL 14% DEL ANCHO PARA LA COLUMNA DE ACCIONES
      ],
      /**********CAMPOS GENERALES  ************/
      component: "ListImagenes",
      routeList: "/recursos/imagenes/",
      tablename: "imagenes",
      idField: "nombre",
      //idValue: "",
      routeApiList: "/api/imagenes/list.php",
      // routeApiDeleteFile: "/api/familias/delete_file.php",
      // routeApiDeleteImage: "/api/familias/delete_image.php",
      routeApiActions: "/api/imagenes/actions.php",
      readOnly: false,
      rowOptions: "bt_delete_row bt_view_row",

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES A EXCLUIR DEL ENVIO A LA API
      dataExcludeParentComponent: [
      ],

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES TIPO HIDDEN O READONLY DEL ESTADO A INCLUIR DEL ENVIO A LA API
      dataIncludeParentComponent: []
    }
  }

  render () {
    const {columns} = this.state;

    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Imágenes</h1>
        <Card>
          <CardHeader>
            <CardTitle tag="h5">Listado de imágenes</CardTitle>
            <h6 className="card-subtitle text-muted">
              Crea, edita o elimina las imágenes que desees.
            </h6>
          </CardHeader>
          <CardBody>
            <FormList
              component={this.state.component}
              tablename={this.state.tablename}
              columns={this.state.columns}
              routeList={this.state.routeList}
              routeApiList={this.state.routeApiList}
              routeApiActions={this.state.routeApiActions}
              rowOptions={this.state.rowOptions}
              idField={this.state.idField}
              deleteField={this.state.deleteField}
              numberFiles={this.state.numberFiles}
              numberImages={this.state.numberImages}
              readOnly={this.state.readOnly}
              dataExcludeParentComponent={this.state.dataExcludeParentComponent}
              dataIncludeParentComponent={this.state.dataIncludeParentComponent}
              idParent={this.state.id_empresa}
          />
          </CardBody>
        </Card>
      </Container>
        );
    // }
  }
}

export default ListLogos;
