import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Container, Row, Col, Button} from "reactstrap";


import FormList from "../../components/FormList";
import ListCampanyas from "./ListCampanyas";
import ListRankingCampanyas from "./ListRankingCampanyas";

class DashboardCampanyas extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      
      component: "DashboardCampanyas",

    }
  }

  render () {

    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Campañas</h1>
        <ListCampanyas />
        <ListRankingCampanyas />
      </Container>
    );
  }
}

export default DashboardCampanyas;
