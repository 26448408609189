/*

COMPONENTE PARA EL INPUT DE ARCHIVOS RELACIONADOS A UN REGISTRO EN LOS FORMULARIOS

PROPIEDADES:

  - id > EL NÚMERO DEL ARCHIVO ASOCIADO AL REGISTRO
  - url > LA URL AL ARCHIVO
  - titleInputName > NOMBRE DEL CAMPO PARA EL TÍTULO
  - withTitle > SI SE MUESTRA EL CAMPO DE TÍTULO O NO
  - title > EL VALOR DEL TÍTULO
  - handleFileChange > FUNCIÓN A EJECUTAR CUANDO SE SELECCIONE UN ARCHIVO
  - handleFileDelete > FUNCIÓN A EJECUTAR CUANDO SE ELIMINE UN ARCHIVO
  - name > EL VALOR DEL NOMBRE DEL INPUT EN EL FORMULARIO
  - readOnly > SI SE PUEDE EDITAR O NO

FUNCIONES:

  - render > RENDERIZADO

*/
import React from "react";
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen, faFile, faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

// import {
//   // AvForm,
//   Input,
//   // AvGroup,
//   // AvInput
//   // AvFeedback,
//   // AvRadioGroup,
//   // AvRadio,
//   // AvCheckboxGroup,
//   // AvCheckbox
// } from "availity-reactstrap-validation";
import {
  // Card,
  // CardBody,
  // CardHeader,
  // CardTitle,
  Col,
  // Container,
  Button,
  Label,
  FormGroup,
  Input
  // CustomInput
} from "reactstrap";

class InputEmail extends React.Component {

  constructor() {
    super();
    this.state = {
    };
  }

  render () {

    return (
      <div>
        { (this.props.options.indexOf("bt_send") !== -1) ?  
          <FormGroup row>
            <Col sm={9}>
              <Input type="text" id={this.props.id} name={this.props.name} className="container-fluid mr-1 mb-1" value={this.props.value} readOnly={this.props.readOnly} onChange={this.props.handleChangeOnEmail} onKeyDown={this.props.handleKeyDownOnEmail} />
              <Label className="text-danger" hidden={!this.props.emailError} >
                {this.props.emailError}
              </Label>
            </Col>
            <Col sm={3}>
              <Button name="bt_send" id={this.props.id} color="primary" className="mr-0 mb-1" onClick={this.props.handleSendEmail} disabled={this.props.readOnly} >
                <FontAwesomeIcon icon={faPaperPlane} /> Enviar
              </Button>
            </Col>
          </FormGroup>
        :
        <FormGroup row>
        <Col sm={12}>
          <Input type="text" id={this.props.id} name={this.props.name} className="container-fluid mr-1 mb-1" value={this.props.value} readOnly={this.props.readOnly} onChange={this.props.handleChangeOnEmail} onKeyDown={this.props.handleKeyDownOnEmail} />
          <Label className="text-danger" hidden={!this.props.emailError} >
            {this.props.emailError}
          </Label>
        </Col>
      </FormGroup>
        }
      </div>
    )
  }
}

export default InputEmail;
