import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
// import { loadState,saveState } from 'redux/store/index';
import { applyMiddleware, createStore } from 'redux';
import reducer from './redux/reducers/sidebarReducers';
// import { applyMiddleware, createStore } from 'redux';
// import reducer from './redux/reducers/sidebarReducers';
 
// const initialData = loadState();
// const store = createStore(
//     reducers,
//     initialData,
//     compose(applyMiddleware(thunk))
// )
// store.subscribe(function(){
//     saveState(store.getState())
// })

ReactDOM.render(<App />, document.getElementById("root"));
