import React from "react";
import { Link, withRouter } from 'react-router-dom';

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  // Col,
  Container,
  // Button,
  // Label,
  // FormGroup,
  // CustomInput,
  // Form,
  // Input,
  // Modal,
  // ModalBody,
  // ModalFooter,
  // ModalHeader
} from "reactstrap";

import GraphLines from "../../components/GraphLines";
import FormEdit from "../../components/FormEdit";

class EditSeoPinturaalimentaria extends React.Component {

  constructor(props) {

    super(props);

    this.state = {

      idValue: "1",

      fields: [
        {
          name: "id",
          label: "Id",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },
        {
          name: "palabras_clave_inicio",
          label: "Palabras clave inicio cas.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "palabras_clave_inicio_ing",
          label: "Palabras clave inicio ing.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "descripcion_inicio",
          label: "Descripción inicio cas.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "descripcion_inicio_ing",
          label: "Descripción inicio ing.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "palabras_clave_complementos",
          label: "Palabras clave complementos cas.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "palabras_clave_complementos_ing",
          label: "Palabras clave complementos ing.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "descripcion_complementos",
          label: "Descripción complementos cas.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "descripcion_complementos_ing",
          label: "Descripción complementos ing.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "palabras_clave_guias",
          label: "Palabras clave guías cas.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "palabras_clave_guias_ing",
          label: "Palabras clave guías ing.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "descripcion_guias",
          label: "Descripción guías cas.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "descripcion_guias_ing",
          label: "Descripción guías ing.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "palabras_clave_conformidad",
          label: "Palabras clave conformidad cas.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "palabras_clave_conformidad_ing",
          label: "Palabras clave conformidad ing.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "descripcion_conformidad",
          label: "Descripción conformidad cas.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "descripcion_conformidad_ing",
          label: "Descripción conformidad ing.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "palabras_clave_noticias",
          label: "Palabras clave noticias cas.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "palabras_clave_noticias_ing",
          label: "Palabras clave noticias ing.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "descripcion_noticias",
          label: "Descripción noticias cas.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "descripcion_noticias_ing",
          label: "Descripción noticias ing.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "palabras_clave_blog",
          label: "Palabras clave blog cas.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "palabras_clave_blog_ing",
          label: "Palabras clave blog ing.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "descripcion_blog",
          label: "Descripción blog cas.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "descripcion_blog_ing",
          label: "Descripción blog ing.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "palabras_clave_localizacion",
          label: "Palabras clave localización cas.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "palabras_clave_localizacion_ing",
          label: "Palabras clave localización ing.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "descripcion_localizacion",
          label: "Descripción localización cas.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "descripcion_localizacion_ing",
          label: "Descripción localización ing.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "palabras_clave_presupuestos",
          label: "Palabras clave presupuestos cas.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "palabras_clave_presupuestos_ing",
          label: "Palabras clave presupuestos ing.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "descripcion_presupuestos",
          label: "Descripción presupuestos cas.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "descripcion_presupuestos_ing",
          label: "Descripción presupuestos ing.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "palabras_clave_mision",
          label: "Palabras clave mision cas.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "palabras_clave_mision_ing",
          label: "Palabras clave mision ing.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "descripcion_mision",
          label: "Descripción mision cas.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "descripcion_mision_ing",
          label: "Descripción mision ing.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "int_status",
          label: "Estado",
          type: "controlledInformative",
          hidden: true,
          readOnly: true,
          defaultValue: 3,
        }
      ],
      /**********CAMPOS GENERALES  ************/
      component: "EditSeoPinturaalimentaria",
      routeList: "/seo/pinturaalimentaria/",
      tablename: "pinturaalimentaria",
      idField: "id",
      deleteField: "int_status",
      routeApiControl: "/api/_generic/control.php",
      routeApiDeleteFile: "/api/_generic/delete_file.php",
      routeApiDeleteImage: "/api/_generic/delete_image.php",
      routeApiDeleteImageFile: "/api/_generic/delete_image_file.php",
      routeApiActions: "/api/_generic/actions.php",
      numberFiles: 0,
      numberImages: 0, 
      readOnly: false,
      options: "bt_save",
      informativeHidden: true,
      autoSave: false,
      initialFetch: true,
      skipRedirectSubmit: false,
      submitButtonText: "Guardar",

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES A EXCLUIR DEL ENVIO A LA API
      dataExcludeParentComponent: [
        // "options_provincia",
        // "routeThumbnail1",
        // "routeImage1",
      ],

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES TIPO HIDDEN O READONLY DEL ESTADO A INCLUIR DEL ENVIO A LA API
      dataIncludeParentComponent: []
    }
  }

  render () {

    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">SEO pinturaalimentaria.es</h1>
        <Card>
          <CardHeader>
            <CardTitle tag="h5">Editar SEO pinturaalimentaria.es</CardTitle>
            <h6 className="card-subtitle text-muted">
              Introduce la información referente a esta web.
            </h6>
          </CardHeader>
          <CardBody>
            <FormEdit 
              component={this.state.component}
              fields={this.state.fields} 
              routeList={this.state.routeList} 
              tablename={this.state.tablename} 
              idField={this.state.idField} 
              deleteField={this.state.deleteField}
              // idValue={this.props.match.params.id}
              idValue={this.state.idValue}
              routeApiControl={this.state.routeApiControl} 
              routeApiDeleteFile={this.state.routeApiDeleteFile} 
              routeApiDeleteImage={this.state.routeApiDeleteImage} 
              routeApiDeleteImageFile={this.state.routeApiDeleteImageFile} 
              routeApiActions={this.state.routeApiActions} 
              numberFiles={this.state.numberFiles} 
              numberImages={this.state.numberImages} 
              readOnly={this.state.readOnly} 
              options={this.state.options}
              informativeHidden={this.state.informativeHidden}
              autoSave={this.state.autoSave}
              dataExcludeParentComponent={this.state.dataExcludeParentComponent}
              dataIncludeParentComponent={this.state.dataIncludeParentComponent}
              initialFetch={this.state.initialFetch}
            /> 
          </CardBody>
        </Card>           
      </Container>
    );
  }
}

//export default EditSeoPinturaalimentaria;
export default withRouter(EditSeoPinturaalimentaria);
