import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Container, Row, Col, Button} from "reactstrap";


import FormList from "../../components/FormList";

class ListRankingErroresPresupuestos extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      
      columns: [
        // {
        //   dataField: "id",
        //   text: "Id",
        //   sort: true,
        //   headerStyle: { "width": "10%" }
        // },
        {
          dataField: "error",
          text: "Error",
          sort: true,
          headerStyle: { "width": "66%" }
        },
        {
          dataField: "cantidad",
          text: "Cantidad",
          sort: true,
          headerStyle: { "width": "20%" }
        },
        // {
        // dataField: "total",
        // text: "Total presupuestos",
        // sort: true,
        // headerStyle: { "width": "23%" }
        // },
        //RESERVAMOS EL 14% DEL ANCHO PARA LA COLUMNA DE ACCIONES
      ],
      /**********CAMPOS GENERALES  ************/
      component: "ListRankingErrores",
      routeList: "/observabilidad/errores/ranking",
      tablename: "_admin_acciones",
      idField: "id",
      deleteField: "int_status",
      routeApiList: "/api/_calculith_errores/list_ranking_errores.php",
      routeApiDeleteFile: "/api/_generic/delete_file.php",
      routeApiDeleteImage: "/api/_generic/delete_image.php",
      routeApiDeleteImageFile: "/api/_generic/delete_image_file.php",
      routeApiActions: "/api/_generic/actions.php",
      numberFiles: 0,
      numberImages: 0, 
      readOnly: true,
      rowOptions: "bt_edit_row bt_delete_row",

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES A EXCLUIR DEL ENVIO A LA API
      dataExcludeParentComponent: [
      ],

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES TIPO HIDDEN O READONLY DEL ESTADO A INCLUIR DEL ENVIO A LA API
      dataIncludeParentComponent: []
    }
  }

  render () {

    return (
      <Container fluid className="p-0">
        {/* <h1 className="h3 mb-3">Ranking de errores</h1> */}
        <Card>
          <CardHeader>
            <CardTitle tag="h5">Ranking de errores</CardTitle>
            <h6 className="card-subtitle text-muted">
              Clasificación de los errores según la cantidad de veces que se han producido.
            </h6>
          </CardHeader>
          <CardBody>
            <FormList 
              component={this.state.component}
              tablename={this.state.tablename}
              columns={this.state.columns}
              routeList={this.state.routeList}
              routeApiList={this.state.routeApiList}
              routeApiActions={this.state.routeApiActions}
              rowOptions={this.state.rowOptions}
              idField={this.state.idField}
              deleteField={this.state.deleteField}
              numberFiles={this.state.numberFiles}
              numberImages={this.state.numberImages}
              readOnly={this.state.readOnly}
              dataExcludeParentComponent={this.state.dataExcludeParentComponent}
              dataIncludeParentComponent={this.state.dataIncludeParentComponent}
              idParent={this.state.id_empresa}
            />
          </CardBody>
        </Card>
      </Container>
    );
  }
}

export default ListRankingErroresPresupuestos;
