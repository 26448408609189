import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Container, Row, Col, Button} from "reactstrap";


import FormList from "../../components/FormList";
import ListTarifas from "./ListTarifas";
import ListRankingTarifas from "./ListRankingTarifas";

class DashboardTarifas extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      
      component: "DashboardUsuarios",

    }
  }

  render () {

    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Tarifas</h1>
        <ListTarifas />
        <ListRankingTarifas />
      </Container>
    );
  }
}

export default DashboardTarifas;
