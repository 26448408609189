import React from "react";
import { Link, withRouter } from 'react-router-dom';

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  // Col,
  Container,
  // Button,
  // Label,
  // FormGroup,
  // CustomInput
  // Modal,
  // ModalBody,
  // ModalFooter,
  // ModalHeader
} from "reactstrap";

import FormEdit from "../../components/FormEdit";

class EditLandings extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      
      fields: [
        {
          name: "id",
          label: "Id",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },
        {
          name: "nombre",
          label: "Nombre cas.",
          type: "controlledText",
          hidden: false,
          readOnly: false,
          required: true
        },
        {
          name: "nombre_ing",
          label: "Nombre ing.",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "palabras_clave",
          label: "Palabras clave cas.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "palabras_clave_ing",
          label: "Palabras clave ing.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "descripcion",
          label: "Descripción cas.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "descripcion_ing",
          label: "Descripción ing.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "imagen1",
          label: "Imagen 1",
          type: "image",
          imageNumber: "1",
          hidden: false,
          readOnly: false,
        },
        {
          name: "orden",
          label: "Orden",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "visible_fakolith",
          label: "Visible fakolith.es",
          type: "controlledSwitch",
          hidden: false,
          readOnly: false,
        },
        {
          name: "visible_pinturaalimentaria",
          label: "Visible pinturaalimentaria.es",
          type: "controlledSwitch",
          hidden: false,
          readOnly: false,
        },
        {
          name: "version_cas",
          label: "Versión cas.",
          type: "controlledSwitch",
          hidden: false,
          readOnly: false,
        },
        {
          name: "version_ing",
          label: "Versión ing.",
          type: "controlledSwitch",
          hidden: false,
          readOnly: false,
        },
        {
          name: "int_status",
          label: "Estado",
          type: "controlledInformative",
          hidden: true,
          readOnly: true,
          defaultValue: 3,
        },
        {
          name: "landings_enlaces",
          label: "Enlaces",
          type: "controlledList",
          hidden: false,
          readOnly: false,
          routeList: "/landings/landings/enlaces/",
          tablename: "landings_enlaces",
          idField: "id",
          deleteField: "int_status",
          routeApiList: "/api/landings/list_landings_enlaces.php",
          routeApiActions: "/api/_generic/actions.php",    
          numberFiles: 0,
          numberImages: 0,     
          rowOptions: "bt_edit_row bt_delete_row",
          columns: [
            {
              dataField: "nombre",
              text: "Enlace",
              sort: true,
              headerStyle: { "width": "32%" }
            },
            {
              dataField: "url",
              text: "URL",
              sort: true,
              headerStyle: { "width": "52%" }
            },
          ]          
        },

      ],
      /**********CAMPOS GENERALES  ************/
      component: "EditLandings",
      routeList: "/landings/landings/",
      tablename: "landings",
      idField: "id",
      deleteField: "int_status",
      routeApiControl: "/api/_generic/control.php",
      routeApiDeleteFile: "/api/_generic/delete_file.php",
      routeApiDeleteImage: "/api/_generic/delete_image.php",
      routeApiDeleteImageFile: "/api/_generic/delete_image_file.php",
      routeApiActions: "/api/_generic/actions.php",
      numberFiles: 0,
      numberImages: 1, 
      readOnly: false,
      options: "bt_save bt_duplicate bt_back bt_delete",
      informativeHidden: true,
      autoSave: false,
      initialFetch: true,
      skipRedirectSubmit: false,
      submitButtonText: "Guardar",

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES A EXCLUIR DEL ENVIO A LA API
      dataExcludeParentComponent: [
        // "options_provincia",
        // "routeThumbnail1",
        // "routeImage1",
      ],

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES TIPO HIDDEN O READONLY DEL ESTADO A INCLUIR DEL ENVIO A LA API
      dataIncludeParentComponent: []
    }
  }

  render () {

    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Landings</h1>
        <Card>
          <CardHeader>
            <CardTitle tag="h5">Editar landing</CardTitle>
            <h6 className="card-subtitle text-muted">
              Introduce la información referente a esta landing.
            </h6>
          </CardHeader>
          <CardBody>
            <FormEdit 
              component={this.state.component}
              fields={this.state.fields} 
              routeList={this.state.routeList} 
              tablename={this.state.tablename} 
              idField={this.state.idField} 
              idValue={this.props.match.params.id}
              deleteField={this.state.deleteField}
              routeApiControl={this.state.routeApiControl} 
              routeApiDeleteFile={this.state.routeApiDeleteFile} 
              routeApiDeleteImage={this.state.routeApiDeleteImage} 
              routeApiDeleteImageFile={this.state.routeApiDeleteImageFile} 
              routeApiActions={this.state.routeApiActions} 
              numberFiles={this.state.numberFiles} 
              numberImages={this.state.numberImages} 
              readOnly={this.state.readOnly} 
              options={this.state.options}
              informativeHidden={this.state.informativeHidden}
              autoSave={this.state.autoSave}
              dataExcludeParentComponent={this.state.dataExcludeParentComponent}
              dataIncludeParentComponent={this.state.dataIncludeParentComponent}
              initialFetch={this.state.initialFetch}
            />            
          </CardBody>
        </Card>
      </Container>
    );
  }
}

//export default EditLandings;
export default withRouter(EditLandings);
