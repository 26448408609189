import React from "react";
import { Link, withRouter } from 'react-router-dom';

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  // Col,
  Container,
  // Button,
  // Label,
  // FormGroup,
  // CustomInput,
  // Form,
  // Input,
  // Modal,
  // ModalBody,
  // ModalFooter,
  // ModalHeader
} from "reactstrap";

import GraphLines from "../../components/GraphLines";
import FormEdit from "../../components/FormEdit";

class EditRegenerarCatalogos extends React.Component {

  constructor(props) {

    super(props);

    this.state = {

      fields: [
        // {
        //   name: "archivo1",
        //   label: "Archivo",
        //   type: "file",
        //   fileNumber: "1",
        //   hidden: false,
        //   readOnly: false,
        //   withTitle: false,
        // },
      ],
      /**********CAMPOS GENERALES  ************/
      component: "EditRegenerarCatalogos",
      routeList: "/productos/regenerar/",
      tablename: "productos",
      idField: "id",
      deleteField: "",
      routeApiControl: "/api/_generic/control.php",
      routeApiDeleteFile: "/api/_generic/delete_file.php",
      routeApiDeleteImage: "/api/_generic/delete_image.php",
      routeApiDeleteImageFile: "/api/_generic/delete_image_file.php",
      routeApiActions: "/api/catalogos_regenerar/actions.php",
      numberFiles: 0,
      numberImages: 0, 
      readOnly: false,
      options: "bt_save",
      informativeHidden: true,
      autoSave: false,
      initialFetch: false,
      skipRedirectSubmit: false,
      submitButtonText: "Guardar",
    }
  }

  render () {

    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Regenerar catálogos</h1>
        <Card>
          <CardHeader>
            <CardTitle tag="h5">Regenerar catálogos</CardTitle>
            <h6 className="card-subtitle text-muted">
              Haz clic sobre el botón de "Guardar" para proceder a la regeneración de los PDFs de los catálogos de las familias de productos.
            </h6>
          </CardHeader>
          <CardBody>
            <FormEdit 
              component={this.state.component}
              fields={this.state.fields} 
              routeList={this.state.routeList} 
              tablename={this.state.tablename} 
              idField={this.state.idField} 
              deleteField={this.state.deleteField}
              idValue={this.props.match.params.id}
              routeApiControl={this.state.routeApiControl} 
              routeApiDeleteFile={this.state.routeApiDeleteFile} 
              routeApiDeleteImage={this.state.routeApiDeleteImage} 
              routeApiDeleteImageFile={this.state.routeApiDeleteImageFile} 
              routeApiActions={this.state.routeApiActions} 
              numberFiles={this.state.numberFiles} 
              numberImages={this.state.numberImages} 
              readOnly={this.state.readOnly} 
              options={this.state.options}
              informativeHidden={this.state.informativeHidden}
              autoSave={this.state.autoSave}
              dataExcludeParentComponent={this.state.dataExcludeParentComponent}
              dataIncludeParentComponent={this.state.dataIncludeParentComponent}
              initialFetch={this.state.initialFetch}
            /> 
          </CardBody>
        </Card>           
      </Container>
    );
  }
}

//export default EditRegenerarCatalogos;
export default withRouter(EditRegenerarCatalogos);
