import React from "react";
import { Link, withRouter } from 'react-router-dom';

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  // Col,
  Container,
  // Button,
  // Label,
  // FormGroup,
  // CustomInput,
  // Form,
  // Input,
  // Modal,
  // ModalBody,
  // ModalFooter,
  // ModalHeader
} from "reactstrap";

import GraphLines from "../../components/GraphLines";
import FormEdit from "../../components/FormEdit";

const options_canonical = [
  { value: "www.fakolith.es", label: "www.fakolith.es" },
  { value: "www.pinturaalimentaria.es", label: "www.pinturaalimentaria.es" },
  { value: "www.foodgradepaint.com", label: "www.foodgradepaint.com" },
];

class EditPosts extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      
      fields: [
        {
          name: "id",
          label: "Id",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },
        {
          name: "nombre",
          label: "Nombre cas.",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "nombre_ing",
          label: "Nombre ing.",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "intro",
          label: "Introducción cas.",
          type: "editor",
          hidden: false,
          readOnly: false,
        },
        {
          name: "intro_ing",
          label: "Introducción ing.",
          type: "editor",
          hidden: false,
          readOnly: false,
        },
        {
          name: "descripcion",
          label: "Descripción cas.",
          type: "editor",
          hidden: false,
          readOnly: false,
        },
        {
          name: "descripcion_ing",
          label: "Descripción ing.",
          type: "editor",
          hidden: false,
          readOnly: false,
        },
        {
          name: "archivo1",
          label: "Imagen cas.",
          type: "file",
          fileNumber: "1",
          hidden: false,
          readOnly: false,
        },
        {
          name: "archivo2",
          label: "Imagen ing.",
          type: "file",
          fileNumber: "2",
          hidden: false,
          readOnly: false,
        },
        {
          name: "fecha",
          label: "Fecha (YYYY-MM-DD)",
          type: "controlledText",
          hidden: false,
          readOnly: false,
          required: true
        },
        {
          name: "hora",
          label: "Hora (HH:MM:SS)",
          type: "controlledText",
          hidden: false,
          readOnly: false,
          required: true
        },
        {
          name: "version_cas",
          label: "Versión cas.",
          type: "controlledSwitch",
          hidden: false,
          readOnly: false,
        },
        {
          name: "version_ing",
          label: "Versión ing.",
          type: "controlledSwitch",
          hidden: false,
          readOnly: false,
        },
        {
          name: "visible_pinturaalimentaria",
          label: "Visible en PA",
          type: "controlledSwitch",
          hidden: false,
          readOnly: false,
        },
        {
          name: "canonical",
          label: "Canonical",
          type: "controlledSelect",
          hidden: false,
          readOnly: false,
          options: options_canonical
        },
        {
          name: "int_status",
          label: "Estado",
          type: "controlledInformative",
          hidden: true,
          readOnly: true,
          defaultValue: 3,
        },
        {
          name: "posts_etiquetas",
          label: "Etiquetas relacionadas",
          type: "controlledList",
          hidden: false,
          readOnly: false,
          routeList: "/blog/posts/etiquetas/",
          tablename: "posts_etiquetas",
          idField: "id",
          deleteField: "int_status",
          routeApiList: "/api/posts/list_posts_etiquetas.php",
          routeApiActions: "/api/_generic/actions.php",
          numberFiles: 0,
          numberImages: 0,
          rowOptions: "bt_edit_row bt_delete_row",  
          columns: [
            {
              dataField: "nombre",
              text: "Nombre cas.",
              sort: true,
              headerStyle: { "width": "36%" }
            },
            {
              dataField: "nombre_ing",
              text: "Nombre ing.",
              sort: true,
              headerStyle: { "width": "36%" }
            },
          ]          
        },
        {
          name: "posts_sistemas",
          label: "Sistemas relacionados",
          type: "controlledList",
          hidden: false,
          readOnly: false,
          routeList: "/blog/posts/sistemas/",
          tablename: "posts_sistemas",
          idField: "id",
          deleteField: "int_status",
          routeApiList: "/api/posts/list_posts_sistemas.php",
          routeApiActions: "/api/_generic/actions.php",
          numberFiles: 0,
          numberImages: 0,
          rowOptions: "bt_edit_row bt_delete_row",   
          columns: [
            {
              dataField: "nombre",
              text: "Nombre cas.",
              sort: true,
              headerStyle: { "width": "27%" }
            },
            {
              dataField: "nombre_ing",
              text: "Nombre ing.",
              sort: true,
              headerStyle: { "width": "27%" }
            },
            {
              dataField: "orden",
              text: "Orden",
              sort: true,
              headerStyle: { "width": "18%" }
            }            
          ]          
        },
        {
          name: "posts_familias",
          label: "Familias relacionadas",
          type: "controlledList",
          hidden: false,
          readOnly: false,
          routeList: "/blog/posts/familias/",
          tablename: "posts_familias",
          idField: "id",
          deleteField: "int_status",
          routeApiList: "/api/posts/list_posts_familias.php",
          routeApiActions: "/api/_generic/actions.php",
          numberFiles: 0,
          numberImages: 0,
          rowOptions: "bt_edit_row bt_delete_row",
          columns: [
            {
              dataField: "nombre",
              text: "Nombre cas.",
              sort: true,
              headerStyle: { "width": "27%" }
            },
            {
              dataField: "nombre_ing",
              text: "Nombre ing.",
              sort: true,
              headerStyle: { "width": "27%" }
            },
            {
              dataField: "orden",
              text: "Orden",
              sort: true,
              headerStyle: { "width": "18%" }
            }            
          ]          
        },
        {
          name: "posts_productos",
          label: "Productos relacionados",
          type: "controlledList",
          hidden: false,
          readOnly: false,
          routeList: "/blog/posts/productos/",
          tablename: "posts_productos",
          idField: "id",
          deleteField: "int_status",
          routeApiList: "/api/posts/list_posts_productos.php",
          routeApiActions: "/api/_generic/actions.php",
          numberFiles: 0,
          numberImages: 0,
          rowOptions: "bt_edit_row bt_delete_row",
          columns: [
            {
              dataField: "nombre",
              text: "Nombre cas.",
              sort: true,
              headerStyle: { "width": "27%" }
            },
            {
              dataField: "nombre_ing",
              text: "Nombre ing.",
              sort: true,
              headerStyle: { "width": "27%" }
            },
            {
              dataField: "orden",
              text: "Orden",
              sort: true,
              headerStyle: { "width": "18%" }
            }
          ]          
        },
      ],
      /**********CAMPOS GENERALES  ************/
      component: "EditPosts",
      routeList: "/blog/posts/",
      tablename: "posts",
      idField: "id",
      deleteField: "int_status",
      routeApiControl: "/api/_generic/control.php",
      routeApiDeleteFile: "/api/_generic/delete_file.php",
      routeApiDeleteImage: "/api/_generic/delete_image.php",
      routeApiDeleteImageFile: "/api/_generic/delete_image_file.php",
      routeApiActions: "/api/_generic/actions.php",
      numberFiles: 2,
      numberImages: 0, 
      readOnly: false,
      options: "bt_save bt_duplicate bt_back bt_delete",
      informativeHidden: true,
      autoSave: false,
      initialFetch: true,
      skipRedirectSubmit: false,
      submitButtonText: "Guardar",

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES A EXCLUIR DEL ENVIO A LA API
      dataExcludeParentComponent: [
        // "options_provincia",
        // "routeThumbnail1",
        // "routeImage1",
      ],

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES TIPO HIDDEN O READONLY DEL ESTADO A INCLUIR DEL ENVIO A LA API
      dataIncludeParentComponent: []
    }
  }

  render () {

    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Posts</h1>
        <Card>
          <CardHeader>
            <CardTitle tag="h5">Editar post</CardTitle>
            <h6 className="card-subtitle text-muted">
              Introduce la información referente a este post.
            </h6>
          </CardHeader>
          <CardBody>
            <FormEdit 
              component={this.state.component}
              fields={this.state.fields} 
              routeList={this.state.routeList} 
              tablename={this.state.tablename} 
              idField={this.state.idField} 
              deleteField={this.state.deleteField}
              idValue={this.props.match.params.id}
              routeApiControl={this.state.routeApiControl} 
              routeApiDeleteFile={this.state.routeApiDeleteFile} 
              routeApiDeleteImage={this.state.routeApiDeleteImage} 
              routeApiDeleteImageFile={this.state.routeApiDeleteImageFile} 
              routeApiActions={this.state.routeApiActions} 
              numberFiles={this.state.numberFiles} 
              numberImages={this.state.numberImages}
              readOnly={this.state.readOnly} 
              options={this.state.options}
              informativeHidden={this.state.informativeHidden}
              autoSave={this.state.autoSave}
              dataExcludeParentComponent={this.state.dataExcludeParentComponent}
              dataIncludeParentComponent={this.state.dataIncludeParentComponent}
              initialFetch={this.state.initialFetch}
            />
          </CardBody>
        </Card>           
      </Container>
    );
  }
}

//export default EditPosts;
export default withRouter(EditPosts);
