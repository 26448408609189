import React from "react";
import { Link, withRouter } from 'react-router-dom';

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  // Col,
  Container,
  // Button,
  // Label,
  // FormGroup,
  // CustomInput,
  // Form,
  // Input,
  // Modal,
  // ModalBody,
  // ModalFooter,
  // ModalHeader
} from "reactstrap";

import GraphLines from "../../components/GraphLines";
import FormEdit from "../../components/FormEdit";

//let options_id_familia = [];

const options_unidades = [
  { value: "l.", label: "l." },
  { value: "Kg.", label: "Kg." },
  { value: "unidad", label: "unidad" },
  { value: "saco", label: "saco" }
];

const options_espesor = [
  { value: "cm.", label: "cm." },
  { value: "mm.", label: "mm." },
  { value: "µ", label: "µ" }
];

class EditProductos extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      
      fields: [
        {
          name: "id",
          label: "Id",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },
        {
          name: "nombre",
          label: "Nombre cas.",
          type: "controlledText",
          hidden: false,
          readOnly: false,
          required: true
        },
        {
          name: "nombre_ing",
          label: "Nombre ing.",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "id_familia",
          label: "Familia",
          type: "controlledRouteDynamicSelect",
          hidden: false,
          readOnly: false,
          //"table": "familias",
          //options: options_id_familia
          routeApi: "/api/_generic/select_familias.php",
          required: true
        },
        {
          name: "imagen1",
          label: "Imagen",
          type: "image",
          imageNumber: "1",
          hidden: false,
          readOnly: false,
        },
        {
          name: "palabras_clave",
          label: "Palabras clave cas.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "palabras_clave_ing",
          label: "Palabras clave ing.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: false,
        },
        {
          name: "introduccion",
          label: "Introducción cas.",
          type: "editor",
          hidden: false,
          readOnly: false,
        },
        {
          name: "introduccion_ing",
          label: "Introducción ing.",
          type: "editor",
          hidden: false,
          readOnly: false,
        },
        {
          name: "descripcion",
          label: "Descripción cas.",
          type: "editor",
          hidden: false,
          readOnly: false,
        },
        {
          name: "descripcion_ing",
          label: "Descripción ing.",
          type: "editor",
          hidden: false,
          readOnly: false,
        },
        {
          name: "archivo1",
          label: "Ficha técnica cas.",
          type: "file",
          fileNumber: "1",
          hidden: false,
          readOnly: false,
        },
        {
          name: "archivo3",
          label: "Ficha técnica ing.",
          type: "file",
          fileNumber: "3",
          hidden: false,
          readOnly: false,
        },
        {
          name: "archivo2",
          label: "Hoja de seguridad 1 cas.",
          type: "file",
          fileNumber: "2",
          hidden: false,
          readOnly: false,
        },
        {
          name: "archivo4",
          label: "Hoja de seguridad 1 ing.",
          type: "file",
          fileNumber: "4",
          hidden: false,
          readOnly: false,
        },
        {
          name: "archivo5",
          label: "Hoja de seguridad 2 cas.",
          type: "file",
          fileNumber: "5",
          hidden: false,
          readOnly: false,
        },
        {
          name: "archivo6",
          label: "Hoja de seguridad 2 ing.",
          type: "file",
          fileNumber: "6",
          hidden: false,
          readOnly: false,
        },
        {
          name: "unidades",
          label: "Unidades",
          type: "controlledSelect",
          hidden: false,
          readOnly: false,
          options: options_unidades,
          // fetchApi: false,
        },
        {
          name: "kg_por_saco",
          label: "Kg por saco",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "densidad",
          label: "Densidad",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "envases",
          label: "Envases cas.",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "envases_ing",
          label: "Envases ing.",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "productos_envases",
          label: "Envases",
          type: "controlledList",
          hidden: false,
          readOnly: false,
          routeList: "/productos/productos/envases/",
          tablename: "productos_envases",
          idField: "id",
          deleteField: "int_status",
          routeApiList: "/api/productos/list_productos_envases.php",
          routeApiActions: "/api/_generic/actions.php",    
          numberFiles: 0,
          numberImages: 0,     
          rowOptions: "bt_edit_row bt_delete_row",
          columns: [
            {
              dataField: "nombre",
              text: "Tarifa",
              sort: true,
              headerStyle: { "width": "20%" }
            },
            {
              dataField: "cantidad",
              text: "Cantidad",
              sort: true,
              headerStyle: { "width": "12%" }
            },
            {
              dataField: "precio_unidad",
              text: "Precio unidad",
              sort: true,
              headerStyle: { "width": "14%" }
            },
            // {
            //   dataField: "peso_bruto",
            //   text: "Peso bruto",
            //   sort: true,
            //   headerStyle: { "width": "14%" }
            // },
            {
              dataField: "precio_envase",
              text: "Precio por envase",
              sort: true,
              headerStyle: { "width": "14%" }
            },
            {
              dataField: "visible_presupuestos",
              text: "Visible presupuestos",
              sort: true,
              headerStyle: { "width": "16%" }
            }
          ]          
        },
        {
          name: "descuentos_envases",
          label: "Descuentos por cantidad (envase más grande)",
          type: "controlledList",
          hidden: false,
          readOnly: false,
          routeList: "/productos/productos/descuentos/",
          tablename: "productos_envases_descuentos",
          idField: "id",
          deleteField: "int_status",
          routeApiList: "/api/productos/list_productos_envases_descuentos.php",
          routeApiActions: "/api/_generic/actions.php", 
          numberFiles: 0,
          numberImages: 0,     
          rowOptions: "bt_edit_row bt_delete_row",   
          columns: [
            {
              dataField: "nombre",
              text: "Tarifa",
              sort: true,
              headerStyle: { "width": "26%" }
            },
            {
              dataField: "cantidad",
              text: "Cantidad",
              sort: true,
              headerStyle: { "width": "25%" }
            },
            {
              dataField: "precio_unidad",
              text: "Precio unidad",
              sort: true,
              headerStyle: { "width": "25%" }
            }
          ]
        },
        {
          name: "visible_presupuesto",
          label: "Visible presupuestos",
          type: "controlledSwitch",
          hidden: false,
          readOnly: false,
        },
        {
          name: "presupuesto_directo",
          label: "Presupuesto directo",
          type: "controlledSwitch",
          hidden: false,
          readOnly: false,
        },
        {
          name: "incremento_color",
          label: "Incremento por color",
          type: "controlledSwitch",
          hidden: false,
          readOnly: false,
        },
        {
          name: "rendimiento",
          label: "Rendimiento",
          type: "controlledSwitch",
          hidden: false,
          readOnly: false,
        },
        {
          name: "rendimiento_min",
          label: "Rendimiento mín.",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "rendimiento_max",
          label: "Rendimiento máx.",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "intervalo_rendimiento",
          label: "Intervalo rendimiento",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "espesor",
          label: "Espesor",
          type: "controlledSwitch",
          hidden: false,
          readOnly: false,
        },
        {
          name: "unidades_espesor",
          label: "Unidades espesor",
          type: "controlledSelect",
          hidden: false,
          readOnly: false,
          options: options_espesor
        },
        {
          name: "espesor_min",
          label: "Espesor mín.",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "espesor_max",
          label: "Espesor máx.:",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "intervalo_espesor",
          label: "Intervalo espesor",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "cantidad_m2",
          label: "Cantidad por m2 esp. mín.",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "productos_rendimientos",
          label: "Rendimientos habituales",
          type: "controlledList",
          hidden: false,
          readOnly: false,
          routeList: "/productos/productos/rendimientos/",
          tablename: "productos_rendimientos",
          idField: "id",
          deleteField: "int_status",
          routeApiList: "/api/productos/list_productos_rendimientos.php",
          routeApiActions: "/api/_generic/actions.php",    
          numberFiles: 0,
          numberImages: 0,     
          rowOptions: "bt_edit_row bt_delete_row",
          columns: [
            {
              dataField: "nombre",
              text: "Nombre",
              sort: true,
              headerStyle: { "width": "50%" }
            },
            {
              dataField: "valor",
              text: "Valor",
              sort: true,
              headerStyle: { "width": "30%" }
            },
          ]          
        },
        {
          name: "aplicaciones",
          label: "Aplicaciones",
          type: "controlledList",
          hidden: false,
          readOnly: false,
          routeList: "/productos/productos/aplicaciones/",
          tablename: "_ai_applications",
          idField: "id",
          deleteField: "status",
          routeApiList: "/api/productos/list_productos_aplicaciones.php",
          routeApiActions: "/api/_generic/actions.php",    
          numberFiles: 0,
          numberImages: 0,     
          rowOptions: "bt_edit_row bt_delete_row",
          columns: [
            {
              dataField: "name_sector",
              text: "Sector",
              sort: true,
              headerStyle: { "width": "25%" }
            },
            {
              dataField: "name_process",
              text: "Proceso",
              sort: true,
              headerStyle: { "width": "30%" }
            },
            {
              dataField: "description_parameter",
              text: "Parámetro",
              sort: true,
              headerStyle: { "width": "30%" }
            },
          ]          
        },
        {
          name: "complementarios",
          label: "Productos complementarios",
          type: "controlledSwitch",
          hidden: true,
          readOnly: false,
        },
        {
          name: "id_producto_padre",
          label: "Producto padre",
          type: "controlledRouteDynamicSelect",
          hidden: false,
          readOnly: false,
          routeApi: "/api/_generic/select_productos.php"
        },
        {
          name: "visible",
          label: "Visible en FK",
          type: "controlledSwitch",
          hidden: false,
          readOnly: false,
        },
        {
          name: "orden",
          label: "Orden",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "visible_pinturaalimentaria",
          label: "Visible en PA",
          type: "controlledSwitch",
          hidden: false,
          readOnly: false,
        },
        {
          name: "orden_pinturaalimentaria",
          label: "Orden en PA",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "complementario_pinturaalimentaria",
          label: "Complementario en PA",
          type: "controlledSwitch",
          hidden: false,
          readOnly: false,
        },
        {
          name: "orden_complementario",
          label: "Orden como complementario",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "int_status",
          label: "Estado",
          type: "controlledInformative",
          hidden: true,
          readOnly: true,
          defaultValue: 3,
        },
      ],
      /**********CAMPOS GENERALES  ************/
      component: "EditProductos",
      routeList: "/productos/productos/",
      tablename: "productos",
      idField: "id",
      //idValue: "",
      deleteField: "int_status",
      routeApiControl: "/api/_generic/control.php",
      routeApiDeleteFile: "/api/_generic/delete_file.php",
      routeApiDeleteImage: "/api/_generic/delete_image.php",
      routeApiDeleteImageFile: "/api/_generic/delete_image_file.php",
      routeApiActions: "/api/_generic/actions.php",
      numberFiles: 9,
      numberImages: 1, 
      readOnly: false,
      options: "bt_save bt_duplicate bt_back bt_delete",
      informativeHidden: true,
      autoSave: false,
      initialFetch: true,
      skipRedirectSubmit: false,
      submitButtonText: "Guardar",

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES A EXCLUIR DEL ENVIO A LA API
      dataExcludeParentComponent: [
        // "options_provincia",
        // "routeThumbnail1",
        // "routeImage1",
      ],

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES TIPO HIDDEN O READONLY DEL ESTADO A INCLUIR DEL ENVIO A LA API
      dataIncludeParentComponent: []
    }
  }

  render () {

    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Productos</h1>
        {/* <GraphLines /> */}
        <Card>
          <CardHeader>
            <CardTitle tag="h5">Editar producto</CardTitle>
            <h6 className="card-subtitle text-muted">
              Introduce la información referente a este producto.
            </h6>
          </CardHeader>
          <CardBody>
            <FormEdit 
              component={this.state.component}
              fields={this.state.fields} 
              routeList={this.state.routeList} 
              tablename={this.state.tablename} 
              idField={this.state.idField} 
              idValue={this.props.match.params.id}
              deleteField={this.state.deleteField}
              routeApiControl={this.state.routeApiControl} 
              routeApiDeleteFile={this.state.routeApiDeleteFile} 
              routeApiDeleteImage={this.state.routeApiDeleteImage} 
              routeApiDeleteImageFile={this.state.routeApiDeleteImageFile} 
              routeApiActions={this.state.routeApiActions} 
              numberFiles={this.state.numberFiles} 
              numberImages={this.state.numberImages} 
              readOnly={this.state.readOnly} 
              options={this.state.options}
              informativeHidden={this.state.informativeHidden}
              autoSave={this.state.autoSave}
              dataExcludeParentComponent={this.state.dataExcludeParentComponent}
              dataIncludeParentComponent={this.state.dataIncludeParentComponent}
              initialFetch={this.state.initialFetch}
            /> 
          </CardBody>
        </Card>           
      </Container>
    );
  }
}

//export default EditProductos;
export default withRouter(EditProductos);
