import React from "react";
import { Link, withRouter } from 'react-router-dom';

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  // Col,
  Container,
  // Button,
  // Label,
  // FormGroup,
  // CustomInput,
  // Form,
  // Input,
  // Modal,
  // ModalBody,
  // ModalFooter,
  // ModalHeader
} from "reactstrap";

import GraphLines from "../../components/GraphLines";
import FormEdit from "../../components/FormEdit";

class EditEmpresas extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      
      fields: [
        {
          name: "id",
          label: "Id",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },
        {
          name: "nombre",
          label: "Nombre",
          type: "controlledText",
          hidden: false,
          readOnly: false,
          required: true,
        },
        {
          name: "direccion",
          label: "Dirección",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "poblacion",
          label: "Población",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "cod_postal",
          label: "Cód. postal",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "provincia",
          label: "Provincia",
          type: "controlledRouteDynamicSelect",
          hidden: false,
          readOnly: false,
          //"table": "familias",
          //options: options_id_familia
          routeApi: "/api/_generic/select_calculith_provincias.php"
        },
        {
          name: "id_pais",
          label: "País",
          type: "controlledRouteDynamicSelect",
          hidden: false,
          readOnly: false,
          routeApi: "/api/_generic/select_calculith_paises.php",
          required: true,
        },
        {
          name: "email",
          label: "Email",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "cif",
          label: "CIF",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        // {
        //   name: "imagen1",
        //   label: "Imagen cas.",
        //   type: "image",
        //   imageNumber: "1",
        //   hidden: false,
        //   readOnly: false,
        // },
        {
          name: "imagen1",
          label: "Logotipo",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "id_version",
          label: "Versión",
          type: "controlledRouteDynamicSelect",
          hidden: false,
          readOnly: false,
          routeApi: "/api/_generic/select_calculith_versiones.php",
          required: true,
        },
        {
          name: "id_campanya",
          label: "Campaña",
          type: "controlledRouteDynamicSelect",
          hidden: false,
          readOnly: false,
          routeApi: "/api/_generic/select_calculith_campanyas.php",
          required: true,
        },
        {
          name: "codigo",
          label: "Código",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "keystring",
          label: "Keystring",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },
        {
          name: "tiendas_presupuestos",
          label: "Tiendas",
          type: "controlledList",
          hidden: false,
          readOnly: false,
          routeList: "/presupuestos/empresas/tiendas/",
          tablename: "tiendas_presupuestos",
          idField: "id",
          deleteField: "int_status",
          routeApiList: "/api/_calculith_empresas/list_empresas_tiendas.php",
          routeApiActions: "/api/_generic/actions.php",
          numberFiles: 0,
          numberImages: 0,
          rowOptions: "bt_edit_row bt_delete_row",
          columns: [
            {
              dataField: "nombre",
              text: "Nombre",
              sort: true,
              headerStyle: { "width": "32%" }
            },
            {
              dataField: "poblacion",
              text: "Población",
              sort: true,
              headerStyle: { "width": "30%" }
            },
            {
              dataField: "nombre_provincia",
              text: "Provincia",
              sort: true,
              headerStyle: { "width": "22%" }
            }            
          ]          
        },
        {
          name: "usuarios_presupuestos",
          label: "Usuarios",
          type: "controlledList",
          hidden: false,
          readOnly: false,
          routeList: "/presupuestos/empresas/usuarios/",
          tablename: "_calculith_usuarios",
          idField: "id",
          deleteField: "int_status",
          routeApiList: "/api/_calculith_empresas/list_empresas_usuarios.php",
          routeApiActions: "/api/_generic/actions.php",
          numberFiles: 0,
          numberImages: 0,
          rowOptions: "bt_edit_row bt_delete_row",
          columns: [
            {
              dataField: "nombre",
              text: "Nombre",
              sort: true,
              headerStyle: { "width": "35%" }
            },
            {
              dataField: "email",
              text: "Email",
              sort: true,
              headerStyle: { "width": "35%" }
            },
            {
              dataField: "fecha",
              text: "Fecha",
              sort: true,
              headerStyle: { "width": "16%" }
            }            
          ]          
        },
        {
          name: "presupuestos",
          label: "Presupuestos",
          type: "controlledList",
          hidden: false,
          readOnly: false,
          routeList: "/presupuestos/empresas/presupuestos/",
          tablename: "presupuestos",
          idField: "id",
          deleteField: "int_status",
          routeApiList: "/api/_calculith_empresas/list_empresas_presupuestos.php",
          routeApiActions: "/api/_generic/actions.php",
          numberFiles: 0,
          numberImages: 0,
          rowOptions: "bt_edit_row bt_delete_row",
          columns: [
            {
              dataField: "numero",
              text: "Número",
              sort: true,
              headerStyle: { "width": "10%" }
            },
            {
              dataField: "referencia",
              text: "Referencia",
              sort: true,
              headerStyle: { "width": "20%" }
            },
            // {
            //   dataField: "nombre_tienda",
            //   text: "Tienda",
            //   sort: true,
            //   headerStyle: { "width": "10%" }
            // },
            {
              dataField: "nombre_usuario",
              text: "Usuario",
              sort: true,
              headerStyle: { "width": "20%" }
            },
            {
              dataField: "cliente",
              text: "Cliente",
              sort: true,
              headerStyle: { "width": "20%" }
            },
            {
              dataField: "total",
              text: "Total",
              sort: true,
              headerStyle: { "width": "10%" }
            }

          ]          
        },
        {
          name: "int_status",
          label: "Estado",
          type: "controlledInformative",
          hidden: true,
          readOnly: true,
          defaultValue: 3,
        },
      ],
      /**********CAMPOS GENERALES  ************/
      component: "EditEmpresas",
      routeList: "/presupuestos/empresas/",
      tablename: "_calculith_empresas",
      idField: "id",
      deleteField: "int_status",
      routeApiControl: "/api/_generic/control.php",
      routeApiDeleteFile: "/api/_generic/delete_file.php",
      routeApiDeleteImage: "/api/_generic/delete_image.php",
      routeApiDeleteImageFile: "/api/_generic/delete_image_file.php",
      routeApiActions: "/api/_calculith_empresas/actions.php",
      numberFiles: 0,
      numberImages: 0, 
      readOnly: false,
      options: "bt_save bt_back bt_delete",
      informativeHidden: true,
      autoSave: false,
      initialFetch: true,
      skipRedirectSubmit: false,
      submitButtonText: "Guardar",

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES A EXCLUIR DEL ENVIO A LA API
      dataExcludeParentComponent: [
        // "options_provincia",
        // "routeThumbnail1",
        // "routeImage1",
      ],

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES TIPO HIDDEN O READONLY DEL ESTADO A INCLUIR DEL ENVIO A LA API
      dataIncludeParentComponent: []
    }
  }

  render () {

    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Empresas</h1>
        <Card>
          <CardHeader>
            <CardTitle tag="h5">Editar empresa</CardTitle>
            <h6 className="card-subtitle text-muted">
              Introduce la información referente a esta empresa.
            </h6>
          </CardHeader>
          <CardBody>
            <FormEdit 
              component={this.state.component}
              fields={this.state.fields} 
              routeList={this.state.routeList} 
              tablename={this.state.tablename} 
              idField={this.state.idField} 
              deleteField={this.state.deleteField}
              idValue={this.props.match.params.id}
              routeApiControl={this.state.routeApiControl} 
              routeApiDeleteFile={this.state.routeApiDeleteFile} 
              routeApiDeleteImage={this.state.routeApiDeleteImage} 
              routeApiDeleteImageFile={this.state.routeApiDeleteImageFile} 
              routeApiActions={this.state.routeApiActions} 
              numberFiles={this.state.numberFiles} 
              numberImages={this.state.numberImages} 
              readOnly={this.state.readOnly} 
              options={this.state.options}
              informativeHidden={this.state.informativeHidden}
              autoSave={this.state.autoSave}
              dataExcludeParentComponent={this.state.dataExcludeParentComponent}
              dataIncludeParentComponent={this.state.dataIncludeParentComponent}
              initialFetch={this.state.initialFetch}
            /> 
          </CardBody>
        </Card>           
      </Container>
    );
  }
}

//export default EditEmpresas;
export default withRouter(EditEmpresas);
