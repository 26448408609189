import React, { useState, useEffect } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  ListGroupItem,
  Media,
  Row,
} from "reactstrap";

import avatar1 from "../assets/img/avatars/avatar.jpg";
import avatar2 from "../assets/img/avatars/avatar-2.jpg";
import avatar3 from "../assets/img/avatars/avatar-3.jpg";
import avatar4 from "../assets/img/avatars/avatar-4.jpg";
import avatar5 from "../assets/img/avatars/avatar-5.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

import { Phone, Video, MoreHorizontal } from "react-feather";

const ChatMessage = ({ position, avatar, name, children, time }) => (
  <div className={`chat-message-${position} pb-4`}>
    <div>
      <img
        src={avatar}
        className="rounded-circle mr-1"
        alt={name}
        width="40"
        height="40"
      />
      <div className="text-muted small text-nowrap mt-2">{time}</div>
    </div>
    <div
      className={`flex-shrink-1 bg-light rounded py-2 px-3 ${
        position === "right" ? "mr-3" : "ml-3"
      }`}
    >
      <div className="font-weight-bold mb-3">{name}</div>
      {children}
    </div>
  </div>
);

const Chat = ({
  name,
  idParent,  
  initialMessages,
  newMessage,
  routeApiList,
  routeApiActions,
  fetchApi,
  handleChangeOnMessage,
  loadingStarted,
  // action
}) => {

  // Ref for the scroll to the end of the messages container
  const messagesEndRef = React.useRef(null);

  // State for messages
  const [messages, setMessages] = useState([]);

  // Function to fetch initial messages
  useEffect(() => {
    if (routeApiList && fetchApi && idParent) {
      fetchApi(routeApiList);
    }
  }, [routeApiList, fetchApi, idParent]);

  useEffect(() => {
    // Make sure initialMessages is an array before updating the state
    if (Array.isArray(initialMessages)) {
      setMessages(initialMessages);
      // messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [initialMessages]);
  
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // Function to send a new message
  const sendMessage = async () => {
    if (routeApiActions && fetchApi) {      
      await fetchApi(routeApiActions);
      // setMessages([...messages, {
      //   id: "16",
      //   id_thread: "1",
      //   id_user: "1",
      //   content: newMessage,
      //   date: "2024-03-27",
      //   time: "22:20:00",
      //   name: "Carlos Pérez",
      //   position: "right",
      //   avatar: "/docs/1/carlos_perez.png"
      // }]);
    }
  };

  // Function to wait for a new assistant message
  const waitMessage = async () => {
    if (routeApiActions && fetchApi) {      
      await fetchApi(routeApiActions);
      // setMessages([...messages, {
      //   id: "16",
      //   id_thread: "1",
      //   id_user: "1",
      //   content: newMessage,
      //   date: "2024-03-27",
      //   time: "22:20:00",
      //   name: "Carlos Pérez",
      //   position: "right",
      //   avatar: "/docs/1/carlos_perez.png"
      // }]);
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Handling user message input
  const handleSendMessage = async () => {
    // isLoading=true;
    // isLoadingModal=true;
    // await loadingStarted();
    await sendMessage();
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    // await loadingStarted();
    await waitMessage();
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Container fluid className="p-0">
      {/* ... [rest of your component] */}
      <div className="position-relative">
        <div className="chat-messages p-4">
          {messages.map((message, index) => (
            <ChatMessage
              key={index}
              position={message.position}
              name={message.name}
              avatar={message.avatar}
              time={message.time}
            >
              <div dangerouslySetInnerHTML={{ __html: message.content }}></div>
            </ChatMessage>
          ))}
          <div ref={messagesEndRef} />
        </div>
      </div>      

      <div className="flex-grow-0 py-3 px-4 border-top">
        <InputGroup>
          <Input
            name={name}
            type="text"
            placeholder="Type your message..."
            value={newMessage}
            onChange={handleChangeOnMessage}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                handleSendMessage();
                event.preventDefault(); // Prevents the addition of a new line in the input when pressing Enter
              }
            }}
          />
          <InputGroupAddon addonType="append">
            <Button color="primary" onClick={handleSendMessage}>
              Send
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </div>
    </Container>
  );
};

export default Chat;
