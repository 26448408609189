/*

COMPONENTE PARA EL INPUT DE IMÁGENES TIPO ARCHIVO (NO EN BBDD) RELACIONADAS A UN REGISTRO EN LOS FORMULARIOS

PROPIEDADES:

  - id > EL NÚMERO DE LA IMAGEN ASOCIADA AL REGISTRO
  - idParent > EL ID DEL REGISTRO PRINCIPAL AL QUE ESTÁ ASOCIADA LA IMAGEN
  - tablename > LA TABLA DONDE SE GUARDA LA IMAGEN
  - availableImage > SI EXISTE UNA IMAGEN YA EN LA BBDD O SI NO
  - handleImageChange > FUNCIÓN A EJECUTAR CUANDO SE SELECCIONE UNA IMAGEN
  - handleImageDelete > FUNCIÓN A EJECUTAR CUANDO SE ELIMINE UNA IMAGEN
  - name > EL VALOR DEL NOMBRE DEL INPUT EN EL FORMULARIO
  - routeImage > EL VALOR DE LA RUTA A LA IMAGEN
  - routeThumbnail > EL VALOR DE LA RUTA A LA MINIATURA DE LA IMAGEN
  - readOnly > SI SE PUEDE EDITAR O NO
  - routeHidden > PARA MOSTRAR O NO EL EDIT CON LA RUTA A LA IMAGEN

FUNCIONES:

  - render > RENDERIZADO

*/
import React from "react";
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen, faFile, faTrashAlt } from "@fortawesome/free-regular-svg-icons";

import {
  // AvForm,
  AvField,
  // AvGroup,
  // AvInput
  // AvFeedback,
  // AvRadioGroup,
  // AvRadio,
  // AvCheckboxGroup,
  // AvCheckbox
} from "availity-reactstrap-validation";
import {
  // Card,
  // CardBody,
  // CardHeader,
  // CardTitle,
  Col,
  // Container,
  Button,
  Label,
  FormGroup,
  Input,
  // CustomInput
} from "reactstrap";

class InputImageFile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render () {

    return (
      <div>
        <FormGroup row>
          { (this.props.availableImage === "1") ? 
          <Col sm={2}>
            <div className="mb-1">
              <a href={this.props.routeImage} target="_blank" rel="noopener noreferrer" >
                <img src={this.props.routeThumbnail} className="img-fluid border" alt="" />
              </a>
            </div>
          </Col>
          :
          <Col sm={2}>
            <div className="mb-1">
              <img src={this.props.routeThumbnail} className="img-fluid border" alt="" />
            </div>
          </Col>
          }
          { (this.props.routeHidden) ? 
          <Col sm={0} hidden>
            <Input type="text" id="route" name="route" className="container-fluid mr-1 mb-1" value={this.props.routeImage} disabled hidden />
            <input name={this.props.name} id={this.props.id} type="file" ref={image => this.image = image} onChange={this.props.handleImageChange} hidden />
          </Col>
          :
          <Col sm={4}>
            <Input type="text" id="route" name="route" className="container-fluid mr-1 mb-1" value={this.props.routeImage} disabled />
            <input name={this.props.name} id={this.props.id} type="file" ref={image => this.image = image} onChange={this.props.handleImageChange} hidden />
          </Col>
          }
          <Col sm={6}>
            <Button name="bt_select_image" color="primary" className="mr-1 mb-1" onClick={() => this.image.click()} disabled={this.props.disabled} >
              <FontAwesomeIcon icon={faFolderOpen} /> Seleccionar
            </Button>
            <Button name="bt_view_image" color="success" className="mr-1 mb-1" tag={Link} to="/#" onClick={(event) => {event.preventDefault(); window.open(`${this.props.routeImage}`);}} disabled={(!(this.props.availableImage))}>
              <FontAwesomeIcon icon={faFile} /> Visualizar
            </Button>
            <Button name="bt_delete_image" id={this.props.id} color="danger" className="mr-1 mb-1" tag={Link} to="/#" onClick={this.props.handleImageDelete}  disabled={((!(this.props.availableImage)) || (this.props.disabled))} >
              <FontAwesomeIcon icon={faTrashAlt} /> Eliminar
            </Button>
          </Col>
        </FormGroup>
      </div>
    )
  }
}

export default InputImageFile;
