import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Container, Row, Col, Button} from "reactstrap";


import FormList from "../../components/FormList";
import EditUsuarios from "./EditUsuarios";
import EditEmpresas from "./EditEmpresas";

class DashboardCuentas extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      
      component: "DashboardCampanyas",

    }
  }

  render () {

    return (
      <Container fluid className="p-0">
        {/* <h1 className="h3 mb-3">Usuarios</h1> */}
        <EditUsuarios />
        <EditEmpresas />
      </Container>
    );
  }
}

export default DashboardCuentas;
