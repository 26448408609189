import React from "react";

import { Container, Row, Col } from "reactstrap";

import jQuery from "jquery";
const $ = jQuery;
window.jQuery = jQuery;

require("smartwizard/dist/js/jquery.smartWizard.min.js");

class WizardComponent extends React.Component {

  componentDidMount() {

    $(this.refs.smartwizard)
      .smartWizard({
        theme: this.props.variant, // default OR arrows
        showStepURLhash: true,
        toolbarSettings: {
          toolbarExtraButtons: [
            $(
              '<button class="btn btn-submit btn-primary" type="button">Finish</button>'
            )
          ]
        }
      })
      .on("leaveStep", function(e, anchorObject, stepNumber, stepDirection) {
        if (stepDirection === "forward") {
          // Validation between steps
        }
        return true;
      });

    $(this.refs.smartwizard)
      .find(".btn-submit")
      .on("click", function() {
        alert("Great! The form is ready to submit.");
        // Final validation

        return false;
      });
  }

  render() {

    const { color } = this.props;

    return (
      <div id="smartwizard" ref="smartwizard" className={`wizard wizard-${color}`}>
        <ul>
          <li>
            <a href={`#step-1`}>
              Producto 1
              <br />
              <small>Step description</small>
            </a>
          </li>
          <li>
            <a href={`#step-2`}>
              Producto 2
              <br />
              <small>Step description</small>
            </a>
          </li>
          <li>
            <a href={`#step-3`}>
              Producto 3
              <br />
              <small>Step description</small>
            </a>
          </li>
        </ul>

        {/* <div>
          <div id={`step-1`}>Step Content 1</div>
          <div id={`step-2`}>Step Content 2</div>
          <div id={`step-3`}>Step Content 3</div>
          <div id={`step-4`}>Step Content 4</div>
        </div> */}
      </div>
    );
  }
}

export default WizardComponent;
