import React from "react";
import { Link, withRouter } from 'react-router-dom';

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  // Col,
  Container,
  // Button,
  // Label,
  // FormGroup,
  // CustomInput,
  // Form,
  // Input,
  // Modal,
  // ModalBody,
  // ModalFooter,
  // ModalHeader
} from "reactstrap";

import GraphLines from "../../components/GraphLines";
import FormEdit from "../../components/FormEdit";

class EditPresupuestos extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      
      fields: [
        {
          name: "id",
          label: "Id",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },
        {
          name: "numero",
          label: "Número",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },
        {
          name: "id_tienda_presupuesto",
          label: "Tienda",
          type: "controlledRouteDynamicSelect",
          hidden: false,
          readOnly: true,
          //"table": "familias",
          //options: options_id_familia
          routeApi: "/api/_generic/select_tiendas.php"
        },
        {
          name: "id_usuario_presupuesto",
          label: "Usuario",
          type: "controlledRouteDynamicSelect",
          hidden: false,
          readOnly: true,
          //"table": "familias",
          //options: options_id_familia
          routeApi: "/api/_generic/select_usuarios.php"
        },
        {
          name: "telefono_comercial",
          label: "Teléfono usuario",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },
        {
          name: "email_comercial",
          label: "Email usuario",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },
        {
          name: "referencia",
          label: "Referencia",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },        
        {
          name: "fecha",
          label: "Fecha",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },        
        {
          name: "hora",
          label: "Hora",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },        
        {
          name: "cliente",
          label: "Cliente",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },        
        {
          name: "poblacion",
          label: "Población",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },        
        {
          name: "email",
          label: "Email",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },
        {
          name: "lineas_presupuestos",
          label: "Líneas del presupuesto",
          type: "controlledList",
          hidden: false,
          readOnly: true,
          routeList: "/presupuestos/usuarios/presupuestos/lineas",
          tablename: "lineas_presupuestos",
          idField: "id",
          deleteField: "int_status",
          routeApiList: "/api/presupuestos/list_presupuestos_lineas.php",
          routeApiActions: "/api/_generic/actions.php",
          numberFiles: 0,
          numberImages: 0,
          rowOptions: "bt_edit_row bt_delete_row",
          columns: [
            {
              dataField: "descripcion",
              text: "Descripción",
              sort: true,
              headerStyle: { "width": "49%" }
            },
            {
              dataField: "cantidad",
              text: "Cantidad",
              sort: true,
              headerStyle: { "width": "10%" }
            },
            {
              dataField: "precio",
              text: "Precio",
              sort: true,
              headerStyle: { "width": "10%" }
            },
            {
              dataField: "descuento",
              text: "% dto.",
              sort: true,
              headerStyle: { "width": "6%" }
            },
            {
              dataField: "metros",
              text: "m2",
              sort: true,
              headerStyle: { "width": "10%" }
            },
            {
              dataField: "total",
              text: "Total",
              sort: true,
              headerStyle: { "width": "15%" }
            }
          ]          
        },
        {
          name: "envios_presupuestos",
          label: "Envíos del presupuesto",
          type: "controlledList",
          hidden: false,
          readOnly: true,
          routeList: "/presupuestos/usuarios/presupuestos/envios",
          tablename: "envios_presupuestos",
          idField: "id",
          deleteField: "int_status",
          routeApiList: "/api/presupuestos/list_presupuestos_envios.php",
          routeApiActions: "/api/_generic/actions.php",
          numberFiles: 0,
          numberImages: 0,
          rowOptions: "bt_edit_row bt_delete_row",
          columns: [
            {
              dataField: "fecha",
              text: "Fecha",
              sort: true,
              headerStyle: { "width": "15%" }
            },
            {
              dataField: "hora",
              text: "Hora",
              sort: true,
              headerStyle: { "width": "15%" }
            },
            {
              dataField: "cliente",
              text: "Cliente",
              sort: true,
              headerStyle: { "width": "20%" }
            },
            {
              dataField: "poblacion",
              text: "Población",
              sort: true,
              headerStyle: { "width": "20%" }
            },
            {
              dataField: "email",
              text: "Email",
              sort: true,
              headerStyle: { "width": "30%" }
            }
          ]          
        },
        {
          name: "escalado",
          label: "Escalado",
          type: "controlledSwitch",
          hidden: false,
          readOnly: true,
        },        
        {
          name: "portes",
          label: "Portes",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },        
        {
          name: "mano_de_obra",
          label: "Mano de obra",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },        
        {
          name: "otros_materiales",
          label: "Otros materiales",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },        
        {
          name: "otros_conceptos",
          label: "Otros conceptos",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },        
        {
          name: "total",
          label: "Total",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },        
        {
          name: "id_idioma",
          label: "Idioma",
          type: "controlledRouteDynamicSelect",
          hidden: false,
          readOnly: true,
          routeApi: "/api/_generic/select_idiomas.php"
        },
        {
          name: "observaciones",
          label: "Observaciones",
          type: "editor",
          hidden: false,
          readOnly: true,
        },
        {
          name: "int_status",
          label: "Estado",
          type: "controlledText",
          hidden: true,
          readOnly: true,
        },
      ],
      /**********CAMPOS GENERALES  ************/
      component: "EditPresupuestos",
      routeList: "/presupuestos/presupuestos/",
      tablename: "presupuestos",
      idField: "id",
      deleteField: "int_status",
      routeApiControl: "/api/_generic/control.php",
      routeApiDeleteFile: "/api/_generic/delete_file.php",
      routeApiDeleteImage: "/api/_generic/delete_image.php",
      routeApiDeleteImageFile: "/api/_generic/delete_image_file.php",
      routeApiActions: "/api/_generic/actions.php",
      numberFiles: 0,
      numberImages: 0, 
      readOnly: true,
      options: "bt_save bt_duplicate bt_back bt_delete",
      informativeHidden: true,
      autoSave: false,
      initialFetch: true,
      skipRedirectSubmit: false,
      submitButtonText: "Guardar",

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES A EXCLUIR DEL ENVIO A LA API
      dataExcludeParentComponent: [
        // "options_provincia",
        // "routeThumbnail1",
        // "routeImage1",
      ],

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES TIPO HIDDEN O READONLY DEL ESTADO A INCLUIR DEL ENVIO A LA API
      dataIncludeParentComponent: []
    }
  }

  render () {

    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Presupuesto</h1>
        <Card>
          <CardHeader>
            <CardTitle tag="h5">Editar presupuesto</CardTitle>
            <h6 className="card-subtitle text-muted">
              Introduce la información referente a este presupuesto.
            </h6>
          </CardHeader>
          <CardBody>
            <FormEdit 
              component={this.state.component}
              fields={this.state.fields} 
              routeList={this.state.routeList} 
              tablename={this.state.tablename} 
              idField={this.state.idField} 
              deleteField={this.state.deleteField}
              idValue={this.props.match.params.id}
              routeApiControl={this.state.routeApiControl} 
              routeApiDeleteFile={this.state.routeApiDeleteFile} 
              routeApiDeleteImage={this.state.routeApiDeleteImage} 
              routeApiDeleteImageFile={this.state.routeApiDeleteImageFile} 
              routeApiActions={this.state.routeApiActions} 
              numberFiles={this.state.numberFiles} 
              numberImages={this.state.numberImages}
              readOnly={this.state.readOnly} 
              options={this.state.options}
              informativeHidden={this.state.informativeHidden}
              autoSave={this.state.autoSave}
              dataExcludeParentComponent={this.state.dataExcludeParentComponent}
              dataIncludeParentComponent={this.state.dataIncludeParentComponent}
              initialFetch={this.state.initialFetch}
            /> 
          </CardBody>
        </Card>           
      </Container>
    );
  }
}

//export default EditPresupuestos;
export default withRouter(EditPresupuestos);
