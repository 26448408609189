import React from "react";
import { Link, withRouter } from 'react-router-dom';

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  // Col,
  Container,
  // Button,
  // Label,
  // FormGroup,
  // CustomInput,
  // Form,
  // Input,
  // Modal,
  // ModalBody,
  // ModalFooter,
  // ModalHeader
} from "reactstrap";

import FormEdit from "../../components/FormEdit";

class EditEmpresasUsuarios extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      
      fields: [
        {
          name: "id",
          label: "Id",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },
        {
          name: "id_empresa",
          label: "Id empresa",
          type: "controlledParent",
          hidden: false,
          readOnly: true,
        },
        {
          name: "nombre",
          label: "Nombre",
          type: "controlledText",
          hidden: false,
          readOnly: false,
          required: true,
        },        
        {
          name: "telefono",
          label: "Teléfono",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },        
        {
          name: "email",
          label: "Email",
          type: "controlledText",
          hidden: false,
          readOnly: false,
          required: true,
        },        
        {
          name: "contrasenya",
          label: "Contraseña",
          type: "controlledText",
          hidden: false,
          readOnly: false,
          required: true,
        },        
        {
          name: "fecha",
          label: "Fecha (YYYY-MM-DD)",
          type: "controlledText",
          hidden: false,
          readOnly: false,
          required: true,
        },        
        {
          name: "hora",
          label: "Hora (HH:MM:SS)",
          type: "controlledText",
          hidden: false,
          readOnly: false,
          required: true,
        },   
        {
          name: "keystring",
          label: "Keystring",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        }, 
        {
          name: "presupuestos",
          label: "Presupuestos",
          type: "controlledList",
          hidden: false,
          readOnly: false,
          routeList: "/presupuestos/usuarios/presupuestos/",
          tablename: "presupuestos",
          idField: "id",
          deleteField: "int_status",
          routeApiList: "/api/_calculith_usuarios/list_usuarios_presupuestos.php",
          routeApiActions: "/api/_generic/actions.php",
          numberFiles: 0,
          numberImages: 0,
          rowOptions: "bt_edit_row bt_delete_row",
          columns: [
            {
              dataField: "numero",
              text: "Número",
              sort: true,
              headerStyle: { "width": "10%" }
            },
            {
              dataField: "referencia",
              text: "Referencia",
              sort: true,
              headerStyle: { "width": "30%" }
            },
            // {
            //   dataField: "nombre_tienda",
            //   text: "Tienda",
            //   sort: true,
            //   headerStyle: { "width": "10%" }
            // },
            {
              dataField: "nombre",
              text: "Usuario",
              sort: true,
              headerStyle: { "width": "25%" }
            },
            {
              dataField: "cliente",
              text: "Cliente",
              sort: true,
              headerStyle: { "width": "25%" }
            },
            {
              dataField: "total",
              text: "Total",
              sort: true,
              headerStyle: { "width": "10%" }
            }
          ]          
        },
        {
          name: "int_status",
          label: "Estado",
          type: "controlledInformative",
          hidden: true,
          readOnly: true,
          defaultValue: 3,
        }
      ],
      /**********CAMPOS GENERALES  ************/
      component: "EditEmpresasUsuarios",
      routeList: "/presupuestos/empresas/",
      tablename: "_calculith_usuarios",
      idField: "id",
      deleteField: "int_status",
      routeApiControl: "/api/_generic/control.php",
      routeApiDeleteFile: "/api/_generic/delete_file.php",
      routeApiDeleteImage: "/api/_generic/delete_image.php",
      routeApiDeleteImageFile: "/api/_generic/delete_image_file.php",
      routeApiActions: "/api/_calculith_usuarios/actions.php",
      numberFiles: 0,
      numberImages: 0, 
      readOnly: false,
      options: "bt_save bt_back bt_delete",
      informativeHidden: true,
      autoSave: false,
      initialFetch: true,
      skipRedirectSubmit: false,
      submitButtonText: "Guardar",

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES A EXCLUIR DEL ENVIO A LA API
      dataExcludeParentComponent: [
        // "options_provincia",
        // "routeThumbnail1",
        // "routeImage1",
      ],

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES TIPO HIDDEN O READONLY DEL ESTADO A INCLUIR DEL ENVIO A LA API
      dataIncludeParentComponent: []
    }
  }

  render () {

    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Empresas</h1>
        <Card>
          <CardHeader>
            <CardTitle tag="h5">Editar usuario</CardTitle>
            <h6 className="card-subtitle text-muted">
              Introduce la información referente al usuario de la empresa.
            </h6>
          </CardHeader>
          <CardBody>
            <FormEdit 
              component={this.state.component}
              fields={this.state.fields} 
              routeList={`${this.state.routeList}${this.props.match.params.id_empresa}`}
              tablename={this.state.tablename} 
              idField={this.state.idField} 
              deleteField={this.state.deleteField}
              idValue={this.props.match.params.id}
              idParent={this.props.match.params.id_empresa}
              routeApiControl={this.state.routeApiControl} 
              routeApiDeleteFile={this.state.routeApiDeleteFile} 
              routeApiDeleteImage={this.state.routeApiDeleteImage} 
              routeApiDeleteImageFile={this.state.routeApiDeleteImageFile} 
              routeApiActions={this.state.routeApiActions} 
              numberFiles={this.state.numberFiles} 
              numberImages={this.state.numberImages} 
              readOnly={this.state.readOnly} 
              options={this.state.options}
              informativeHidden={this.state.informativeHidden}
              autoSave={this.state.autoSave}
              dataExcludeParentComponent={this.state.dataExcludeParentComponent}
              dataIncludeParentComponent={this.state.dataIncludeParentComponent}
              initialFetch={this.state.initialFetch}
            />            
          </CardBody>
        </Card>
      </Container>
    );
  }
}

//export default EditEmpresasUsuarios;
export default withRouter(EditEmpresasUsuarios);
