import React from "react";
import { Link, Redirect } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faPaperPlane } from "@fortawesome/free-solid-svg-icons";

import {
  // Button,
  Card,
  CardBody,
  // Form,
  // FormGroup,
  // Col,
  // Label,
  // Input,
  // CustomInput
  UncontrolledAlert,
} from "reactstrap";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFolderOpen } from "@fortawesome/free-regular-svg-icons";
// import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import logo from "../assets/img/logo.png";
// import logo from "../assets/img/logo_calculith_vertical5.gif";
import FormLogin from "../components/FormLogin";


class ConfirmRegister extends React.Component {
  
  constructor(props) {
    
    super(props);
    
    this.state = {

      fields: [
        {
          name: "keystring",
          label: "Keystring",
          placeholder: "",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
          required: true,
          defaultValue: this.props.match.params.keystring,
          fetchApi: false,
        },

      ],

      /**********CAMPOS GENERALES  ************/
      component: "ConfirmRegister",
      action: "confirm_register",
      routeList: "/",
      tablename: "_calculith_usuarios",
      idField: "id",
      deleteField: "int_status",
      // routeApiControl: "",
      routeApiControl: "/api/registro/actions.php",
      routeApiDeleteFile: "",
      routeApiDeleteImage: "",
      routeApiActions: "/api/registro/actions.php",
      numberFiles: 0,
      numberImages: 0, 
      readOnly: false,
      options: "bt_back",
      informativeHidden: true,
      autoSave: false,
      updateListForm: false,
      isSignInForm: false,
      initialFetch: true,
      skipRedirectSubmit: false,
      submitButtonText: "Guardar",

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES A EXCLUIR DEL ENVIO A LA API
      dataExcludeParentComponent: [
        // "routeList",
        // "routeApiControl",
        // "routeApiDeleteFile",
        // "routeApiDeleteImage",
        // "routeApiActions",
        // "informativeHidden",
        // "autoSave",
      ],

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES TIPO HIDDEN O READONLY DEL ESTADO A INCLUIR DEL ENVIO A LA API
      dataIncludeParentComponent: []

    };
  }

  
  render() {

    return (

      <React.Fragment>
        <div className="text-center mt-4">
        <img
            src={logo}
            alt="CALCULITH"
            // className="img-fluid rounded-circle mb-3"
            // className="img-fluid mb-3"
            className="img-fluid mb-3"
            width="260"
            height="260"
          />
          {/* <h2>Welcome to admin login</h2> */}
          <h2>Software online de presupuestos</h2>
          <p className="lead">Confirmación de registro</p>
        </div>
        <Card>
          <CardBody>
            <div className="m-sm-4">
              <UncontrolledAlert
                color="success"
                className="alert-outline-coloured my-3"
                isOpen={this.state.errorForm}
                toggle=""
                // key={301}
              >
                <div className="alert-icon">
                  <FontAwesomeIcon icon={faExclamationCircle} fixedWidth />
                </div>
                <div className="alert-message">
                  {/* {this.state.errorForm} */}
                  El proceso de registro en el sistema se ha completado con éxito.
                  Diríjase a la página inicial e introduzca sus datos de acceso.
                </div>
              </UncontrolledAlert>

              {/* <UncontrolledAlert>
                <div className="alert-message">
                  <h4 className="alert-heading">Registro completado con éxito!</h4>
                  <hr />
                  <p>
                    El proceso de registro en el sistema se ha completado con éxito.
                  </p>
                  <p className="mb-0">
                  Diríjase a la página inicial e introduzca sus datos de acceso.
                  </p>
                </div>
              </UncontrolledAlert> */}
              
              <FormLogin 
                fields={this.state.fields} 
                routeList={this.state.routeList} 
                tablename={this.state.tablename} 
                idField={this.state.idField} 
                deleteField={this.state.deleteField}
                idValue={this.props.match.params.id}
                routeApiControl={this.state.routeApiControl} 
                routeApiDeleteFile={this.state.routeApiDeleteFile} 
                routeApiDeleteImage={this.state.routeApiDeleteImage} 
              routeApiDeleteImageFile={this.state.routeApiDeleteImageFile} 
                routeApiActions={this.state.routeApiActions} 
                numberFiles={this.state.numberFiles} 
                numberImages={this.state.numberImages}
                readOnly={this.state.readOnly} 
                options={this.state.options}
                informativeHidden={this.state.informativeHidden}
                autoSave={this.state.autoSave}
                dataExcludeParentComponent={this.state.dataExcludeParentComponent}
                dataIncludeParentComponent={this.state.dataIncludeParentComponent}
                updateListForm={this.state.updateListForm}
                action={this.state.action}
                isSignInForm={this.state.isSignInForm}
                initialFetch={this.state.initialFetch}
              />
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default ConfirmRegister;
