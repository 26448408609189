/*

FORMLIST

COMPONENTE QUE MONTA LOS LISTADOS CON LOS REGISTROS DE UNA CONSULTA
  
ULTIMAS MODFIFICACIONES:

  - 2021-06-15 > AÑADIDA LA OPCION DE ACTUALIZARSE CUANDO DESDE EL COMPONENTE PADRE LE CAMBIAMOS LA PROPIEDAD updateLists
  - 2021-06-21 > CREADAS PROP dataExcludeParentComponent Y VARIABLE dataExcludeComponent CON LOS NOMBRES DE LAS VIARIABLES A NO ENVIAR A LA API PARA ACOTAR INFO ENVIADA
  - 2021-06-21 > AÑADIDO UN idParent POR SI EL LISTADO DEPENDE DE UN PADRE
  - 2021-06-22 > CORREGIDO ERROR EXPORADICO BOTON ELIMINAR EN LINEAS
  - 2021-06-22 > AÑADIDA PROP updateListForm CON LA FUNCION DEL FORMULARIO A EJECUTAR PARA ACTUALIZARLO CUANDO SE ELIMINAN LINEAS DEL LISTADO 
  - 2021-06-23 > AÑADIDO ARRAY nonSelectable AUTORELLENADO SI ESTA DEFINIDO optionsIfField
  - 2021-07-16 > AÑADIDA GESTION DE ERRORES EN componentDidMount Y render
  - 2021-07-21 > AÑADIDO TOKEN EN LLAMADAS A API
  - 2021-07-27 > IMPORTADAS ALGUNAS FUNCIONES DE Functions.js
  - 2021-07-31 > AÑADIDO DUPLICAR SIMPLE A CADA FILA
  - 2021-11-12 > AÑADIDA PROPIEDAD pagination PARA PAGINAR O NO EL LISTADO
  - 2022-03-17 > AÑADIDA PROPIEDAD component PARA CONSULTAR LOS PERMISOS
  - 2022-03-27 > AÑADIDAS PROPIEDAD Y VARIABLE ESTADO dataIncludeParentComponent, dataIncludeComponent PARA INCLUIR EN FETCH VARIBLES TIPO HIDDEN Y READONLY

PROPIEDADES:

  - component > NOMBRE DEL COMPONENTE PARA ENVIAR A LA API Y BUSCAR LOS PERMISOS
  - tablename > LA TABLA A LA QUE AFECTA
  - columns > EL ARRAY DE COLUMNAS DEL LISTADO
  - routeList > LA RUTA AL FORMULARIO DE EDICION O ALTA
  - routeApiList > LA LLAMADA A LA API QUE DEVUELVE LOS REGISTROS DEL LISTADO
  - routeApiActions > LA LLAMADA A LA API PARA EJECUTAR LAS ACCIONES PARA ELIMINAR
  - rowOptions > LOS BOTONES A LA DERECHA DE CADA REGISTRO. PUEDEN SER: bt_edit_row bt_delete_row bt_view_row bt_duplicate_row
  - readOnly > SI ES TRUE NO APARECERÁN LOS BOTONES DE ELIMINAR, NI GUARDAR, NI DUPLICAR
  - idParent > EL VALOR DEL ID DEL REGISTRO DEL FORMULARIO PADRE
  - idField > EN CAMPO ID DE LA TABLA
  - deleteField > EL CAMPO DE BORRADO DE LA TABLA (SE PONE A 0 CUANDO SE ELIMINA UN REGISTRO)
  - numberFiles > EL NÚMERO DE ARCHIVOS ASOCIADOS A CADA REGISTRO
  - numberImages > EL NÚMERO DE IMÁGENES ASOCIADOS A CADA REGISTRO
  - readOnly > SI ES TRUE NO MUESTRA BOTONES NI EN CADA LINEA NI DEBAJO DEL LISTADO
  - optionsIfField > SI ES undefined, SI SU VALOR ES MAYOR QUE 0 O ES true SE MUESTRAN LAS OPCIONES PARA ESA FILA
  - updateLists > PROPIEDAD QUE CUANDO CAMBIA EN EL COMPONENTE PADRE HACE QUE SE ACTUALICE EL LISTADO
  - updateListForm > FUNCION QUE ACTUALIZA EL FORMULARIO PADRE SI SE LE PASA A ESTE updateListForm A TRUE
  - dataExcludeParentComponent > ARRAY CON NOMBRES DE VARIABLES DEL ESTADO QUE NO SE DEBEN ENVIAR A LA API PARA ACOTAR LA INFO ENVIADA
  - pagination > SI ES FALSE NO SE PAGINA EL LISTADO

FUNCIONES:

  - linkFormatter > ES LO QUE DEFINE LA COLUMNA DE ACCIONES DEPENDIENDO DE LAS OPCIONES PASADAS AL COMPONENTE
  - handleOnSelect > FUNCION QUE AÑADE COMO SELECCIONADO UN REGISTRO
  - handleOnSelectAll > FUNCION QUE AÑADE COMO SELECCIONADOS TODOS LOS REGISTROS
  - handleDelete > FUNCION QUE ABRE LA MODAL PARA CONFIRMAR LA ELIMINACION DEL REGISTRO
  - handleDeleteMultiple //FUNCION QUE ABRE LA MODAL PARA CONFIRMAR LA ELIMINACION DE VARIOS REGISTROS
  - handleDuplicate > FUNCION QUE ABRE LA MODAL PARA CONFIRMAR LA DUPLICACION DEL REGISTRO
  - deleteRegister > FUNCION EJECUTADA AL CONFIRMAR EN LA VENTANA MODAL Y QUE ELIMINA LOS REGISTROS SELECCIONADOS
  - duplicateRegister > FUNCION EJECUTADA AL CONFIRMAR EN LA VENTANA MODAL PARA DUPLICAR LOS REGISTROS SELECCIONADOS
  - componentDidMount > FUNCION QUE LLAMA A LA API PARA CARGAR LAS LINEAS DEL LISTADO Y OCULTAR LAS COLUMNAS SEGÚN PERMISOS
  - componentDidUpdate > FUNCION QUE ACTUALIZA EL LISTADO CUANDO CAMBIA LA PROPIEDAD updateLists
  - render > RENDERIZADO

  - (*** VER EL RESTO DE FUNCIONES EN EL COMPONENTE DE FUNCIONES ***)


*/

import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Container, Row, Col, Button, Alert} from "reactstrap";
import { Link, withRouter } from 'react-router-dom';

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faFolderOpen, faFile, faTrashAlt, faBell, faInfoCircle, faCopy } from "@fortawesome/free-regular-svg-icons";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import { 
  FormGroup,
  Modal, 
  Spinner,
  UncontrolledAlert,
} from "reactstrap";

import { toastr } from "react-redux-toastr";

import ModalWindow from "./ModalWindow";

import {
  modalToggle,
  modalCancel,
  modalConfirm,
  modalAccept,
  // handleFileChange,
  // handleFileDelete,
  // handleImageChange,
  // handleImageFileChange,
  // handleImageDelete,
  // handleImageFileDelete,
  // handleDelete,
  // handleDuplicate,
  // handleSendEmail,
  // deleteRegister,
  // duplicateRegister,
  // sendRegister,
  redirect,
  redirectDuplicateRegister,
  // redirectSendRegister,
  // updateForm,
  // initialState,

  // handleValidSubmit,
  // handleChange,
  // handleChangeOnSelect,
  // handleChangeOnEditor,
  // handleChangeOnSwitch,
  // handleChangeOnEmail,
  handleErrors,
  showToastr,
  // validateEmail,
  // validateFields,
  excludeDataFetch,
  fetchApi,
} from "./Functions";

class FormList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

      // CAMPO DEL TOKEN
      token: localStorage.getItem("token") || sessionStorage.getItem("token"),

      // routeList: this.props.routeList,
      rows: [],
      // columns: this.props.columns,
      columnsWithFormatter: [],
      selected: [],
      nonSelectable: [],
      isLoading: false,

      //CAMPOS VENTANA MODAL
      modalType: "",
      modalTitle: "",
      modalText: "",
      modalIsOpen: false,

      //CAMPO QUE ABRE EL MODAL DE LOADING
      isLoadingModal: false,

      //CAMPOS ACCIONES
      action: "",

      //CAMPOS FUNCIONES ELIMINAR
      selectedId: "",

      //CAMPOS QUE ESTABAN EN props ANTES
      component: props.component,
      tablename: props.tablename,
      id: "", //AQUI PONDREMOS LOS IDS A ELIMINAR
      idParent: props.idParent,
      idField: props.idField,
      deleteField: props.deleteField,
      numberFiles: props.numberFiles,
      numberImages: props.numberImages,
      // autoSave: props.autoSave,
      updateListForm: props.updateListForm,

      // CAMPOS ERRORES (DEBEN CONTENER "error" AL PRINCIPIO PARA QUE NO SE EXCLUYAN AL ENVIAR A LA API)
      errorValidation: false,
      errorForm: "",
      toastrIsOpen: false,

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES A EXCLUIR DEL ENVIO A LA API
      dataExcludeParentComponent: props.dataExcludeParentComponent,
      dataExcludeComponent: [
        "token",
        "rows",
        "columnsWithFormatter",
        "selected",
        "nonSelectable",
        "isLoading",
        "modalType",
        "modalTitle",
        "modalText",
        "modalIsOpen",
        "isLoadingModal",
        "errorValidation",
        "errorForm",
        "toastrIsOpen",
        "selectedId",
        "numberFiles",
        "numberImages",
        "updateListForm",
        "dataExcludeParentComponent",
        "dataExcludeComponent",
        "dataIncludeParentComponent",
        "dataIncludeComponent"
      ],

      dataIncludeParentComponent: props.dataIncludeParentComponent,
      dataIncludeComponent: []
    };
    
    this.modalToggle = modalToggle.bind(this);
    this.modalCancel = modalCancel.bind(this);
    this.modalConfirm = modalConfirm.bind(this);
    this.modalAccept = modalAccept.bind(this);
    this.redirect = redirect.bind(this);
    this.redirectDuplicateRegister = redirectDuplicateRegister.bind(this);
    this.handleErrors = handleErrors.bind(this);
    this.showToastr = showToastr.bind(this);
    this.excludeDataFetch = excludeDataFetch.bind(this);
    this.fetchApi = fetchApi.bind(this);

    //this.linkFormatter = this.linkFormatter.bind(this);
    this.handleOnSelect = this.handleOnSelect.bind(this);
    this.handleOnSelectAll = this.handleOnSelectAll.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteMultiple = this.handleDeleteMultiple.bind(this);
    this.handleDuplicate = this.handleDuplicate.bind(this);
    this.deleteRegister = this.deleteRegister.bind(this);
    this.duplicateRegister = this.duplicateRegister.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentDidUpdate = this.componentDidUpdate.bind(this);
  }

  //ES LO QUE DEFINE LA COLUMNA DE ACCIONES DEPENDIENDO DE LAS OPCIONES PASADAS AL COMPONENTE
  linkFormatter = (cell, row, rowIndex, formatExtraData) => {

    //alert(`${row[this.props.optionsIfField]}`);
    return (

      <div>

      {/* COMPROBAMOS EL VALOR DEL CAMPO DEL QUE DEPENDE QUE SE MUESTREN LAS ACCIONES */}
      { ((`${row[this.props.optionsIfField]}` > 0) || (`${row[this.props.optionsIfField]}` === "true") || (`${row[this.props.optionsIfField]}` === "undefined")) ? ( 

        <div>
          { (formatExtraData.indexOf("bt_edit_row") !== -1) ? (
            <Button name="bt_edit_row" color="primary" className="mr-1 mb-1" id={`${row[this.props.idField]}`} tag={Link} to={`${this.props.routeList}${row[this.props.idField]}`} title="Editar" >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          )
          : null
          }

          { (formatExtraData.indexOf("bt_view_row") !== -1) ? (
            <Button name="bt_view_row" color="success" className="mr-1 mb-1" id={`${row[this.props.idField]}`} tag={Link} to="/#" onClick={(event) => {event.preventDefault(); window.open(`${process.env.REACT_APP_API_DOMAIN}${row[this.props.idField]}`);}} title="Visualizar" >
              <FontAwesomeIcon icon={faFile} />
            </Button>
          )
          : null
          }

          { (formatExtraData.indexOf("bt_duplicate_row") !== -1) ? (
            // <Button name="bt_delete" color="danger" className="mr-1 mb-1" id={`${row[this.props.idField]}`} onClick={this.handleDelete} title="Eliminar" >
            <Button name="bt_duplicate_row" color="warning" className="mr-1 mb-1" id={`${row[this.props.idField]}`} onClick={(event) => {event.preventDefault(); event.stopPropagation(); this.handleDuplicate(`${row[this.props.idField]}`)}} title="Duplicar" >
              <FontAwesomeIcon icon={faCopy} />
            </Button>
          )
          : null
          }
          
          { (formatExtraData.indexOf("bt_delete_row") !== -1) ? (
            // <Button name="bt_delete" color="danger" className="mr-1 mb-1" id={`${row[this.props.idField]}`} onClick={this.handleDelete} title="Eliminar" >
            <Button name="bt_delete_row" color="danger" className="mr-1 mb-1" id={`${row[this.props.idField]}`} onClick={(event) => {event.preventDefault(); event.stopPropagation(); this.handleDelete(`${row[this.props.idField]}`)}} title="Eliminar" >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          )
          : null
          }
        </div>

      )
      : null
      } 

      </div>
      
    );
  };

  //FUNCION QUE AÑADE COMO SELECCIONADO UN REGISTRO
  handleOnSelect = (row, isSelect) => {

    //let idField = `row[${this.props.idField}]`;
    let idValue = `${row[this.props.idField]}`;
    //alert(idValue);

    if (isSelect) {
      this.setState(() => ({
        selected: [...this.state.selected, idValue]
      }));
    } else {
      this.setState(() => ({
        selected: this.state.selected.filter(x => x !== idValue)
      }));
    }
  }

  //FUNCION QUE AÑADE COMO SELECCIONADOS TODOS LOS REGISTROS
  handleOnSelectAll = (isSelect, rows) => {

    const ids = rows.map(row => `${row[this.props.idField]}`);
    if (isSelect) {
      this.setState(() => ({
        selected: ids
      }));
    } else {
      this.setState(() => ({
        selected: []
      }));
    }
  }

  //FUNCION QUE ABRE LA MODAL PARA CONFIRMAR LA ELIMINACION DEL REGISTRO
  handleDelete(id) {

    // alert(id);
    this.setState({ action: "delete" });
    this.setState({ selectedId: id });
    this.setState({ modalType: "confirmModal" });
    this.setState({ modalTitle: "Confirmación" });
    this.setState({ modalText: "¿Está seguro de que desea eliminar el registro seleccionado?" });

    /***********ABRIMOS VENTANA MODAL Y PEDIMOS CONFIRMACION *************/
    this.setState({ modalIsOpen: true });
  }

  //FUNCION QUE ABRE LA MODAL PARA CONFIRMAR LA ELIMINACION DE VARIOS REGISTROS
  handleDeleteMultiple(e) {

    e.preventDefault();
    const target = e.target;
    this.setState({ action: "delete" });
    this.setState({ modalType: "confirmModal" });
    this.setState({ modalTitle: "Confirmación" });
    this.setState({ modalText: "¿Está seguro de que desea eliminar los registros seleccionados?" });

    /***********ABRIMOS VENTANA MODAL Y PEDIMOS CONFIRMACION *************/
    this.setState({ modalIsOpen: true });
  }

  //FUNCION QUE ABRE LA MODAL PARA CONFIRMAR LA DUPLICACION DEL REGISTRO
  handleDuplicate(id) {

    // alert(id);
    this.setState({ action: "duplicate" });
    this.setState({ selectedId: id });
    this.setState({ modalType: "confirmModal" });
    this.setState({ modalTitle: "Confirmación" });
    this.setState({ modalText: "¿Está seguro de que desea duplicar el registro seleccionado?" });

    /***********ABRIMOS VENTANA MODAL Y PEDIMOS CONFIRMACION *************/
    this.setState({ modalIsOpen: true });
  }

  // //FUNCION QUE ABRE O CIERRA LA VENTANA MODAL DE CONFIRMACIÓN
  // modalToggle() {
  //   this.setState({ modalIsOpen: !this.state.modalIsOpen });
  // }

  //FUNCION EJECUTADA AL CANCELAR EN LA VENTANA MODAL
  // modalCancel() {
  //   this.modalToggle();
  //   this.setState({ modalType: "" });
  //   this.setState({ modalTitle: "" });
  //   this.setState({ modalText: "" });
  //   // SI VENIMOS DE DUPLICAR Y NO REDIRIGIMOS ACTUALIZAMOS EL LISTADO
  //   if (this.state.action === "redirect_duplicate") {      
  //     this.componentDidMount();
  //   }
  //   this.setState({ action: "" });
  //   this.setState({ selectedId: "" });
  // }

  // //FUNCION EJECUTADA AL CONFIRMAR EN LA VENTANA MODAL
  // modalConfirm() {

  //   this.modalToggle();
  //   this.setState({isLoadingModal: true});

  //   switch(this.state.action) {

  //       case "delete" : 
  //           this.deleteRegister();
  //           break;

  //       case "duplicate" :
  //           this.duplicateRegister();
  //           break;
        
  //       case "send" :
  //           this.sendRegister();
  //           break;
        
  //       case "redirect_duplicate" :
  //           this.redirectDuplicateRegister();
  //           break;
        
  //       default:

  //   } 
  // };

  //FUNCION EJECUTADA AL CONFIRMAR EN LA VENTANA MODAL Y QUE ELIMINA LOS REGISTROS SELECCIONADOS
  deleteRegister() {

    this.setState({isLoadingModal: true});

    let selectedIds = [];
    let error = false;

    this.modalToggle();

    if (this.state.selectedId != "") { //SE TRATA DE UNA ELIMNACIÓN SIMPLE EJECUTADA DESDE EL BOTÓN DE LA FILA
      // alert(this.state.selectedId)
      selectedIds[0] = this.state.selectedId; //PONEMOS EL 0 PARA FORZAR A QUE SEA UN ARRAY
    } else { //SE TRATA DE UNA ELIMINACION MULTIPLE
      selectedIds = this.state.selected;
    }

    // selectedIds.map((item, error) => {
    selectedIds.map((item) => {

      // this.setState({ id: item }, this.fetchApi());
      this.state["id"] = item ; //SI LO HACEMOS CON SETSTATE SE POSPONE, HACE MERGE Y SOLO SE RECOGE EL ULTIMO ESTADO EN fetchApi INCLUSO CON CALLBACK
      this.fetchApi(this.props.routeApiActions);

      // if (this.state.errorForm) {
      //   error = true;
      // }

      return null;

    });

    // if (!error) {

      let idField = this.props.idField; //TENEMOS QUE ASIGNARLA AQUI A UNA VARIABLE PORQUE DENTRO DE LA FUNCION DEL FILTER this NO ESTÁ ATADO

      let newRows = this.state.rows.filter(
        function(e) {
          return this.indexOf(e[idField]) < 0;
        }, selectedIds
      );

      this.setState({ rows : newRows });

      if (this.state.selectedId != "") { //POR SI HA SIDO UNA ELIMINACION SIMPLE
        this.setState({ selectedId : "" }); 
      } else { 
        this.setState({ selected : [] }); 
      }

      // SI DE LA API SE DEVUELVE QUE HAY QUE ACTUALIZAR EL FORMULARIO ACTUALIZAMOS
      if (this.state.updateListForm) {
        // this.props.updateListForm = false;
        this.props.updateListForm();
      }
      
    //}

    this.setState({ action : "" });
    this.setState({ isLoadingModal: false });
  }

  //FUNCION EJECUTADA AL CONFIRMAR EN LA VENTANA MODAL PARA DUPLICAR LOS REGISTROS SELECCIONADOS
  duplicateRegister() {

    this.setState({isLoadingModal: true});

    let selectedIds = []
    // let anyError = false

    this.modalToggle();

    if (this.state.selectedId != "") { //SE TRATA DE UNA DUPLICACION SIMPLE EJECUTADA DESDE EL BOTÓN DE LA FILA
      // alert(this.state.selectedId)
      selectedIds[0] = this.state.selectedId; //PONEMOS EL 0 PARA FORZAR A QUE SEA UN ARRAY
    } else { //SE TRATA DE UNA DUPLICACION MULTIPLE
      selectedIds = this.state.selected;
    }

    selectedIds.map( async (item) => {

      // this.setState({ id: item }, this.fetchApi());
      this.state["id"] = item ; //SI LO HACEMOS CON SETSTATE SE POSPONE, HACE MERGE Y SOLO SE RECOGE EL ULTIMO ESTADO EN fetchApi INCLUSO CON CALLBACK
      await this.fetchApi(this.props.routeApiActions);

      return null;

    })
    
    this.setState({ action : "" });
    // this.setState({ isLoadingModal: false });
  }
  
  // //FUNCION QUE REDIRIGE A routeList TRAS ENVIAR EMAIL
  // redirectDuplicateRegister() {

  //   this.setState({ action: "" });
  //   this.setState({ modalType: "" });
  //   this.setState({ modalTitle: "" });
  //   this.setState({ modalText: "" });
  //   this.props.history.push(`${this.props.routeList}${this.state.insert_id}`);
  // }


  async componentDidMount() {

    this.setState({ isLoading: true });

    await this.fetchApi(`${this.props.routeApiList}`);

    // alert(this.props.optionsIfField);
    // SI optionsIfField TIENE UN VALOR MONTAMOS EL ARRAY DE nonSelectable DEL LISTADO
    if (this.props.optionsIfField) {
      const nonSelectable = this.state.rows.map( (row) => {
        if (!(row[`${this.props.optionsIfField}`])) {
          return row[`${this.props.idField}`];
        }
      });
      // this.setState({ nonSelectable: nonSelectable }, console.log(nonSelectable));
      this.setState({ nonSelectable: nonSelectable }) ;
      // if ((`${row[this.props.optionsIfField]}` > 0) || (`${row[this.props.optionsIfField]}` === "true") || (`${row[this.props.optionsIfField]}` === "undefined")) 
    }

    // let sentData = new FormData();

    // let state = JSON.stringify(this.excludeDataFetch());
    // sentData.append( "state", state );
    // // console.log(sentData);

    // //CARGAMOS LAS LÍNEAS DESDE LA API
    // // await fetch(`${this.props.routeApiList}`)    
    // await fetch(`${this.props.routeApiList}`, {
    //   method: "post",
    //   body: sentData,
    //     headers: { 
    //       "Token": `${this.state.token}`,
    //       // "Content-Type": "application/json",
    //       "Accept": "application/json, text-plain, */*"
    //     }
    // })
    // .then((receivedData) => {
    //   return receivedData.json();
    // })
    // .then((receivedData) => {
      
    //   // alert(receivedData.length);
      
    //   if (receivedData.length>0) {
        
    //     // alert(receivedData.length);
        
    //     receivedData.map((data) => {  
          
    //       if (data["errorForm"]) {

    //         // alert("ERROR!");
    //         this.setState({"errorForm": data["errorForm"]});
    //         this.showToastr("Error", data["errorForm"]);

    //       } else {

    //         // alert(data.rows.length);

    //         // this.setState({ rows: data.rows }, function () {
    //         this.setState({ rows: JSON.parse(JSON.stringify(data["rows"])) }, function () {
              
    //           // alert(this.props.optionsIfField);
    //           // SI optionsIfField TIENE UN VALOR MONTAMOS EL ARRAY DE nonSelectable DEL LISTADO
    //           if (this.props.optionsIfField) {
    //             const nonSelectable = this.state.rows.map( (row) => {
    //               if (!(row[`${this.props.optionsIfField}`])) {
    //                 return row[`${this.props.idField}`];
    //               }
    //             });
    //             this.setState({ nonSelectable: nonSelectable}, console.log(nonSelectable)) ;
    //             // if ((`${row[this.props.optionsIfField]}` > 0) || (`${row[this.props.optionsIfField]}` === "true") || (`${row[this.props.optionsIfField]}` === "undefined")) 
    //           }
    //         });
    //       }

    //     });
    //   }
    //   //console.log(receivedData);
    //   // console.log("rows", this.state.rows.length);
    //   this.setState({ isLoading: false });
    //   this.setState({ isLoadingModal: false });
    // })
    // .catch((error) => console.log(error));

    // this.fetchApi(this.props.routeApiList);

    let temp = this.props.columns;

    // temp[3] = { ...temp[3], hidden: true };

    // OCULTAMOS LAS COLUMNAS QUE HAYAN VENIDO EN EL fetchApi EN columnsHidden
    if (this.state.columnsHidden) {
      temp = temp.map( item => {
        // if (item.dataField === this.state.columnsHidden) {        
        if (this.state.columnsHidden.indexOf(item.dataField) !== -1) {        
          // item = [...item, { "hidden": true }]
          item.hidden = true
        }
        return item;
      });
    }

    //AÑADIMOS LA COLUMNA DE ACCIONES UTILIZANDO formatter SI NO ES READONLY
    if (!(this.props.readOnly)) {    
      temp = [...temp,
      {
        "dataField": "actions",
        "text": "Acciones",
        "sort": false,
        "isDummyField" : true,
        "formatter" : this.linkFormatter,
        "formatExtraData": this.props.rowOptions,
        "headerStyle": { "width": "14%" }
      }]
    }
    
    this.setState({columnsWithFormatter: temp});

    //NO CONSIGO QUE FUNCIONE PORQUE NO SE PUEDE PASAR UNA FUNCION COMO VALOR DENTRO DE UN JSON
    // let variable = {linkFormatter}  
    // fetch('/api/familias/data4_columns.json')
    // // //fetch('/api/familias/list_familias_columns.php')
    //   .then(data => {
    //     // console.log(data.json);
    //     // return data = JSON.parse(JSON.stringify(data).replace("###this.linkFormatter###","{this.linkFormatter}"));

    //     return data.json();
    //     // debugger;
    //     // alert(data)
    //     // console.log(data);
    //     // return JSON.parse(data);
    //     // return require("c:/xampp/htdocs/admin4.com/node_modules/jsonfn/jsonfn.js").JSONfn.parse(data);
    //   }).then(data => {
    //     data = JSON.parse(JSON.stringify(data).replace("###this.linkFormatter###",variable));
    //     console.log(data);
    //     this.setState({ columns: data });
    //   }).then(() => {
    //     //alert(this.state.columns.length)
    //     this.setState({isLoading: false});
    //   })
    // //debugger;
    // //console.log(data);
    // //console.log("columns", this.state.columns.length);
  }

  // FUNCION QUE ACTUALIZA EL LISTADO CUANDO CAMBIA LA PROPIEDAD updateLists
  componentDidUpdate(prevProps) {

    if (this.props.updateLists !== prevProps.updateLists) {
      // alert("ACTUALIZO LISTADO!");
      this.setState({isLoadingModal: true});
      this.componentDidMount();
      // this.setState({columnsWithFormatter: true});
      // this.setState(prevState => ({
      //   columnsWithFormatter: {
      //       ...prevState.columnsWithFormatter,
      //       [prevState.columnsWithFormatter[1].text]: "HOLA!",
      //   },
      // }), alert("LLEGO!"));

    }

  }

  render () {

    const { SearchBar } = Search;

    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      nonSelectable: this.state.nonSelectable,
      selected: this.state.selected,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll,
      bgColor: '#dee0f2'
    };

    if (this.state.isLoading || !(this.state.columnsWithFormatter.length>0)) { //EL isLoading SOLO NO FUNCIONA
      return (
        <div className="loading">
          {/* <Modal isOpen={isLoadingModal} centered> */}
          <Spinner color="primary" type="grow" className="mr-2" size="lg" />
          {/* </Modal> */}
        </div>
      );
    } else {
      //alert("RENDERIZO LISTADO");
      return (
        <div>
          <ToolkitProvider
            keyField = { (!(this.props.idField)) ? "id" : this.props.idField }
            data = { this.state.rows }
            columns = { this.state.columnsWithFormatter }
            // noDataText = 'Empty Table'
            // withoutNoDataText = {true}
            selectRow = {selectRow}
            search
          >
            {
              props => (
                <div>
                  <Row className="content-fluid text-right bg-aqua">
                    <Col sm={12} className="content-fluid">                        
                      <SearchBar { ...props.searchProps } sm={12} className="content-fluid" />
                    </Col>
                  </Row>                      
                  <BootstrapTable 
                    { ...props.baseProps } 
                    bootstrap4 
                    striped 
                    hover 
                    bordered = {false} 
                    // noDataText = 'Empty Table'
                    // withoutNoDataText = {true}
                    // keyField="id" 
                    // data={this.state.rows} 
                    // columns={this.state.columns} 
                    selectRow = {selectRow}
                    selected = {this.state.selected}
                    onSelect = {this.handleOnSelect}
                    onSelectAll = {this.handleOnSelectAll}
                    wrapperClasses = "table-responsive" // This is the style provided by bootstrap 4, this will set the parent div with that class 
                    pagination = { (this.props["pagination"] === false) ? false :  paginationFactory({ sizePerPage: 10, sizePerPageList: [5, 10, 25, 50] }) }
                  />
                </div>
              )
            }
          </ToolkitProvider>

          {/* SI NO ES readOnly NI ES UN SUBLISTADO EN UN FORMULARIO DE ALTA MUESTRA LOS BOTONES DE AÑADIR Y ELIMINAR */}
          { ((!(this.props.readOnly)) && (!(this.props.idParent==0))) ? (
            <Container sm={12} className="text-center container-fluid">
              <Button name="bt_create" color="primary" tag={Link} to={`${this.props.routeList}0`} className="mr-1 mb-1" >
                <FontAwesomeIcon icon={faFolderOpen} /> Añadir
              </Button>
              <Button name="bt_delete_varios" color="danger" tag={Link}  to="/#" onClick={this.handleDeleteMultiple} className="mr-1 mb-1" >
                <FontAwesomeIcon icon={faTrashAlt} /> Eliminar
              </Button>
            </Container>
          ) : null }

          {/* SI ES UN SUBLISTADO Y EL FORMULARIO EN EL QUE APARECE ES PARA UN ALTA ENTONCES MUESTRA UN MENSAJE */}
          { (this.props.idParent==0) ? (

            <FormGroup row>
              <Col sm={{ size: 8, offset: 2 }}>
                <UncontrolledAlert color="danger" className="alert-outline-coloured my-2" key="1">
                  <div className="alert-icon">
                    <FontAwesomeIcon icon={faBell} fixedWidth />
                  </div>
                  <div className="alert-message">
                    <strong>Registro principal en creación, sin Id asociado todavía:</strong> En el siguiente paso podrá asignarle registros en este sublistado.
                  </div>
                </UncontrolledAlert>
              </Col>
            </FormGroup>

          ) : null }

          <FormGroup row>
              <Col sm={{ size: 8, offset: 2 }}>
                <UncontrolledAlert
                  color="danger"
                  className="alert-outline-coloured my-3"
                  isOpen={this.state.errorForm}
                  toggle=""
                  // key={301}
                >
                  <div className="alert-icon">
                    <FontAwesomeIcon icon={faExclamationCircle} fixedWidth />
                  </div>
                  <div className="alert-message">
                    {this.state.errorForm}
                  </div>
                </UncontrolledAlert>
              </Col>
            </FormGroup>


          <ModalWindow modalType={this.state.modalType} modalTitle={this.state.modalTitle} modalText={this.state.modalText} isOpen={this.state.modalIsOpen} modalToggle={this.modalToggle} modalConfirm={this.modalConfirm} modalCancel={this.modalCancel} modalAccept={this.modalAccept} />

          <Modal isOpen={this.state.isLoadingModal} centered >
            {/* <Spinner animation="grow" /> */}
          </Modal>

        </div>
      );
    }
  };
}

// export default Listado;
export default withRouter(FormList);
