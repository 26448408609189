/*

COMPONENTE PARA EL INPUT DE IMÁGENES RELACIONADAS A UN REGISTRO EN LOS FORMULARIOS

PROPIEDADES:

  - id > EL NÚMERO DE LA IMAGEN ASOCIADA AL REGISTRO
  - idParent > EL ID DEL REGISTRO PRINCIPAL AL QUE ESTÁ ASOCIADA LA IMAGEN
  - tablename > LA TABLA DONDE SE GUARDA LA IMAGEN
  - availableImage > SI EXISTE UNA IMAGEN YA EN LA BBDD O SI NO
  - handleImageChange > FUNCIÓN A EJECUTAR CUANDO SE SELECCIONE UNA IMAGEN
  - handleImageDelete > FUNCIÓN A EJECUTAR CUANDO SE ELIMINE UNA IMAGEN
  - name > EL VALOR DEL NOMBRE DEL INPUT EN EL FORMULARIO
  - routeImage > EL VALOR DE LA RUTA A LA IMAGEN
  - routeThumbnail > EL VALOR DE LA RUTA A LA MINIATURA DE LA IMAGEN
  - readOnly > SI SE PUEDE EDITAR O NO

FUNCIONES:

  - render > RENDERIZADO

*/
import React from "react";
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen, faFile, faTrashAlt} from "@fortawesome/free-regular-svg-icons";

import {
  // AvForm,
  AvField,
  // AvGroup,
  // AvInput
  // AvFeedback,
  // AvRadioGroup,
  // AvRadio,
  // AvCheckboxGroup,
  // AvCheckbox
} from "availity-reactstrap-validation";
import {
  // Card,
  // CardBody,
  // CardHeader,
  // CardTitle,
  Col,
  // Container,
  Button,
  Label,
  FormGroup,
  Input,
  // CustomInput
} from "reactstrap";

class InputImage extends React.Component {

  constructor() {
    super();
    this.state = {
    };
  }

  render () {

    return (
      <div>
      { (this.props.availableImage === "1") ? 
        <FormGroup row>
          <Col sm={2}>
            <div className="mb-1">
              <a href={`${process.env.REACT_APP_API_DOMAIN}${this.props.routeImage}`} target="_blank" rel="noopener noreferrer" >
                <img src={this.props.routeThumbnail} className="img-fluid border" alt="" />
              </a>
            </div>
          </Col>
          <Col sm={4}>
            <Input type="text" id="route" name="route" className="container-fluid mr-1 mb-1" value={this.props.routeImage} disabled />
            <input name={this.props.name} id={this.props.id} type="file" ref={image => this.image = image} onChange={this.props.handleImageChange} hidden />
          </Col>
          <Col sm={6}>
            <Button name="bt_select_image" color="primary" className="mr-1 mb-1" onClick={() => this.image.click()} disabled={this.props.disabled} >
              <FontAwesomeIcon icon={faFolderOpen} /> Seleccionar
            </Button>
            {/* <Button name="bt_view_image" color="success" className="mr-1 mb-1" tag={Link} to="/#" onClick={(event) => {event.preventDefault(); window.open(`${process.env.REACT_APP_API_DOMAIN}/get_img.php?id=${this.props.idParent}&n_img=${this.props.id}&tablename=${this.props.tablename}`);}} > */}
            <Button name="bt_view_image" color="success" className="mr-1 mb-1" tag={Link} to="/#" onClick={(event) => {event.preventDefault(); window.open(`${process.env.REACT_APP_API_DOMAIN}${this.props.routeImage}`);}} >
              <FontAwesomeIcon icon={faFile} /> Visualizar
            </Button>
            <Button name="bt_delete_image" id={this.props.id} color="danger" className="mr-1 mb-1" tag={Link} to="/#" onClick={this.props.handleImageDelete} disabled={this.props.disabled} >
              <FontAwesomeIcon icon={faTrashAlt} /> Eliminar
            </Button>
          </Col>
        </FormGroup>
      :
        <FormGroup row>
          <Col sm={2}>
            <div className="mb-1">
              <img src={this.props.routeThumbnail} className="img-fluid border" alt="" />
            </div>
          </Col>
          <Col sm={4}>
            <Input type="text" id="route" name="route" className="container-fluid mr-1 mb-1" value={this.props.routeImage} disabled />
            <input name={this.props.name} id={this.props.id} type="file" ref={image => this.image = image} onChange={this.props.handleImageChange} hidden />
          </Col>
          <Col sm={6}>
            <Button name="bt_select_image" color="primary" className="mr-1 mb-1" onClick={() => this.image.click()}  disabled={this.props.disabled} >
              <FontAwesomeIcon icon={faFolderOpen} /> Seleccionar
            </Button>
            {/* <Button name="bt_view_image" color="success" className="mr-1 mb-1" tag={Link} to="/#" onClick={(event) => {event.preventDefault(); window.open(`${process.env.REACT_APP_API_DOMAIN}/get_img.php?id=${this.props.idParent}&n_img=${this.props.id}&tablename=${this.props.tablename}`);}} disabled > */}
            <Button name="bt_view_image" color="success" className="mr-1 mb-1" tag={Link} to="/#" onClick={(event) => {event.preventDefault(); window.open(`${process.env.REACT_APP_API_DOMAIN}${this.props.routeImage}`);}} disabled >
              <FontAwesomeIcon icon={faFile} /> Visualizar
            </Button>
            <Button name="bt_delete_image" id={this.props.id} color="danger" className="mr-1 mb-1" tag={Link} to="/#" onClick={this.props.handleImageDelete} disabled >
              <FontAwesomeIcon icon={faTrashAlt} /> Eliminar
            </Button>
          </Col>
        </FormGroup>
      }
      </div>
    )
  }
}

export default InputImage;
