/*

FUNCTIONS

ARCHIVO CON LAS FUNCIONES COMUNES A VARIOS COMPONENTES

ULTIMAS MODFIFICACIONES:

  - 2021-07-27 > CREADO
  - 2021-07-31 > AÑADIDO DUPLICAR
  - 2021-07-31 > AÑADIDO redirectDuplicateRegister
  - 2021-11-20 > AÑADIDO handleChangeOnNumber Y handleKeyDownOnNumber
  - 2021-12-15 > AÑADIDO loadingCompleted
  - 2021-12-15 > AÑADIDO history.go AL DUPLICAR PARA FORZAR RECARGA
  - 2022-03-25 > AÑADIDO A initialState INICIALIZACION ESTADO VARIABLES Hidden Y ReadOnly DE CADA CAMPO
  - 2022-03-25 > AÑADIDO QUE EXCLUYA VARIABLES Hidden Y ReadOnly DE LOS CAMPOS EN excludeDataFetch
  - 2022-03-27 > AÑADIDAS QUE INCLUYA VARIABLES Hidden Y ReadOnly DE LOS CAMPOS EN excludeDataFetch
  - 2022-03-29 > AÑADIDO A initialState INICIALIZACION DE LA VARIABLE DE TIPO controlledParent AL VALOR idParent
  - 2022-03-30 > AÑADIDA FUNCION redirect 
  - 2022-05-30 > AÑADIDA FUNCION handleKeyDownOnEmail
  - 2022-10-26 > RESETS action TO update AFTER A DELETING OF IMAGE OR FILE
  - 2024-03-13 > AÑADIDA FUNCION handleChangeOnMessage
  - 2024-04-07 > AÑADIDA FUNCION loadingStarted

FUNCIONES:

  - modalToggle > FUNCION QUE ABRE O CIERRA LA VENTANA MODAL DE CONFIRMACIÓN
  - modalCancel > FUNCION EJECUTADA AL CANCELAR EN LA VENTANA MODAL
  - modalConfirm > FUNCION EJECUTADA AL CONFIRMAR EN LA VENTANA MODAL Y LLAMA A deleteRegister O A duplicateRegister

  - handleFileChange > FUNCION QUE MANEJA EL CAMBIO EN UN ARCHIVO ASOCIADO
  - handleFileDelete > FUNCION QUE ABRE LA MODAL PARA CONFIRMAR LA ELIMINACIÓN DE UN ARCHIVO ASOCIADO
  - handleImageChange > FUNCION QUE MANEJA EL CAMBIO EN UNA IMAGEN ASOCIADA
  - handleImageFileChange > FUNCION QUE MANEJA EL CAMBIO EN UNA IMAGEN TIPO ARCHIVO ASOCIADA
  - handleImageDelete > FUNCION QUE ABRE LA MODAL PARA CONFIRMAR LA ELIMINACIÓN DE UNA IMAGEN ASOCIADA
  - handleImageFileDelete > FUNCION QUE ABRE LA MODAL PARA CONFIRMAR LA ELIMINACIÓN DE UNA IMAGEN TIPO ARCHIVO ASOCIADA
  - handleDelete > FUNCION QUE ABRE LA MODAL PARA CONFIRMAR LA ELIMINACIÓN DEL REGISTRO
  - handleDuplicate > FUNCION QUE ABRE LA MODAL PARA CONFIRMAR LA DUPLICACIÓN DEL REGISTRO
  - handleSendEmail > FUNCION QUE ABRE LA MODAL PARA CONFIRMAR EL ENVIO POR EMAIL DEL REGISTRO

  - deleteRegister > FUNCION QUE ELIMINA EL REGISTRO, UNA IMAGEN O UN ARCHIVO ASOCIADO
  - duplicateRegister > FUNCION QUE DUPLICA EL REGISTRO
  - sendRegister > FUNCION QUE ENVIA POR MAIL EL REGISTRO
  - redirect > FUNCION QUE REDIRIGE A routeRedirect ENVIADA DESDE LA API
  - redirectDuplicateRegister > FUNCION QUE REDIRIGE AL REGISTRO DUPLICADO
  - redirectSendRegister > FUNCION QUE REDIRIGE A routeList TRAS ENVIAR EMAIL
  - continueRegister > FUNCION QUE HACE UN FETCHAPI TRAS PEDIR CONFIRMACION

  - loadingCompleted > FUNCION QUE PONE LAS VARIABLES DE LOADING A FALSE
  - loadingStarted > FUNCION QUE PONE LAS VARIABLES DE LOADING A TRUE
  - updateForm > FUNCION A EJECUTAR CUANDO SE ELIMINAN LINEAS DE UN SUBLISTADO
  - initialState > FUNCION QUE RECORRE LOS CAMPOS PARA ASIGNAR AL ESTADO LOS VALORES INICIALES
  - handleValidSubmit > FUNCION TANTO PARA GUARDAR LA EDICIÓN COMO PARA EL ALTA DE UN REGISTRO CUANDO !autoSave

  // ****************HANDLERS PARA CAMBIOS EN CAMPOS**************
  - handleChange > FUNCION QUE ACTUALIZA EL ESTADO DEL CAMPO TEXT MODIFICADO Y LLAMA A fetchApi
  - handleKeyDownOnNumber > FUNCION QUE SUSTITUYE LA ',' POR '.' EN CAMPOS TIPO NUMBER
  - handleKeyDownOnEmail > FUNCION QUE EVITA EL ' ' EN CAMPOS TIPO EMAIL
  - handleChangeOnNumber > FUNCION QUE ACTUALIZA EL ESTADO DEL CAMPO NUMBER MODIFICADO Y LLAMA A fetchApi
  - handleChangeOnSelect > FUNCION QUE ACTUALIZA EL ESTADO DEL CAMPO SELECT MODIFICADO Y LLAMA A fetchApi
  - handleChangeOnEditor > FUNCION QUE CARGA EL CONTENIDO MODIFICADO DE UN CAMPO TIPO editor A LA VARIABLE DE ESTADO
  - handleChangeOnCheckbox > FUNCION QUE ACTUALIZA EL ESTADO DEL CAMPO CHECKBOX MODIFICADO Y LLAMA A fetchApi
  - handleChangeOnSwitch > FUNCION QUE ACTUALIZA EL ESTADO DEL CAMPO SWITCH MODIFICADO Y LLAMA A fetchApi
  - handleChangeOnEmail > FUNCION QUE ACTUALIZA EL ESTADO DEL CAMPO EMAIL MODIFICADO Y LLAMA A fetchApi
  - handleChangeOnMessage > FUNCION QUE ACTUALIZA EL ESTADO DEL CAMPO MESSAGE MODIFICADO

  // ***************HANDLERS PARA LLAMADAS A API EN CAMBIOS******************
  - handleErrors > FUNCION PARA GESTIONAR LOS ERRORES DEVUELTOS DESDE LA API
  - showToastr > FUNCION PARA MOSTRAR UN TOASTR CON UN MENSAJE (DE MOMENTO SOLO ERRORES)
  - validateEmail > FUNCION QUE VALIDA EL FORMATO DE UN CAMPO DE EMAIL
  - validateFields > FUNCION QUE VALIDA LOS CAMPOS DEL FORMULARIO, DE MOMENTO SOLO LOS OBRIGATORIOS
  - excludeDataFetch > FUNCION QUE DEVUELVE UN OBJETO CON LOS DATOS DEL ESTADO EXCLUYENDO LAS VARIABLES EN dataExcludeParentComponent, dataExcludeComponent, LAS options_ DE SELECTS Y LOS xxxError DE LAS VALIDACIONES
  - fetchApi > FUNCION QUE PASA A LA API EL ESTADO, LLAMANDO AL ENDPOINT DE LA API QUE SE PASA COMO PARAMETRO Y AL RECIBIR LOS RESULTADOS MODIFICA EL ESTADO

*/

import { toastr } from "react-redux-toastr";

import { useCallback } from "react";
// import _ from 'lodash';
import debounce from "lodash/debounce";

//FUNCION QUE ABRE O CIERRA LA VENTANA MODAL DE CONFIRMACIÓN
export function modalToggle() {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
};

//FUNCION EJECUTADA AL CANCELAR EN LA VENTANA MODAL
export function modalCancel() {

    this.modalToggle();
    if (this.state.id == "0") {
        this.setState({ action: "create" })
    } else {
        this.setState({ action: "update" })
    }
    this.setState({ modalType: "" });
    this.setState({ modalTitle: "" });
    this.setState({ modalText: "" });
    this.setState({ deleteType: "" });
    this.setState({ selectedId: "" });

    // SI VENIMOS DE DUPLICAR UNA FILA Y NO REDIRIGIMOS ACTUALIZAMOS EL LISTADO
    if ((this.state.action === "redirect_duplicate") && (this.state.rows)) {      
        this.componentDidMount();
    }     
};

//FUNCION EJECUTADA AL CONFIRMAR EN LA VENTANA MODAL
export function modalConfirm() {

    this.modalToggle();
    this.setState({ isLoadingModal: true });
    
    switch(this.state.action) {

        case "delete" : 
            this.deleteRegister();
            break;

        case "duplicate" :
            this.duplicateRegister();
            break;
        
        case "send" :
            this.sendRegister();
            break;
        
        case "redirect_send" :
            this.redirectSendRegister();
            break;

        case "redirect_duplicate" :
            this.redirectDuplicateRegister();
            break;

        case "redirect" :
            this.redirect();
            break;

        case "continue" :
            this.continueRegister();
            break;
    
        // case "accept" :
        //     this.acceptRegister();
        //     break;
    
        default:

    } 

    // ESTO LO HACEMOS AL FINAL DE CADA PROCESO
    // this.setState({ isLoadingModal: false });
};

//FUNCION QUE ACEPTA UNA ADVERTENCIA
export function modalAccept() { 

    this.modalToggle();

    this.setState({ modalType: "" });
    this.setState({ modalTitle: "" });
    this.setState({ modalText: "" });

    this.setState({ isLoadingModal: false });
}


//FUNCION QUE MANEJA EL CAMBIO EN UN ARCHIVO ASOCIADO
export function handleFileChange(e) {
    
    const target = e.target;
    const files = target.files;
    const id=target.id;
    //alert (id);
    if (files.length) {
        this.setState({ [`archivo${id}`]: files[0] });
        this.setState({ [`route${id}`]: files[0].name });
    }
}

//FUNCION QUE ABRE LA MODAL PARA CONFIRMAR LA ELIMINACIÓN DE UN ARCHIVO ASOCIADO
export function handleFileDelete(e) {

    e.preventDefault();
    const target = e.target;
    this.setState({ action: "delete" });
    this.setState({ deleteType: "file" });
    this.setState({ selectedId: target.id });
    this.setState({ modalType: "confirmModal" });
    this.setState({ modalTitle: "Confirmación" });
    this.setState({ modalText: "¿Está seguro de que desea eliminar el archivo?" });

    //ABRIMOS VENTANA MODAL Y PEDIMOS CONFIRMACIÓN
    this.setState({ modalIsOpen: true });
}

//FUNCION QUE MANEJA EL CAMBIO EN UNA IMAGEN ASOCIADA
export function handleImageChange(e) {

    const target = e.target;
    const files = target.files;
    const id = target.id;
    if (files.length) {
        this.setState({ [`imagen${id}`]: files[0] });
        this.setState({ [`routeImage${id}`]: files[0].name })
    }
}

//FUNCION QUE MANEJA EL CAMBIO EN UNA IMAGEN TIPO ARCHIVO ASOCIADA
export function handleImageFileChange(e) {

    const target = e.target;
    const files = target.files;
    const id = target.id;
    // alert(target.id);

    if (files.length) {

        this.setState({ [`routeImage${id}`]: files[0].name })
        this.setState({ [`imagen${id}`]: files[0] }, () => {
            
            // SI ES AUTOGUARDADO GUARDAMOS INMEDIATAMENTE LA IMAGEN
            if (this.props.autoSave) {
            
            let sentData = new FormData();
            
            let state = JSON.stringify(this.excludeDataFetch());
            sentData.append( "state", state );
            // console.log(state);
            
            /*********AÑADIMOS LAS IMAGENES AL JSON **************/
            // alert(this.state[`imagen${id}`]);
            sentData.append( `imagen${id}`, this.state[`imagen${id}`]);
            //console.log(i + ") " + `imagen${i}` + ": " + this.state[`imagen${i}`]);
        
            //console.log(...state);

            /////// HABRA QUE COMPROBAR AQUI EN UN FUTURO SI ES UN ALTA O NO /////////
            // if (this.props.idValue==="0") { //*****ES UN ALTA*****
            // console.log(`${this.props.routeApiControl}?modified_field=imagen${id}`);
            fetch(`${this.props.routeApiControl}?modified_field=imagen${id}` , {
                method: "POST",
                body: sentData,
                headers: { 
                "Token": `${this.state.token}`,
                // "Content-Type": "application/json",
                "Accept": "application/json, text-plain, */*"
                }
            })
            //.then(response => response.json())
            .then(receivedData => {
                return receivedData.json();    
            })
            .then(receivedData => {
        
                // this.setState({receivedData: receivedData});
                // console.log(receivedData);
                
                if (receivedData.length>0) {
        
                receivedData.map((data) => { 
                    // alert(data[`routeImage${id}`]);
                    // alert(data[`routeThumbnail${id}`]);
                    this.setState({ [`availableImage${id}`]: "1" });
                    this.setState({ [`routeImage${id}`]: data[`routeImage${id}`] });
                    this.setState({ [`imagen${id}`]: data[`routeImage${id}`] });
                    this.setState({ [`routeThumbnail${id}`]: data[`routeImage${id}`] });

                })  
                }
        
            })
            .catch(error => {
                alert("Error en el envío del formulario: " + error);
                // console.log(error);
                this.setState({ isLoadingModal: false });
            });
        
            } else {
                // SI NO HAY autoSave MOSTRAMOS LA IMAGEN SELECCIONADA EN LA MINIATURA HASTA QUE SE GUARDE
                // alert(URL.createObjectURL(files[0]));
                this.setState({ [`availableImage${id}`]: "1" });
                this.setState({ [`routeThumbnail${id}`]: URL.createObjectURL(files[0]) })
            }

        });
    }
}
  

//FUNCION QUE ABRE LA MODAL PARA CONFIRMAR LA ELIMINACIÓN DE UNA IMAGEN ASOCIADA
export function handleImageDelete(e) {

    e.preventDefault();
    const target = e.target;
    this.setState({ action: "delete" });
    this.setState({ deleteType: "image" });
    this.setState({ selectedId: target.id });
    this.setState({ modalType: "confirmModal" });
    this.setState({ modalTitle: "Confirmación" });
    this.setState({ modalText: "¿Está seguro de que desea eliminar la imagen?" });

    //ABRIMOS VENTANA MODAL Y PEDIMOS CONFIRMACIÓN
    this.setState({ modalIsOpen: true });
  }

//FUNCION QUE ABRE LA MODAL PARA CONFIRMAR LA ELIMINACIÓN DE UNA IMAGEN TIPO ARCHIVO ASOCIADA
export function handleImageFileDelete(e) {

    e.preventDefault();
    const target = e.target;
    // alert(target.id);
    this.setState({ action: "delete" });
    this.setState({ deleteType: "imageFile" });
    this.setState({ selectedId: target.id });
    this.setState({ modalType: "confirmModal" });
    this.setState({ modalTitle: "Confirmación" });
    this.setState({ modalText: "¿Está seguro de que desea eliminar la imagen?" });

    //ABRIMOS VENTANA MODAL Y PEDIMOS CONFIRMACIÓN
    this.setState({ modalIsOpen: true });
}
  
//FUNCION QUE ABRE LA MODAL PARA CONFIRMAR LA ELIMINACIÓN DEL REGISTRO
export function handleDelete(e) {

    e.preventDefault();
    const target = e.target;
    this.setState({ action: "delete" });
    this.setState({ deleteType: "register" });
    this.setState({ modalType: "confirmModal" });
    this.setState({ modalTitle: "Confirmación" });
    this.setState({ modalText: "¿Está seguro de que desea eliminar el registro?" });

    //ABRIMOS VENTANA MODAL Y PEDIMOS CONFIRMACIÓN
    this.setState({ modalIsOpen: true });
}

//FUNCION QUE ABRE LA MODAL PARA CONFIRMAR LA DUPLICACIÓN DEL REGISTRO
export function handleDuplicate(e) {

    e.preventDefault();
    const target = e.target;
    this.setState({ action: "duplicate" });
    this.setState({ modalType: "confirmModal" });
    this.setState({ modalTitle: "Confirmación" });
    this.setState({ modalText: "¿Está seguro de que desea duplicar el registro?" });

    //ABRIMOS VENTANA MODAL Y PEDIMOS CONFIRMACIÓN
    this.setState({ modalIsOpen: true });
}

//FUNCION QUE ABRE LA MODAL PARA CONFIRMAR EL ENVIO POR EMAIL DEL REGISTRO
export function handleSendEmail(e) {

    e.preventDefault();
    // *** DA ERROR EXPORADICAMENTE *** const target = e.target;
    const target = e.currentTarget;

    // VALIDAMOS QUE CONTENGA UN EMAIL
    let error = false;

    // alert(target.id);

    if (((this.state[`${target.id}`] == "") || (this.state[`${target.id}`] == undefined)) && (!this.state[`${target.id}Hidden`])) {
        this.setState({ [`${target.id}Error`] : "Este campo es obligatorio" });
        this.showToastr("Error", "Por favor, rellene los campos obligatorios del formulario.");
        error = true;
    } else {

        // VALIDAMOS QUE EL EMAIL TENGA UN FORMATO CORRECTO
        var emailValido = this.validateEmail(this.state[`${target.id}`]);
        // if (!this.validateEmail(this.state[`${field.name}`])) {
        if (!emailValido) {

            this.setState({ [`${target.id}Error`] : "El email no tiene un formato correcto." });
            this.showToastr("Error", "El email no tiene un formato correcto.");
            error = true;

        } else {

            this.setState({ [`${target.id}Error`] : "" });
            // alert("NO ERROR");
        }
    }

    if (!error) {

        this.setState({ action: "send" });
        this.setState({ modalType: "confirmModal" });
        this.setState({ modalTitle: "Confirmación" });
        this.setState({ modalText: "¿Está seguro de que desea enviar?" });

        //ABRIMOS VENTANA MODAL Y PEDIMOS CONFIRMACIÓN
        this.setState({ modalIsOpen: true });
    }
}


//FUNCION QUE ELIMINA EL REGISTRO, UNA IMAGEN O UN ARCHIVO ASOCIADO
export async function deleteRegister() {

    this.setState({ isLoadingModal: true });

    let sentData = new FormData();
    
    switch (this.state.deleteType) {

        case "file" : 

            /*********AÑADIMOS LOS DATOS AL JSON ***************/
            sentData.append(`id`, this.state.id);
            sentData.append(`file_number`, this.state.selectedId);
            //sentData.append(`url`, url_arhivo);
            sentData.append(`tablename`, `${this.props.tablename}`);

            fetch(`${this.props.routeApiDeleteFile}` , {
            method: "POST",
            body: sentData,
            headers: { 
                "Token": `${this.state.token}`,
                // "Content-Type": "application/json",
                "Accept": "application/json, text-plain, */*"
            }
            })
            //.then(response => response.json())
            .then(response => {
                // console.log(response)
                if (response.ok) { 
                    this.setState({[`title${this.state.selectedId}`] : ""});
                    this.setState({[`url${this.state.selectedId}`] : ""});
                    this.setState({[`route${this.state.selectedId}`] : ""});
                    this.setState({ modalType: "" });
                    this.setState({ modalTitle: "" });
                    this.setState({ modalText: "" });
                    this.setState({ deleteType: "" });
                    this.setState({ selectedId: "" });
                    this.setState({ action: "update" });        
                } else {
                    alert("Ha ocurrido un error en la eliminación del archivo.");
                }
            })
            .catch(error => {
            alert("Error en el envío del formulario: " + error);
            // console.log(error);
            })

            break;

        case "image" :

            /*********AÑADIMOS LOS DATOS AL JSON ***************/
            sentData.append(`id`, this.state.id);
            sentData.append(`image_number`, this.state.selectedId);
            //sentData.append(`url`, url_arhivo);
            sentData.append(`tablename`, `${this.props.tablename}`);

            fetch(`${this.props.routeApiDeleteImage}` , {
            method: "POST",
            body: sentData,
            headers: { 
                "Token": `${this.state.token}`,
                // "Content-Type": "application/json",
                "Accept": "application/json, text-plain, */*"
            }
            })
            //.then(response => response.json())
            .then(response => {
                // console.log(response);
                if (response.ok) { 
                    this.setState({ [`availableImage${this.state.selectedId}`] : "" });
                    this.setState({ [`imagen${this.state.selectedId}`] : "" });
                    this.setState({ [`routeImage${this.state.selectedId}`] : "" });
                    this.setState({ [`routeThumbnail${this.state.selectedId}`] : `${process.env.REACT_APP_API_DOMAIN}/${process.env.REACT_APP_NON_AVAILABLE_IMAGE}`});
                    this.setState({ modalType: "" });
                    this.setState({ modalTitle: "" });
                    this.setState({ modalText: "" });
                    this.setState({ deleteType: "" });
                    this.setState({ selectedId: "" });  
                    this.setState({ action: "update" });      
                } else {
                    alert("Ha ocurrido un error en la eliminación de la imagen.");
                }
            })
            .catch(error => {
            alert("Error en el envío del formulario: " + error);
            // console.log(error);
            })

            break;

        case "imageFile" :

            /*********AÑADIMOS LOS DATOS AL JSON ***************/
            sentData.append(`id`, this.state.id);
            sentData.append(`image_number`, this.state.selectedId);
            //sentData.append(`url`, url_arhivo);
            sentData.append(`tablename`, `${this.props.tablename}`);
    
            fetch(`${this.props.routeApiDeleteImageFile}` , {
                method: "POST",
                body: sentData,
                headers: { 
                "Token": `${this.state.token}`,
                // "Content-Type": "application/json",
                "Accept": "application/json, text-plain, */*"
                }
            })
            //.then(response => response.json())
            .then(response => {
                // console.log(response);
                if (response.ok) { 
                    this.setState({ [`availableImage${this.state.selectedId}`] : "" });
                    this.setState({ [`imagen${this.state.selectedId}`] : "" });
                    this.setState({ [`routeImage${this.state.selectedId}`] : "" });
                    this.setState({ [`routeThumbnail${this.state.selectedId}`] : `${process.env.REACT_APP_API_DOMAIN}/${process.env.REACT_APP_NON_AVAILABLE_IMAGE}`});
                    ///// AQUI FALTARIA COMPROBAR SI EL id ES 0 O NO PARA PONER LA ACCION CORRESPONDIENTE ////
                    this.setState({ action: "update" });
                    this.setState({ modalType: "" });
                    this.setState({ modalTitle: "" });
                    this.setState({ modalText: "" });
                    this.setState({ deleteType: "" });
                    this.setState({ selectedId: "" });
                    this.setState({ action: "update" });   
                } else {
                    alert("Ha ocurrido un error en la eliminación de la imagen.");
                }
            })
            .catch(error => {
                alert("Error en el envío del formulario: " + error);
                // console.log(error);
            })
    
            break;
    
        case "register" :

            // this.setState({ isLoadingModal: true });

            await this.fetchApi(`${this.props.routeApiActions}`);
            
            if (!this.state.errorForm) {
            //     this.showToastr("Error", this.state.errorForm);
            // } else {
                //OJO: DE MOMENTO FUNCIONA, AUNQUE PODRÍA DAR PROBLEMAS SI HACE EL history.push ANTES DE QUE SE COMPLETARA EL fetchApi. EL VSCODE ADVIERTE QUE EL await EN EL fetchApi NO TIENE UTILIDAD
                this.props.history.push(`${this.props.routeList}`);
            }

            break;
        
        default:

    } 

    this.setState({ isLoadingModal: false });
}

//FUNCION QUE DUPLICA EL REGISTRO
export async function duplicateRegister() {

    await this.validateFields();
    
    if (!this.state.errorValidation) {

        this.setState({ isLoadingModal: true });

        await this.fetchApi(`${this.props.routeApiActions}`);

        // if (!this.state.errorForm) {

        //     this.props.history.push(`${this.props.routeList}${this.state.insert_id}`);
        //     // this.setState({isLoading: true});
        //     // this.setState({isLoadingModal: true});
        //     this.componentDidMount();
        // }
    }
}

//FUNCION QUE ENVIA POR MAIL EL REGISTRO
export function sendRegister() { 
    this.setState({ isLoadingModal: true }, () => {
        this.fetchApi(`${this.props.routeApiActions}`);
    });
}

//FUNCION QUE REDIRIGE A routeRedirect ENVIADA DESDE LA API
export function redirect() {

    this.setState({ action: "" });
    this.setState({ modalType: "" });
    this.setState({ modalTitle: "" });
    this.setState({ modalText: "" });

    this.props.history.push(`${this.state.routeRedirect}`);
}

//FUNCION QUE REDIRIGE AL REGISTRO DUPLICADO
export function redirectDuplicateRegister() {

    this.setState({ action: "" });
    this.setState({ modalType: "" });
    this.setState({ modalTitle: "" });
    this.setState({ modalText: "" });

    this.props.history.push(`${this.props.routeList}${this.state.insert_id}`);
    this.props.history.go();
    
    // YA NO HACE FALTA POR EL history.go();
    // // SI ES UN FORMULARIO FORZAMOS SU ACTUALIZACION
    // if (!this.state.rows) {
    //     this.componentDidMount();
    // }
}
  
//FUNCION QUE REDIRIGE A routeList TRAS ENVIAR EMAIL
export function redirectSendRegister() {

    this.setState({ action: "" });
    this.setState({ modalType: "" });
    this.setState({ modalTitle: "" });
    this.setState({ modalText: "" });

    this.props.history.push(`${this.props.routeList}`);
    // this.props.history.go();
}

//FUNCION QUE HACE UN FETCHAPI TRAS PEDIR CONFIRMACION
export function continueRegister() { 

    this.setState({ modalType: "" });
    this.setState({ modalTitle: "" });
    this.setState({ modalText: "" });

    this.setState({ isLoadingModal: true }, () => {
        this.fetchApi(`${this.props.routeApiActions}`);
    });
}


// FUNCION QUE PONE LAS VARIABLES DE LOADING A FALSE
export async function loadingCompleted() {

    this.setState({isLoading: false});
    this.setState({isLoadingModal: false});
}

// FUNCION QUE PONE LAS VARIABLES DE LOADING A TRUE
export async function loadingStarted() {

    this.setState({isLoading: true});
    this.setState({isLoadingModal: true});
}

// FUNCION QUE ACTUALIZA EL FORMULARIO LLAMANDO A componentDidMount
export async function updateForm() {
    // alert("ACTUALIZO FORMULARIO");
    this.componentDidMount();
}

// FUNCION QUE RECORRE LOS CAMPOS PARA ASIGNAR AL ESTADO LOS VALORES INICIALES
export function initialState () {

    // INICIALIZAMOS LOS VALORES DE HIDDEN Y READONLY DE LOS CAMPOS EN EL ESTADO
    this.props.fields.map((field, index) => {      
        // alert(`${field.name}Hidden` + ": " + field.hidden);
        this.setState({ [`${field.name}Hidden`] : field.hidden });
        this.setState({ [`${field.name}ReadOnly`] : field.readOnly });
    });

    // INICIALIZAMOS EL VALOR DEL CAMPO QUE CORRESPONDE AL idParent EN EL ESTADO
    if (this.props.idParent) {
        
        const idParent = this.props.idParent;
        this.props.fields.filter((field) => (field.type === "controlledParent")).map((field, index) => {      
            // alert(`${field.name}` + ": " + idParent);
            this.setState({ [`${field.name}`] : idParent });
        });
    }

    // INICIALIZAMOS LOS VALORES POR DEFECTO DE LOS CAMPOS EN EL ESTADO
    this.props.fields.filter((field) => (field.defaultValue)).map((field, index) => {      
        // alert(field.name + ": " + field.defaultValue);
        this.setState({ [`${field.name}`] : field.defaultValue });
    });
}


//FUNCION TANTO PARA GUARDAR LA EDICIÓN COMO PARA EL ALTA DE UN REGISTRO CUANDO !autoSave
export async function handleValidSubmit(event, values) {

    event.preventDefault();

    await this.validateFields();
    
    if (!this.state.errorValidation) {

        this.setState({ isLoadingModal: true });

        await this.fetchApi(`${this.props.routeApiActions}`);
        
        if (!this.state["errorForm"]) {

            // SI ES EL FORMULARIO DE SIGNIN GUARDAMOS EL TOKEN EN LOCAL O SESION
            if (this.state.isSignInForm) {

                if (this.state.recordar) {

                    // alert("LOCAL!");
                    localStorage.clear();
                    localStorage.setItem("token", this.state.token);

                  } else {
          
                    // alert("SESSION!");
                    sessionStorage.clear();
                    sessionStorage.setItem("token", this.state.token);
          
                  }

                  this.props.history.push(`${this.props.routeList}`);
                  window.location.reload();

            } else {

                // SI ES FORMULARIO LOGIN SE PREGUNTA PARA REDIRIGIR
                if ((!this.state.isLoginForm) && (!this.state.skipRedirectSubmit)) {
                    //OJO: DE MOMENTO FUNCIONA, AUNQUE PODRÍA DAR PROBLEMAS SI HACE EL history.push ANTES DE QUE SE COMPLETARA EL fetchApi. EL VSCODE ADVIERTE QUE EL await EN EL fetchApi NO TIENE UTILIDAD
                    this.props.history.push(`${this.props.routeList}`);
                }
            }
        }
        // } else {
        //     this.handleErrors();
        // }
    }
}

//FUNCION QUE ACTUALIZA EL ESTADO DEL CAMPO TEXT MODIFICADO Y LLAMA A fetchApi
// async handleChange(event) { 
export function handleChange(event) { 

    event.preventDefault();

    const fieldname = event.target.name;
    const field = this.props.fields.find(field => field.name == fieldname);
    const fieldFetchApi = field.fetchApi;

    // SI TENIA UN ERROR ACTIVO LO DESACTIVAMOS
    if ((this.state[`${fieldname}Error`] != "" ) && (event.target.value)) {
        this.setState({ [`${fieldname}Error`] : "" });
    }

    this.setState({[`${event.target.name}`]: event.target.value}, () => {
        if ((fieldFetchApi) || (this.state.autoSave)) {
            
            // this.fetchApi(`${this.props.routeApiControl}?modified_field=${fieldname}`);
            this.debouncedFetchApi(`${this.props.routeApiControl}?modified_field=${fieldname}`);
        }
    })
}

// FUNCION QUE SUSTITUYE LA ',' POR '.' EN CAMPOS TIPO NUMBER
export function handleKeyDownOnNumber(event) {

    // ESTA FUNCION ERA LA BUENA PARA SUSTITUIR POR "." HASTA QUE DESACTIVE QUE SE PUDIERA UTILIZAR LA TECLA DE ",". PARA ACTIVARLA QUITAR DOBLES COMENTARIOS DENTRO DEL IF
    // alert("keyDown!");
    if ((event.key === ",") && !(event.target.value.includes("."))) {
        // // event.target.type = 'text';
        // // get old value
        // var start = event.target.selectionStart;
        // var end = event.target.selectionEnd;
        // var oldValue = event.target.value;
        
        // // replace point and change input value
        // var newValue = oldValue.slice(0, start) + "." + oldValue.slice(end);
        // event.target.value = newValue;
        
        // // replace cursor
        // event.target.selectionStart = event.target.selectionEnd = start + 1;
        
        event.preventDefault();
    }
}

// FUNCION QUE SUSTITUYE LA ',' POR '.' EN CAMPOS TIPO NUMBER
export function handleKeyDownOnEmail(event) {

    // alert("|" + event.key + "|");
    if (event.key === " ") {
        event.preventDefault();
    }
}


//FUNCION QUE ACTUALIZA EL ESTADO DEL CAMPO NUMBER MODIFICADO Y LLAMA A fetchApi
// async handleChange(event) { 
export function handleChangeOnNumber(event) { 

    // alert("handleChange!");

    event.preventDefault();

    const fieldname = event.target.name;
    const field = this.props.fields.find(field => field.name == fieldname);
    const fieldFetchApi = field.fetchApi;

    // let { name, value } = event.target;
    // alert(value);
    // value = value.replace(/\./g,',');
    // event.target.value = value;
    // // setData(prevState => ({ ...prevState, [name]: value}));
    // // this.setState(prevState => ({ ...prevState, [name]: value}));
    // // alert(value);


    // const numberWithDecimalSeparator = 1.1;
    // const decimalSeparator = Intl.NumberFormat()
    //     .formatToParts(numberWithDecimalSeparator)
    //     .find(part => part.type === 'decimal')
    //     .value;

    // // alert(event.target.value);
    // var valueString = event.target.value;
    // // valueString.replace(".", ',');
    // valueString = valueString.toFixed(2).toString().replace(".", ",");
    // alert(valueString);

    // SI TENIA UN ERROR ACTIVO LO DESACTIVAMOS
    if ((this.state[`${fieldname}Error`] != "" ) && (event.target.value)) {
        this.setState({ [`${fieldname}Error`] : "" });
    }

    if (event.target.validity.valid) {
        
        // var value = event.target.value.replace(/\./g,',');
        // // var value = this.state[`${event.target.name}`].replace(/\./g,',');
        // alert(value);
        // event.target.value = value
        this.setState({[`${event.target.name}`]: event.target.value}, () => {
        // this.setState({[`${event.target.name}`]: value}, () => {
        // this.setState({[`${event.target.name}`]: parseFloat(valueString)}, () => {
            if ((fieldFetchApi) || (this.state.autoSave)) {
                // this.fetchApi(`${this.props.routeApiControl}?modified_field=${fieldname}`);
                this.debouncedFetchApi(`${this.props.routeApiControl}?modified_field=${fieldname}`);
            }
        })

    } else {
        // alert("Inválido!");
    }

}

//FUNCION QUE ACTUALIZA EL ESTADO DEL CAMPO SELECT MODIFICADO Y LLAMA A fetchApi
export function handleChangeOnSelect(selectedOption, name) {     

    // event.preventDefault();

    const fieldname = name;
    const field = this.props.fields.find(field => field.name == fieldname);
    const fieldFetchApi = field.fetchApi;

    let value = "";
    if (selectedOption) { //CONTROLAMOS SI TIENE VALOR O SE HA DESELECCIONADO
      
        value = selectedOption.value;

        // SI TENIA UN ERROR ACTIVO LO DESACTIVAMOS
        if (this.state[`${fieldname}Error`] != "" ) {
            this.setState({ [`${fieldname}Error`] : "" });
        }
    }

    this.setState({[`${name}`]: value}, () => {
        this.setState({[`selected_${name}`]: selectedOption}, () => {
            if ((fieldFetchApi) || (this.state.autoSave)) {
                this.fetchApi(`${this.props.routeApiControl}?modified_field=${fieldname}`);
            }
        });
    });
}

//FUNCION QUE CARGA EL CONTENIDO MODIFICADO DE UN CAMPO TIPO editor A LA VARIABLE DE ESTADO
export function handleChangeOnEditor(content, editor) {

    const fieldname = editor.id;
    const field = this.props.fields.find(field => field.name == fieldname);
    const fieldFetchApi = field.fetchApi;

    this.setState({ [`${fieldname}`]: content }, () => {
        if ((fieldFetchApi) || (this.state.autoSave)) {
            // this.fetchApi(`${this.props.routeApiControl}?modified_field=${fieldname}`);
            this.debouncedFetchApi(`${this.props.routeApiControl}?modified_field=${fieldname}`);
        }
    });

    // AQUI PONEMOS LA DESACTIVACION DEL ERROR DESPUES PONQUE SI NO DA PROBLEMAS AL ESCRIBIR
    // SI TENIA UN ERROR ACTIVO LO DESACTIVAMOS
    if ((this.state[`${fieldname}Error`] != "" ) && (content)) {
        this.setState({ [`${fieldname}Error`] : "" });
    }
}
  

//FUNCION QUE ACTUALIZA EL ESTADO DEL CAMPO SWITCH MODIFICADO Y LLAMA A fetchApi
export function handleChangeOnSwitch(event) { 

    event.preventDefault();

    const fieldname = event.target.name;
    const field = this.props.fields.find(field => field.name == fieldname);
    const fieldFetchApi = field.fetchApi;

    // SI TENIA UN ERROR ACTIVO LO DESACTIVAMOS
    if ((this.state[`${fieldname}Error`] != "" ) && (event.target.checked)) {
        this.setState({ [`${fieldname}Error`] : "" });
    }
    
    if (event.target.checked) {
        this.setState({ [`${fieldname}`]: 1 }, () => {
            if ((fieldFetchApi) || (this.state.autoSave)) {
                this.fetchApi(`${this.props.routeApiControl}?modified_field=${fieldname}`);
            }
        })
    } else {
        this.setState({ [`${fieldname}`]: 0 }, () => {
            if ((fieldFetchApi) || (this.state.autoSave)) {
                this.fetchApi(`${this.props.routeApiControl}?modified_field=${fieldname}`);
            }
        }) 
    }   
}
  
//FUNCION QUE ACTUALIZA EL ESTADO DEL CAMPO CHECKBOX MODIFICADO Y LLAMA A fetchApi
export function handleChangeOnCheckbox(event) { 

    // event.preventDefault();

    const fieldname = event.target.name;
    const field = this.props.fields.find(field => field.name == fieldname);
    const fieldFetchApi = field.fetchApi;

    // SI TENIA UN ERROR ACTIVO LO DESACTIVAMOS
    if ((this.state[`${fieldname}Error`] != "" ) && (event.target.checked)) {
        this.setState({ [`${fieldname}Error`] : "" });
    }
    
    if (event.target.checked) {
        this.setState({ [`${fieldname}`]: true }, () => {
            if ((fieldFetchApi) || (this.state.autoSave)) {
                this.fetchApi(`${this.props.routeApiControl}?modified_field=${fieldname}`);
            }
        })
    } else {
        this.setState({ [`${fieldname}`]: false }, () => {
            if ((fieldFetchApi) || (this.state.autoSave)) {
                this.fetchApi(`${this.props.routeApiControl}?modified_field=${fieldname}`);
            }
        }) 
    }   
}

//FUNCION QUE ACTUALIZA EL ESTADO DEL CAMPO EMAIL MODIFICADO Y LLAMA A fetchApi
export function handleChangeOnEmail(event) { 

    event.preventDefault();

    const fieldname = event.target.name;
    const field = this.props.fields.find(field => field.name == fieldname);
    const fieldFetchApi = field.fetchApi;

    // SI TENIA UN ERROR ACTIVO LO DESACTIVAMOS
    if ((this.state[`${fieldname}Error`] != "" ) && (event.target.value)) {
        this.setState({ [`${fieldname}Error`] : "" });
    }

    this.setState({[`${event.target.name}`]: event.target.value}, () => {
        if ((fieldFetchApi) || (this.state.autoSave)) {
            // this.fetchApi(`${this.props.routeApiControl}?modified_field=${fieldname}`);
            this.debouncedFetchApi(`${this.props.routeApiControl}?modified_field=${fieldname}`);
        }
    })
}

//FUNCION QUE ACTUALIZA EL ESTADO DEL CAMPO MESSAGE MODIFICADO 
export function handleChangeOnMessage(event) { 

    event.preventDefault();

    const fieldname = event.target.name;
    const field = this.props.fields.find(field => field.name == fieldname);
    const fieldFetchApi = field.fetchApi;

    // SI TENIA UN ERROR ACTIVO LO DESACTIVAMOS
    if ((this.state[`${fieldname}Error`] != "" ) && (event.target.value)) {
        this.setState({ [`${fieldname}Error`] : "" });
    }

    this.setState({[`${event.target.name}`]: event.target.value}, () => {
        if ((fieldFetchApi) || (this.state.autoSave)) {
            // this.fetchApi(`${this.props.routeApiControl}?modified_field=${fieldname}`);
            this.debouncedFetchApi(`${this.props.routeApiControl}?modified_field=${fieldname}`);
        }
    })
}

// FUNCION PARA GESTIONAR LOS ERRORES DEVUELTOS DESDE LA API
export function handleErrors() {

    // SI HAY UN ERROR GENERAL POR VALIDACION DE TOKEN LO ELIMINAMOS Y VOLVEMOS A CARGAR APP
    if (this.state.errorGeneral) {

        localStorage.clear();
        sessionStorage.clear();
        this.props.history.push("/");
        window.location.reload();

    } else {
        // LO METEMOS EN EL ELSE PARA QUE NO LO MUESTRE SI HAY QUE RECARGAR APP POR ERROR GENERAL CON EL TOKEN
        // SI NO HAY AUTOSAVE NO HACE FALTA PORQUE YA SE GESTIONA DESDE EL fetchApi
        // if ((this.state.autoSave) && (this.state.errorForm)) {
        if (this.state.errorForm) {

            this.setState({isLoading: false});
            this.setState({isLoadingModal: false});  
            this.showToastr("Error", this.state.errorForm);
        }
    }
}

// FUNCION PARA MOSTRAR UN TOASTR CON UN MENSAJE (DE MOMENTO SOLO ERRORES)
// showToastr(title, message, tipoError) {
export function showToastr(title, message) {

    // const title = "Error";

    // alert(this.state.autosave);
    // EVITAMOS ABRIR EL TOASTR SI NO ES UN FORM CON AUTOSAVE
    if ((!this.state.autosave) || (!this.state.toastrIsOpen)) {
    // if ((!this.state.toastrIsOpen)) {

        const options = {
            timeOut: 5000,
            showCloseButton: true,
            progressBar: true,
            // position: "top-center"
            position: "bottom-right",
            // onclose: this.setState({ toastrIsOpen: false })
            onHideComplete: () => this.setState({ toastrIsOpen: false })
        };

        // PARA SACAR DIFERENTES TIPOS DE TOASTR
        // const toastrInstance = tipoError == 0 ? toastr.warning: toastr.error;
        // const toastrInstance =
        // this.state.type === "info"
        //   ? toastr.info
        //   : this.state.type === "warning"
        //   ? toastr.warning
        //   : this.state.type === "error"
        //   ? toastr.error
        //   : toastr.success;

        const toastrInstance = toastr.error;

        this.setState({ toastrIsOpen: true });

        toastrInstance(
            // this.state.title,
            // this.state.message || "Have fun storming the castle!",
            title,
            message,
            options
        );
        // toastrInstance.subscribe((...args) => {
        //   alert("EVENTO");
        //   console.log(args);
        // });
    }
}

// FUNCION QUE VALIDA EL FORMATO DE UN CAMPO DE EMAIL
export function validateEmail(email) {

    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if (!pattern.test(email)) {
        return false;
    } else {
        return true;
    }

}

// FUNCION QUE VALIDA LOS CAMPOS DEL FORMULARIO, DE MOMENTO SOLO LOS OBRIGATORIOS
export async function validateFields() {

    let error = false;

    // VALIDAMOS CAMPOS OBLIGATORIOS
    const requiredFields = this.props.fields.filter((field) => (field.required));

    if (requiredFields.length>0) {

        requiredFields.map((field,index) => {

            switch (field.type) {

            case "controlledRouteDynamicSelect":

                if (((this.state[`${field.name}`] == "") || (this.state[`${field.name}`] == undefined) || (this.state[`${field.name}`] == 0)) && (!this.state[`${field.name}Hidden`])) {
                    this.setState({ [`${field.name}Error`] : "Este campo es obligatorio" });
                    error = true;
                    // alert("ERROR EN" + `${field.name}`);
                } else {
                    this.setState({ [`${field.name}Error`] : "" });
                    // alert("NO ERROR");
                }

                break;

            case "controlledText":
            case "controlledPassword":
            case "controlledDynamicSelect":
            // case "controlledRouteDynamicSelect":
            case "controlledSelect":
            case "controlledEmail":
            case "editor":
            case "checkbox":
            case "controlledCheckbox":

                if (((this.state[`${field.name}`] == "") || (this.state[`${field.name}`] == undefined)) && (!this.state[`${field.name}Hidden`])) {
                    this.setState({ [`${field.name}Error`] : "Este campo es obligatorio" });
                    error = true;
                    // alert("ERROR EN" + `${field.name}`);
                } else {
                    this.setState({ [`${field.name}Error`] : "" });
                    // alert("NO ERROR");
                }

                break;

            case "controlledSwitch":

                if ((this.state[`${field.name}`] == "0")) {
                    // alert("ERROR EN" + `${field.name}`);
                    this.setState({ [`${field.name}Error`] : "Este campo es obligatorio" });
                    error = true;
                } else {
                    this.setState({ [`${field.name}Error`] : "" });
                }

                break;

            default: 

            } 
        })
    }

    // // VALIDAMOS CAMPOS EMAIL  ********** DE MOMENTO VALIDAMOS EN EL handleSendEmail *********
    // const emailFields = this.props.fields.filter((field) => (field.type === "controlledEmail"));

    // // alert(emailFields.length);
    // if (emailFields.length>0) {

    //   emailFields.map((field,index) => {

    //     var emailValido = this.validateEmail(this.state[`${field.name}`], emailValido);
    //     // if (!this.validateEmail(this.state[`${field.name}`])) {
    //     if (!emailValido) {
    //       alert("ERROR");
    //       this.setState({ [`${field.name}Error`] : "El email no tiene un formato correcto." });
    //       error = true;
    //     } else {
    //       this.setState({ [`${field.name}Error`] : "" });
    //     }

    //   })
    // }

    // SI HAY ERRORES EN LOS CAMPOS OBLIGATORIOS
    if (error) {
        await this.setState({ "errorValidation" : true });
        if (!this.props.autoSave) {
            await this.setState({ "errorForm" : "Por favor, rellene los campos obligatorios del formulario." });
        }
        this.showToastr("Error", "Por favor, rellene los campos obligatorios del formulario.");
    } else {
        await this.setState({ "errorValidation" : false });
        await this.setState({ "errorForm" : "" });
    }

}

// FUNCION QUE DEVUELVE UN OBJETO CON LOS DATOS DEL ESTADO EXCLUYENDO LAS VARIABLES EN dataExcludeParentComponent, dataExcludeComponent, LAS options_ DE SELECTS Y LOS xxxError DE LAS VALIDACIONES E INCLUYENDO LAS DE dataIncludeParentComponent, dataIncludeComponent
export function excludeDataFetch() {

    var dataFetch = {};

    const dataExclude = this.state.dataExcludeComponent.concat(this.state.dataExcludeParentComponent);
    const dataInclude = this.state.dataIncludeComponent.concat(this.state.dataIncludeParentComponent);
    // alert(dataInclude.indexOf("id_incrementoHidden"));
    // alert(this.state.dataIncludeParentComponent[0]);
    // const dataInclude = this.state.dataIncludeParentComponent;

    // console.log("dataExclude: " + dataExclude);
    for (var key in this.state) {
        // console.log("key: " + key);
        // DESCARTAMOS LAS VARIABLES DE ERRORES DE VALIDACION DE LOS CAMPOS (NO EL GENERAL), LAS OPCIONES DE LOS SELECTS, LOS READONLY Y HIDDEN A NO SER QUE ESTEN EN EL VECTOR A INCLUIR
        if ((((key.indexOf("Error") == -1) || (key.indexOf("Error") == 0)) && 
            (key.indexOf("options_") == -1) && 
            (key.indexOf("ReadOnly") == -1) && 
            (key.indexOf("Hidden") == -1) && 
            (dataExclude.indexOf(key) == -1)) || 
            (dataInclude.indexOf(key) > -1)) {

            // if ((dataExclude.indexOf(key) == -1) || (dataInclude.indexOf(key) > -1)) {
                dataFetch[key] = this.state[key];
            // }
        } 
    }
    return dataFetch;
}

//FUNCION QUE PASA A LA API EL ESTADO, LLAMANDO AL ENDPOINT DE LA API QUE SE PASA COMO PARAMETRO Y AL RECIBIR LOS RESULTADOS MODIFICA EL ESTADO
export async function fetchApi(routeApi) { 

    this.setState({ errorForm: "" });
    // this.setState({ errorForm: false });
    
    // SI NO ES AUTOSAVE HACEMOS LA VALIDACION EN EL handleValidSubmit
    if (this.props.autoSave) {
        await this.validateFields();
    }

    //alert("routeApi: " + routeApi);
    // console.log("routeApi: " + routeApi);
    // this.setState({ isLoadingModal: true });

    let sentData = new FormData();
        
    let state = JSON.stringify(this.excludeDataFetch());
    sentData.append( "state", state);

    // SI ES UN SUBMIT AÑADIMOS LOS ARCHIVOS E IMAGENES
    if (routeApi === this.props.routeApiActions) {

        /*********AÑADIMOS LOS ARCHIVOS AL JSON ***************/
        for (let i=1; i<=this.props.numberFiles; i++) {
            sentData.append(`archivo${i}`, this.state[`archivo${i}`]);
            //console.log(i + ") " + `archivo${i}` + ": " + this.state[`archivo${i}`]);
        }

        /*********AÑADIMOS LAS IMAGENES AL JSON **************/
        for (let i=1; i<=this.props.numberImages; i++) {
            sentData.append(`imagen${i}`, this.state[`imagen${i}`]);
            // console.log(i + ") " + `imagen${i}` + ": " + this.state[`imagen${i}`]);
        }

        /*********AÑADIMOS LAS IMAGENES TIPO ARCHIVO AL JSON **************/
        for (let i=1; i<=this.props.numberImageFiles; i++) {
            sentData.append(`imagen${i}`, this.state[`imagen${i}`]);
            //console.log(i + ") " + `imagen${i}` + ": " + this.state[`imagen${i}`]);
        }

    }

    // const sentData = JSON.stringify(this.state);
    ////const sentData = JSON.stringify(this.excludeDataFetch());
    // console.log(sentData);

    await fetch(routeApi , {
        method: "POST",
        body: sentData,
        headers: { 
        "Token": `${this.state.token}`,
        // "Content-Type": "application/json",
        "Accept": "application/json, text-plain, */*"
        }
    })
    //.then(response => response.json())
    .then(receivedData => {
        return receivedData.json();
    })
    .then(receivedData => {

        // console.log(receivedData);

        if (receivedData.length>0) {

        receivedData.map((data) => {  

            /**************ASIGNAMOS LOS VALORES DE LOS STATES DE LOS CAMPOS ***********/
            Object.entries(data).map(([key,value]) => { 

                // HACEMOS UNA RECONVERSION PASANDO A CADENA Y OTRA VEZ A OBJETO PARA ASEGURARNOS DE QUE COGE LA FORMA DE OBJETO, PUES DABA ERROR EN LOS JSON ANIDADOS COMO LOS DE options_rendimiento, etc....
                let obj = JSON.parse(JSON.stringify(value));
                this.setState({ [`${key}`] : obj });
                // this.setState({ [`${key}`] : obj.toLocaleString('de-DE') }); // NO FUNCIONA, HABRIA QUE APLICARLO SOLO A CAMPOS type NUMERICOS
                // console.log(`${key}:` + obj );

                // if (key == "descuento") {
                //     alert("DESCUENTO: " + obj);
                // }
            });

        })

        ////////////ESTO PODRIA SER UTIL SI EN UN FUTURO QUEREMOS ACTUALIZAR SOLO ALGUNO DE LOS SUBLISTADOS
        // const listFields = this.props.fields.filter((field) => field.type === "list");
        // listFields.map((field) => {      
        //   // // dataFetch.set(`${field.name}`,this.state[`${field.name}`]);
        //   //console.log(`${field.name}: `+this.state[`${field.name}`]);
        //   alert(`${field.name}`);
        //   [`${field.name}`].componentDidMount();
        //   return null;
        // });

        // SI DESDE LA API MANDAMOS ACTUALIZAR LISTADOS VOLVEMOS SU VALOR A false
        if (this.state.updateLists) {
            this.setState({ updateLists: false });
        }
        // this.setState({ updateLists: true }, this.setState({ updateLists: false }) );  

        }

        this.setState({ isLoading: false });  
        this.setState({ isLoadingModal: false });  
        
    })
    .catch(error => {
        // alert("Error: " + error);
        console.log("Error: " + error);
        // console.log(error);
        this.setState({ isLoading: false });  
        this.setState({ isLoadingModal: false });
    })

    this.handleErrors();

}

