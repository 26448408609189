import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link, NavLink, withRouter, Router, Redirect } from "react-router-dom";

const Footer = () => (
  <footer className="footer">
    <Container fluid>
      <Row className="text-muted">
        <Col xs="6" className="text-left">
          <ul className="list-inline">
            <li className="list-inline-item">
              <span className="text-muted" href="#">
                Soporte Técnico:
              </span>
            </li>
            <li className="list-inline-item">
              <span className="text-muted" href="mailto:informatica@fakolith.com">
                <Link to="#" onClick={(e) => {
                  window.location = "mailto:informatica@fakolith.com";
                  e.preventDefault();
                  }}>informatica@fakolith.com</Link>
              </span>
            </li>
            {/* <li className="list-inline-item">
              <span className="text-muted" href="#">
                |
              </span>
            </li> */}
            {/* <li className="list-inline-item">
              <span className="text-muted" href="#">
                +34 639675602
              </span>
            </li> */}
          </ul>
        </Col>
        <Col xs="6" className="text-right">
          <p className="mb-0">
            &copy; {new Date().getFullYear()} -{" "}
            <span href="/" className="text-muted">
              Fakolith Chemical Systems, S.L.U.
            </span>
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
