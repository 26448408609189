import React from "react";
import { BrowserRouter, Link, Redirect, withRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../redux/store/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Routes from "../routes/RoutesLogout";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
  DropdownItem
} from "reactstrap";
import { Route, NavLink } from "react-router-dom";
// import RoutesLogout from "../routes/RoutesLogout";
// import { LogOut } from "react-feather";


class LogOut extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
    //this.props.shareMethods(this.handleLogout.bind(this));
    this.clearSession = this.clearSession.bind(this);
    this.redirect = this.redirect.bind(this);
    this.reload = this.reload.bind(this);
  }

  componentDidMount() {
    //this.props.onRef(this.handleLogout.bind(this));
    this.handleLogout();
  }

  async clearSession() {
    await localStorage.clear();
    await sessionStorage.clear();
  }

  async redirect() {
    // window.location.reload();
    // alert("LLEGO!");
    await this.props.history.push('/');
  }

  async reload() {
    window.location.reload();    
  }

  async handleLogout() {

    await this.clearSession();
    // localStorage.clear();
    // sessionStorage.clear();
    await this.redirect();
    await this.reload();
    // await this.props.history.push('/');
    // window.location.reload();
  }

  render() {

    return (
      null
    );
  }
}

// export default LogOut;
export default withRouter(LogOut);
