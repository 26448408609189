// import { ComponentType, SVGAttributes } from 'react';

import async from "../components/Async";

import {
  Bell as Bellicon,
  BookOpen as BookOpenIcon,
  Box as BoxIcon,
  Calendar as CalendarIcon,
  CheckSquare as CheckSquareIcon,
  Clipboard as ClipboardIcon,
  DollarSign as DollarSignIcon,
  Grid as GridIcon,
  Heart as HeartIcon,
  Image as ImageIcon,
  Layers as LayersIcon,
  Layout as LayoutIcon,
  List as ListIcon,
  MapPin as MapPinIcon,
  Monitor as MonitorIcon,
  PieChart as PieChartIcon,
  Rss as RssIcon,
  Sliders as SlidersIcon,
  Copy as CopyIcon,
  Home as HomeIcon,
  Users as UsersIcon,
  User as UserIcon,
  Settings as SettingsIcon,
  Eye as EyeIcon,
  HelpCircle as HelpCircleIcon,
  LogOut as LogOutIcon,
  MessageCircle as MessageCircleIcon,
  Database as DatabaseIcon,
} from "react-feather";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  faCube,
  // faCubes,
  // faPaintRoller,
  // faImages,
  // faBlog,
  // faSearch,
  // faFileInvoiceDollar,
} from "@fortawesome/free-solid-svg-icons";

// Landing
import Landing from "../pages/landing/Landing";

// Auth
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ResetPassword from "../pages/auth/ResetPassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

// Layouts
import Boxed from "../pages/layouts/Boxed";
import SidebarCollapsed from "../pages/layouts/SidebarCollapsed";
import SidebarSticky from "../pages/layouts/SidebarSticky";
import ThemeClassic from "../pages/layouts/ThemeClassic";
import ThemeCorporate from "../pages/layouts/ThemeCorporate";
import ThemeModern from "../pages/layouts/ThemeModern";

// Misc
import Blank from "../pages/misc/Blank";

// UI Elements
import Alerts from "../pages/ui-elements/Alerts";
import Buttons from "../pages/ui-elements/Buttons";
import Cards from "../pages/ui-elements/Cards";
import Carousel from "../pages/ui-elements/Carousel";
import EmbedVideo from "../pages/ui-elements/EmbedVideo";
import General from "../pages/ui-elements/General";
import Grid from "../pages/ui-elements/Grid";
import Modals from "../pages/ui-elements/Modals";
import Tabs from "../pages/ui-elements/Tabs";
import Typography from "../pages/ui-elements/Typography";

// Notifications
import Notifications from "../pages/notifications/Notifications";

// Pages
import Profile from "../pages/pages/Profile";
import Settings from "../pages/pages/Settings";
import Clients from "../pages/pages/Clients";
import Projects from "../pages/pages/Projects";
import Invoice from "../pages/pages/Invoice";
import Pricing from "../pages/pages/Pricing";
import Tasks from "../pages/pages/Tasks";
import Chat from "../pages/pages/Chat";

// Documentation
import Introduction from "../pages/docs/Introduction";
import Installation from "../pages/docs/Installation";
import Plugins from "../pages/docs/Plugins";
import Changelog from "../pages/docs/Changelog";

// AI
import ListThreads from "../assistants/threads/ListThreads";
import ListApplications from "../knowledge/applications/ListApplications";

// // Productos
// import Familias from "../pages/tables/Familias"; //PARECE NO HABER DIFERENCIA ENTRE LAS 2
// //const Familias = async(() => import("../pages/tables/Familias"));
// import ListTarifas from "../productos/tarifas/ListTarifas";
import DashboardTarifas from "../productos/tarifas/DashboardTarifas";
// import ListFamilias from "../productos/familias/ListFamilias";
import DashboardFamilias from "../productos/familias/DashboardFamilias";
// import ListProductos from "../productos/productos/ListProductos";
import DashboardProductos from "../productos/productos/DashboardProductos";
import ListProductosAgrupados from "../productos/agrupados/ListProductosAgrupados";
import EditRegenerarCatalogos from "../productos/regenerar/EditRegenerarCatalogos";

import ListSistemas from "../sistemas/sistemas/ListSistemas";
import ListDocumentos from "../sistemas/documentos/ListDocumentos";

import ListGuias from "../guias/guias/ListGuias";
import EditRegenerarGuias from "../guias/regenerar/EditRegenerarGuias";
import ListGuiasTextos from "../guias/textos/ListGuiasTextos";

import ListSliderFakolith from "../recursos/slider_fakolith/ListSliderFakolith";
import ListSliderFakolithIng from "../recursos/slider_fakolith_ing/ListSliderFakolithIng";
import ListSliderPinturaalimentaria from "../recursos/slider_pinturaalimentaria/ListSliderPinturaalimentaria";
import ListSliderFoodgradepaint from "../recursos/slider_foodgradepaint/ListSliderFoodgradepaint";
import ListLogos from "../recursos/logos/ListLogos";
import ListImagenes from "../recursos/imagenes/ListImagenes";
import ListVideos from "../recursos/videos/ListVideos";

import ListNoticias from "../blog/noticias/ListNoticias";
import ListPosts from "../blog/posts/ListPosts";
import ListEtiquetas from "../blog/etiquetas/ListEtiquetas";
import ListMailings from "../blog/mailings/ListMailings";

import ListLandings from "../landings/landings/ListLandings";
import ListEnlaces from "../landings/enlaces/ListEnlaces";

import EditSeoFakolith from "../seo/fakolith/EditSeoFakolith";
import EditSeoPinturaalimentaria from "../seo/pinturaalimentaria/EditSeoPinturaalimentaria";

import ListAccesosPresupuestos from "../presupuestos/accesos/ListAccesosPresupuestos";
import ListAccionesPresupuestos from "../presupuestos/acciones/ListAccionesPresupuestos";
// import ListErroresPresupuestos from "../presupuestos/errores/ListErroresPresupuestos";
import DashboardErroresPresupuestos from "../presupuestos/errores/DashboardErroresPresupuestos";
import ListPresupuestos from "../presupuestos/presupuestos/ListPresupuestos";
// import ListUsuarios from "../presupuestos/usuarios/ListUsuarios";
import DashboardUsuarios from "../presupuestos/usuarios/DashboardUsuarios";
// import ListEmpresas from "../presupuestos/empresas/ListEmpresas";
// import ListRanking from "../presupuestos/empresas/ListRankingEmpresas";
import DashboardEmpresas from "../presupuestos/empresas/DashboardEmpresas";
// import ListProvincias from "../presupuestos/provincias/ListProvincias";
import DashboardProvincias from "../presupuestos/provincias/DashboardProvincias";
// import ListCampanyas from "../presupuestos/campanyas/ListCampanyas";
import DashboardCampanyas from "../presupuestos/campanyas/DashboardCampanyas";

// import EditEmpresas from "../configuracion/empresas/EditEmpresas";
// import EditUsuarios from "../configuracion/usuarios/EditUsuarios";
import DashboardCuentas from "../configuracion/cuentas/DashboardCuentas";
import ListDuplicarTablas from "../configuracion/duplicar/ListDuplicarTablas";
import ListAccesos from "../observabilidad/accesos/ListAccesos";
import ListAcciones from "../observabilidad/acciones/ListAcciones";
// import ListErrores from "../observabilidad/errores/ListErrores";
import DashboardErrores from "../observabilidad/errores/DashboardErrores";

import Help from "../components/Help";
import LogOut from "../components/LogOut";

// const Icon = ComponentType;



// Dashboards
const Default = async(() => import("../pages/dashboards/Default"));
const Analytics = async(() => import("../pages/dashboards/Analytics"));
const Ecommerce = async(() => import("../pages/dashboards/Ecommerce"));
const Crypto = async(() => import("../pages/dashboards/Crypto"));
const Social = async(() => import("../pages/dashboards/Social"));

// Forms
const Layouts = async(() => import("../pages/forms/Layouts"));
const BasicInputs = async(() => import("../pages/forms/BasicInputs"));
const AdvancedInputs = async(() => import("../pages/forms/AdvancedInputs"));
const InputGroups = async(() => import("../pages/forms/InputGroups"));
const Editors = async(() => import("../pages/forms/Editors"));
const Validation = async(() => import("../pages/forms/Validation"));
const Wizard = async(() => import("../pages/forms/Wizard"));

// Tables
const BootstrapTables = async(() => import("../pages/tables/Bootstrap"));
const PaginationTables = async(() => import("../pages/tables/Pagination"));
const RowSelectionTables = async(() => import("../pages/tables/RowSelection"));
const ExportCsvTables = async(() => import("../pages/tables/ExportCsv"));
const ExpandableRowsTables = async(() => import("../pages/tables/ExpandableRows"));

// Charts
const Chartjs = async(() => import("../pages/charts/Chartjs"));
const ApexCharts = async(() => import("../pages/charts/ApexCharts"));

// Icons
const FontAwesome = async(() => import("../pages/icons/FontAwesome"));
const Feather = async(() => import("../pages/icons/Feather"));

// Calendar
const Calendar = async(() => import("../pages/calendar/Calendar"));

// Maps
const VectorMaps = async(() => import("../pages/maps/VectorMaps"));
const GoogleMaps = async(() => import("../pages/maps/GoogleMaps"));

// Routes
const landingRoutes = {
  path: "/landing",
  name: "Landing Page",
  component: Landing,
  children: null
};

const assistantsRoutes = {
  path: "/assistants",
  name: "Asistentes",
  header: "AI",
  //icon: faCube["svgPathData"],
  icon: MessageCircleIcon,
  children: [
      {
      path: "/assistants/threads/",
      name: "The Coating Expert",
      component: ListThreads
    },
  ]
};

const knowledgeRoutes = {
  path: "/knowledge",
  name: "Conocimiento",
  // header: "AI",
  //icon: faCube["svgPathData"],
  icon: DatabaseIcon,
  children: [
      {
      path: "/knowledge/applications/",
      name: "Aplicaciones",
      component: ListApplications
    },
  ]
};


const productosRoutes = {
  path: "/productos",
  name: "Productos",
  header: "Menú",
  //icon: faCube["svgPathData"],
  icon: BoxIcon,
  children: [
    // {
    //   path: "/productos/tarifas/",
    //   name: "Tarifas",
    //   component: ListTarifas
    // },
    {
      path: "/productos/tarifas/",
      name: "Tarifas",
      component: DashboardTarifas
    },
    // {
    //   path: "/productos/familias/",
    //   name: "Familias",
    //   component: ListFamilias
    // },
    {
      path: "/productos/familias/",
      name: "Familias",
      component: DashboardFamilias
    },
    // {
    //   path: "/productos/productos/",
    //   name: "Productos",
    //   component: ListProductos
    // },
    {
      path: "/productos/productos/",
      name: "Productos",
      component: DashboardProductos
    },
    {
      path: "/productos/agrupados/",
      name: "Productos agrupados",
      component: ListProductosAgrupados
    },
    {
      path: "/productos/regenerar/",
      name: "Regenerar catálogos",
      component: EditRegenerarCatalogos
    }
  ]
};

const sistemasRoutes = {
  path: "/sistemas",
  name: "Sistemas",
  // header: "Sistemas",
  icon: LayersIcon,
  children: [
    {
      path: "/sistemas/sistemas/",
      name: "Sistemas",
      component: ListSistemas
    },
    {
      path: "/sistemas/documentos/",
      name: "Documentos",
      component: ListDocumentos
    }
  ]
};

const guiasRoutes = {
  path: "/guias",
  name: "Guías",
  // header: "Guías",
  icon: ClipboardIcon,
  children: [
    {
      path: "/guias/guias/",
      name: "Guías",
      component: ListGuias
    },
    {
      path: "/guias/regenerar/",
      name: "Regenerar guías",
      component: EditRegenerarGuias
    },
    {
      path: "/guias/textos/",
      name: "Textos legales",
      component: ListGuiasTextos
    },
    // {
    //   path: "/guias/regenerar/",
    //   name: "Regenerar guías",
    //   component: ListFamilias
    // }
  ]
};

const recursosRoutes = {
  path: "/recursos",
  name: "Recursos",
  // header: "Recursos",
  icon: ImageIcon,
  children: [
    {
      path: "/recursos/slider_fakolith/",
      name: "Slider fakolith.es",
      component: ListSliderFakolith
    },
    {
      path: "/recursos/slider_fakolith_ing/",
      name: "Slider en.fakolith.es",
      component: ListSliderFakolithIng
    },
    {
      path: "/recursos/slider_pinturaalimentaria/",
      name: "Slider pinturaalimentaria.es",
      component: ListSliderPinturaalimentaria
    },
    {
      path: "/recursos/slider_foodgradepaint/",
      name: "Slider foodgradepaint.com",
      component: ListSliderFoodgradepaint
    },
    // {
    //   path: "/recursos/muestras/",
    //   name: "Muestras",
    //   component: ListFamilias
    // },
    {
      path: "/recursos/logos/",
      name: "Logos",
      component: ListLogos
    },
    {
      path: "/recursos/imagenes/",
      name: "Imágenes",
      component: ListImagenes
    },
    {
      path: "/recursos/videos/",
      name: "Vídeos",
      component: ListVideos
    }
  ]
};

const blogRoutes = {
  path: "/blog",
  name: "Blog",
  // header: "Blog",
  icon: RssIcon,
  children: [
    {
      path: "/blog/noticias/",
      name: "Noticias",
      component: ListNoticias
    },
    {
      path: "/blog/posts/",
      name: "Posts",
      component: ListPosts
    },
    {
      path: "/blog/etiquetas/",
      name: "Etiquetas",
      component: ListEtiquetas
    },
    {
      path: "/blog/mailings/",
      name: "Mailings",
      component: ListMailings
    },
  ]
};

const landingsRoutes = {
  path: "/landings",
  name: "Landings",
  // header: "Blog",
  icon: ListIcon,
  children: [
    {
      path: "/landings/enlaces/",
      name: "Enlaces",
      component: ListEnlaces
    },
    {
      path: "/landings/landings/",
      name: "Landings",
      component: ListLandings
    }
  ]
};

const seoRoutes = {
  path: "/seo",
  name: "SEO",
  // header: "SEO",
  icon: SlidersIcon,
  children: [
    {
      path: "/seo/fakolith/",
      name: "fakolith.es",
      component: EditSeoFakolith
    },
    {
      path: "/seo/pinturaalimentaria/",
      name: "pinturaalimentaria.es",
      component: EditSeoPinturaalimentaria
    },
  ]
};

const presupuestosRoutes = {
  path: "/presupuestos",
  name: "Calculith",
  // header: "Presupuestos",
  icon: DollarSignIcon,
  children: [
    {
      path: "/presupuestos/presupuestos/",
      name: "Presupuestos",
      component: ListPresupuestos
    },
    // {
    //   path: "/presupuestos/usuarios/",
    //   name: "Usuarios",
    //   component: ListUsuarios
    // },
    {
      path: "/presupuestos/usuarios/",
      name: "Usuarios",
      component: DashboardUsuarios
    },
    // {
    //   path: "/presupuestos/empresas/",
    //   name: "Empresas",
    //   component: ListEmpresas
    // },
    // {
    //   path: "/presupuestos/empresas/ranking/",
    //   name: "Ranking empresas",
    //   component: ListRanking
    // },
    {
      path: "/presupuestos/empresas/",
      name: "Empresas",
      component: DashboardEmpresas
    },
    // {
    //   path: "/presupuestos/provincias/",
    //   name: "Provincias",
    //   component: ListProvincias
    // },
    {
      path: "/presupuestos/provincias/",
      name: "Provincias",
      component: DashboardProvincias
    },
    // {
    //   path: "/presupuestos/campanyas/",
    //   name: "Campañas",
    //   component: ListCampanyas
    // },
    {
      path: "/presupuestos/campanyas/",
      name: "Campañas",
      component: DashboardCampanyas
    },
    {
      path: "/presupuestos/accesos/",
      name: "Accesos",
      component: ListAccesosPresupuestos
    },
    {
      path: "/presupuestos/acciones/",
      name: "Acciones",
      component: ListAccionesPresupuestos
    },
    // {
    //   path: "/presupuestos/errores/",
    //   name: "Errores",
    //   component: ListErroresPresupuestos
    // },
    {
      path: "/presupuestos/errores/",
      name: "Errores",
      component: DashboardErroresPresupuestos
    },
  ]
};

// const empresasRoutes = {
//   // path: `/empresas/${process.env.REACT_APP_ID_EMPRESA}`,
//   path: "/configuracion/empresas/",
//   name: "Empresa",
//   header: "Configuración",
//   icon: SettingsIcon,
//   component: EditEmpresas
// };

// const usuariosRoutes = {
//   path: "/configuracion/usuarios/",
//   name: "Usuario",
//   icon: UserIcon,
//   component: EditUsuarios
// };

const cuentasRoutes = {
  path: "/configuracion/cuentas/",
  name: "Mi cuenta",
  header: "Configuración",
  icon: UserIcon,
  component: DashboardCuentas
};

const monitorizacionRoutes = {
  path: "/observabilidad",
  name: "Observabilidad",
  // header: "Presupuestos",
  icon: EyeIcon,
  children: [
    {
      path: "/observabilidad/accesos/",
      name: "Accesos",
      component: ListAccesos
    },
    {
      path: "/observabilidad/acciones/",
      name: "Acciones",
      component: ListAcciones
    },
    // {
    //   path: "/observabilidad/errores/",
    //   name: "Errores",
    //   component: ListErrores
    // },
    {
      path: "/observabilidad/errores/",
      name: "Errores",
      component: DashboardErrores
    },
  ]
};

const duplicarRoutes = {
  path: "/configuracion/duplicar",
  name: "Duplicar tablas",
  // header: "Configuración",
  icon: CopyIcon,
  component: ListDuplicarTablas
  // children: [
  //   {
  //     path: "/configuracion/duplicar/",
  //     name: "Duplicar tablas",
  //     component: ListDuplicarTablas
  //   },
  // ]
};

const helpRoutes = {
  path: "/help/",
  name: "Ayuda",
  header: "Ayuda",
  icon: HelpCircleIcon,
  component: Help
};

const logOutRoutes = {
  // path: `/empresas/${process.env.REACT_APP_ID_EMPRESA}`,
  path: "/logout/",
  name: "Cerrar sesión",
  header: "Sesión",
  icon: LogOutIcon,
  component: LogOut
};




const dashboardRoutes = {
  path: "/dashboard",
  name: "Dashboards",
  header: "Pages",
  badgeColor: "primary",
  badgeText: "5",
  icon: SlidersIcon,
  containsHome: true,
  children: [
    {
      path: "/dashboard/default",
      name: "Default",
      component: Default
    },
    {
      path: "/dashboard/analytics",
      name: "Analytics",
      component: Analytics
    },
    {
      path: "/dashboard/e-commerce",
      name: "E-commerce",
      component: Ecommerce
    },
    {
      path: "/dashboard/social",
      name: "Social",
      component: Social
    },
    {
      path: "/dashboard/crypto",
      name: "Crypto",
      component: Crypto,
      badgeColor: "primary",
      badgeText: "New"
    }
  ]
};

const pageRoutes = {
  path: "/pages",
  name: "Pages",
  icon: LayoutIcon,
  children: [
    {
      path: "/pages/profile",
      name: "Profile",
      component: Profile
    },
    {
      path: "/pages/settings",
      name: "Settings",
      component: Settings
    },
    {
      path: "/pages/clients",
      name: "Clients",
      component: Clients
    },
    {
      path: "/pages/projects",
      name: "Projects",
      component: Projects
    },
    {
      path: "/pages/invoice",
      name: "Invoice",
      component: Invoice
    },
    {
      path: "/pages/pricing",
      name: "Pricing",
      component: Pricing
    },
    {
      path: "/pages/tasks",
      name: "Tasks",
      component: Tasks
    },
    {
      path: "/pages/chat",
      name: "Chat",
      component: Chat,
      badgeColor: "primary",
      badgeText: "New"
    },
    {
      path: "/pages/blank",
      name: "Blank Page",
      component: Blank
    }
  ]
};

const authRoutes = {
  path: "/auth",
  name: "Auth",
  icon: UsersIcon,
  badgeColor: "secondary",
  badgeText: "Special",
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500
    }
  ]
};

const layoutRoutes = {
  path: "/layouts",
  name: "Layouts",
  icon: MonitorIcon,
  children: [
    {
      path: "/layouts/sidebar-sticky",
      name: "Sticky Sidebar",
      component: SidebarSticky
    },
    {
      path: "/layouts/sidebar-collapsed",
      name: "Sidebar Collapsed",
      component: SidebarCollapsed
    },
    {
      path: "/layouts/boxed",
      name: "Boxed Layout",
      component: Boxed
    },
    {
      path: "/layouts/theme-classic",
      name: "Classic Theme",
      component: ThemeClassic
    },
    {
      path: "/layouts/theme-corporate",
      name: "Corporate Theme",
      component: ThemeCorporate,
      badgeColor: "primary",
      badgeText: "New"
    },
    {
      path: "/",
      name: "Modern Theme",
      component: ThemeModern,
      badgeColor: "primary",
      badgeText: "New"
    }
  ]
};

const documentationRoutes = {
  path: "/docs",
  name: "Documentation",
  icon: BookOpenIcon,
  children: [
    {
      path: "/docs/introduction",
      name: "Introduction",
      component: Introduction
    },
    {
      path: "/docs/installation",
      name: "Getting Started",
      component: Installation
    },
    {
      path: "/docs/plugins",
      name: "Plugins",
      component: Plugins
    },
    {
      path: "/docs/changelog",
      name: "Changelog",
      component: Changelog
    }
  ]
};

const uiRoutes = {
  path: "/ui",
  name: "UI Elements",
  header: "Tools & Components",
  icon: GridIcon,
  children: [
    {
      path: "/ui/alerts",
      name: "Alerts",
      component: Alerts
    },
    {
      path: "/ui/buttons",
      name: "Buttons",
      component: Buttons
    },
    {
      path: "/ui/cards",
      name: "Cards",
      component: Cards
    },
    {
      path: "/ui/carousel",
      name: "Carousel",
      component: Carousel
    },
    {
      path: "/ui/embed-video",
      name: "Embed Video",
      component: EmbedVideo
    },
    {
      path: "/ui/general",
      name: "General",
      component: General,
      badgeColor: "info",
      badgeText: "10+"
    },
    {
      path: "/ui/grid",
      name: "Grid",
      component: Grid
    },
    {
      path: "/ui/modals",
      name: "Modals",
      component: Modals
    },
    {
      path: "/ui/tabs",
      name: "Tabs",
      component: Tabs
    },
    {
      path: "/ui/typography",
      name: "Typography",
      component: Typography
    }
  ]
};

const iconRoutes = {
  path: "/icons",
  name: "Icons",
  icon: HeartIcon,
  badgeColor: "info",
  badgeText: "1500+",
  children: [
    {
      path: "/icons/feather",
      name: "Feather",
      component: Feather
    },
    {
      path: "/icons/font-awesome",
      name: "Font Awesome",
      component: FontAwesome
    }
  ]
};

const formRoutes = {
  path: "/forms",
  name: "Forms",
  icon: CheckSquareIcon,
  children: [
    {
      path: "/forms/layouts",
      name: "Layouts",
      component: Layouts
    },
    {
      path: "/forms/basic-inputs",
      name: "Basic Inputs",
      component: BasicInputs
    },
    {
      path: "/forms/input-groups",
      name: "Input Groups",
      component: InputGroups
    }
  ]
};

const tableRoutes = {
  path: "/tables",
  name: "Tables",
  icon: ListIcon,
  component: BootstrapTables,
  children: null
};

const formPluginsRoutes = {
  path: "/form-plugins",
  name: "Form Plugins",
  icon: CheckSquareIcon,
  header: "Plugin & Addons",
  children: [
    {
      path: "/form-plugins/advanced-inputs",
      name: "Advanced Inputs",
      component: AdvancedInputs
    },
    {
      path: "/form-plugins/editors",
      name: "Editors",
      component: Editors
    },
    {
      path: "/form-plugins/validation",
      name: "Validation",
      component: Validation
    },
    {
      path: "/form-plugins/wizard",
      name: "Wizard",
      component: Wizard
    }
  ]
};

const advancedTablesRoutes = {
  path: "/advanced-tables",
  name: "Advanced Tables",
  icon: ListIcon,
  children: [
    {
      path: "/advanced-tables/pagination",
      name: "Pagination",
      component: PaginationTables
    },
    {
      path: "/advanced-tables/row-selection",
      name: "Row Selection",
      component: RowSelectionTables
    },
    {
      path: "/advanced-tables/expandable-rows",
      name: "Expandable Rows",
      component: ExpandableRowsTables
    },
    {
      path: "/advanced-tables/export-csv",
      name: "Export CSV",
      component: ExportCsvTables
    }
  ]
};

const chartRoutes = {
  path: "/charts",
  name: "Charts",
  icon: PieChartIcon,
  badgeColor: "primary",
  badgeText: "New",
  children: [
    {
      path: "/charts/chartjs",
      name: "Chart.js",
      component: Chartjs
    },
    {
      path: "/charts/apexcharts",
      name: "ApexCharts",
      component: ApexCharts,
      badgeColor: "primary",
      badgeText: "New"
    }
  ]
};

const notificationsRoutes = {
  path: "/notifications",
  name: "Notifications",
  icon: Bellicon,
  component: Notifications,
  children: null
};

const mapRoutes = {
  path: "/maps",
  name: "Maps",
  icon: MapPinIcon,
  children: [
    {
      path: "/maps/google-maps",
      name: "Google Maps",
      component: GoogleMaps
    },
    {
      path: "/maps/vector-maps",
      name: "Vector Maps",
      component: VectorMaps
    }
  ]
};

const calendarRoutes = {
  path: "/calendar",
  name: "Calendar",
  icon: CalendarIcon,
  component: Calendar,
  children: null
};

// This route is not visisble in the sidebar
const privateRoutes = {
  path: "/private",
  name: "Private",
  children: [
    {
      path: "/private/blank",
      name: "Blank Page",
      component: Blank
    }
  ]
};

// Dashboard specific routes
export const dashboard = [
  assistantsRoutes,
  knowledgeRoutes,
  productosRoutes,
  sistemasRoutes,
  guiasRoutes,
  recursosRoutes,
  blogRoutes,
  landingsRoutes,
  presupuestosRoutes,
  seoRoutes,
  // empresasRoutes,
  cuentasRoutes,
  duplicarRoutes,
  monitorizacionRoutes,
  helpRoutes,
  logOutRoutes,

  dashboardRoutes,
  pageRoutes,
  layoutRoutes,
  documentationRoutes,
  uiRoutes,
  iconRoutes,
  formRoutes,
  tableRoutes,
  formPluginsRoutes,
  advancedTablesRoutes,
  chartRoutes,
  notificationsRoutes,
  mapRoutes,
  calendarRoutes,
  privateRoutes
];

// Landing specific routes
export const landing = [landingRoutes];

// Auth specific routes
export const page = [authRoutes];

// All routes
export default [
  assistantsRoutes,
  // knowledgeRoutes,
  productosRoutes,
  sistemasRoutes,
  guiasRoutes,
  recursosRoutes,
  blogRoutes,
  landingsRoutes,
  seoRoutes,
  presupuestosRoutes,
  // empresasRoutes,
  cuentasRoutes,
  duplicarRoutes,
  monitorizacionRoutes,
  helpRoutes,
  logOutRoutes,

  // dashboardRoutes,
  // pageRoutes,
  // authRoutes,
  // layoutRoutes,
  // documentationRoutes,
  // uiRoutes,
  // iconRoutes,
  // formRoutes,
  // tableRoutes,
  // formPluginsRoutes,
  // advancedTablesRoutes,
  // chartRoutes,
  // notificationsRoutes,
  // mapRoutes,
  // calendarRoutes
];
