import React from "react";
import { Link, withRouter } from 'react-router-dom';

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  // Col,
  Container,
  // Button,
  // Label,
  // FormGroup,
  // CustomInput,
  // Form,
  // Input,
  // Modal,
  // ModalBody,
  // ModalFooter,
  // ModalHeader
} from "reactstrap";

import GraphLines from "../../components/GraphLines";
import FormEdit from "../../components/FormEdit";

const options_web = [
  { value: "www.fakolith.es", label: "www.fakolith.es" },
  { value: "www.pinturaalimentaria.es", label: "www.pinturaalimentaria.es" },
];

class EditMailings extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      
      fields: [
        {
          name: "id",
          label: "Id",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },
        {
          name: "nombre",
          label: "Nombre cas.",
          type: "controlledText",
          hidden: false,
          readOnly: false,
          required: true
        },
        {
          name: "descripcion",
          label: "Descripción",
          type: "controlledTextarea",
          hidden: false,
          readOnly: true,
        },
        {
          name: "fecha",
          label: "Fecha (YYYY-MM-DD)",
          type: "controlledText",
          hidden: false,
          readOnly: false,
          required: true
        },
        {
          name: "web",
          label: "Web",
          type: "controlledSelect",
          hidden: false,
          readOnly: false,
          options: options_web
        },
        {
          name: "int_status",
          label: "Estado",
          type: "controlledInformative",
          hidden: true,
          readOnly: true,
          defaultValue: 3,
        },
        {
          name: "mailings_posts",
          label: "Posts y noticias",
          type: "controlledList",
          hidden: false,
          readOnly: false,
          routeList: "/blog/mailings/posts/",
          tablename: "mailings_posts",
          idField: "id",
          deleteField: "int_status",
          routeApiList: "/api/mailings/list_mailings_posts.php",
          routeApiActions: "/api/_generic/actions.php",   
          numberFiles: 0,
          numberImages: 0,     
          rowOptions: "bt_edit_row bt_delete_row", 
          columns: [
            {
              dataField: "nombre_post",
              text: "Post",
              sort: true,
              headerStyle: { "width": "38%" }
            },
            {
              dataField: "nombre_noticia",
              text: "Noticia",
              sort: true,
              headerStyle: { "width": "38%" }
            },
          ]          
        },
      ],
      /**********CAMPOS GENERALES  ************/
      component: "EditMailings",
      routeList: "/blog/mailings/",
      tablename: "mailings",
      idField: "id",
      deleteField: "int_status",
      routeApiControl: "/api/_generic/control.php",
      routeApiDeleteFile: "/api/_generic/delete_file.php",
      routeApiDeleteImage: "/api/_generic/delete_image.php",
      routeApiDeleteImageFile: "/api/_generic/delete_image_file.php",
      routeApiActions: "/api/mailings/actions.php",
      numberFiles: 0,
      numberImages: 0, 
      readOnly: false,
      options: "bt_save bt_duplicate bt_back bt_delete",
      informativeHidden: true,
      autoSave: false,
      initialFetch: true,
      skipRedirectSubmit: false,
      submitButtonText: "Guardar",

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES A EXCLUIR DEL ENVIO A LA API
      dataExcludeParentComponent: [
        // "options_provincia",
        // "routeThumbnail1",
        // "routeImage1",
      ],

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES TIPO HIDDEN O READONLY DEL ESTADO A INCLUIR DEL ENVIO A LA API
      dataIncludeParentComponent: []
    }
  }

  render () {

    return (
        <Container fluid className="p-0">
          <h1 className="h3 mb-3">Mailings</h1>
          <Card>
            <CardHeader>
              <CardTitle tag="h5">Editar mailing</CardTitle>
              <h6 className="card-subtitle text-muted">
                Introduce la información referente a este mailing.
              </h6>
            </CardHeader>
            <CardBody>
              <FormEdit 
                component={this.state.component}
                fields={this.state.fields} 
                routeList={this.state.routeList} 
                tablename={this.state.tablename} 
                idField={this.state.idField} 
                deleteField={this.state.deleteField}
                idValue={this.props.match.params.id}
                routeApiControl={this.state.routeApiControl} 
                routeApiDeleteFile={this.state.routeApiDeleteFile} 
                routeApiDeleteImage={this.state.routeApiDeleteImage} 
                routeApiDeleteImageFile={this.state.routeApiDeleteImageFile} 
                routeApiActions={this.state.routeApiActions} 
                numberFiles={this.state.numberFiles} 
                numberImages={this.state.numberImages} 
                readOnly={this.state.readOnly} 
                options={this.state.options}
                informativeHidden={this.state.informativeHidden}
                autoSave={this.state.autoSave}
                dataExcludeParentComponent={this.state.dataExcludeParentComponent}
                dataIncludeParentComponent={this.state.dataIncludeParentComponent}
                initialFetch={this.state.initialFetch}
              /> 
            </CardBody>
          </Card>           
      </Container>
    );
  }
}

//export default EditMailings;
export default withRouter(EditMailings);
