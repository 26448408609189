import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Container, Row, Col, Button} from "reactstrap";


import FormList from "../../components/FormList";
import ListErroresPresupuestos from "./ListErroresPresupuestos";
import ListRankingErroresPresupuestos from "./ListRankingErroresPresupuestos";

class DashboardErroresPresupuestos extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      
      component: "DashboardErroresPresupuestos",

    }
  }

  render () {

    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Errores</h1>
        <ListErroresPresupuestos />
        <ListRankingErroresPresupuestos />
      </Container>
    );
  }
}

export default DashboardErroresPresupuestos;
