import React from "react";
import { Link, withRouter } from 'react-router-dom';

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  // Col,
  Container,
  // Button,
  // Label,
  // FormGroup,
  // CustomInput,
  // Form,
  // Input,
  // Modal,
  // ModalBody,
  // ModalFooter,
  // ModalHeader
} from "reactstrap";

import GraphLines from "../../components/GraphLines";
import FormEdit from "../../components/FormEdit";

class EditImagenes extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      
      fields: [
        {
          name: "archivo1",
          label: "Archivo",
          type: "file",
          fileNumber: "1",
          hidden: false,
          readOnly: false,
          withTitle: false
        },
      ],
      /**********CAMPOS GENERALES  ************/
      component: "EditImagenes",
      routeList: "/recursos/imagenes/",
      tablename: "imagenes",
      idField: "id",
      deleteField: "",
      routeApiControl: "/api/imagenes/control.php",
      routeApiDeleteFile: "/api/imagenes/delete_file.php",
      routeApiDeleteImage: "/api/imagenes/delete_image.php",
      routeApiActions: "/api/imagenes/actions.php",
      numberFiles: 1,
      numberImages: 0, 
      readOnly: false,
      options: "bt_save bt_back",
      informativeHidden: true,
      autoSave: false,
      initialFetch: false,
      skipRedirectSubmit: false,
      submitButtonText: "Guardar",

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES A EXCLUIR DEL ENVIO A LA API
      dataExcludeParentComponent: [
        // "options_provincia",
        // "routeThumbnail1",
        // "routeImage1",
      ],

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES TIPO HIDDEN O READONLY DEL ESTADO A INCLUIR DEL ENVIO A LA API
      dataIncludeParentComponent: []
    }
  }

  render () {

    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Imágenes</h1>
        <Card>
          <CardHeader>
            <CardTitle tag="h5">Editar imagen</CardTitle>
            <h6 className="card-subtitle text-muted">
              Introduce la información referente a esta imagen.
            </h6>
          </CardHeader>
          <CardBody>
            <FormEdit 
              component={this.state.component}
              fields={this.state.fields} 
              routeList={this.state.routeList} 
              tablename={this.state.tablename} 
              idField={this.state.idField} 
              deleteField={this.state.deleteField}
              idValue={this.props.match.params.id}
              routeApiControl={this.state.routeApiControl} 
              routeApiDeleteFile={this.state.routeApiDeleteFile} 
              routeApiDeleteImage={this.state.routeApiDeleteImage} 
              routeApiDeleteImageFile={this.state.routeApiDeleteImageFile} 
              routeApiActions={this.state.routeApiActions} 
              numberFiles={this.state.numberFiles} 
              numberImages={this.state.numberImages} 
              readOnly={this.state.readOnly} 
              options={this.state.options}
              informativeHidden={this.state.informativeHidden}
              autoSave={this.state.autoSave}
              dataExcludeParentComponent={this.state.dataExcludeParentComponent}
              dataIncludeParentComponent={this.state.dataIncludeParentComponent}
              initialFetch={this.state.initialFetch}
            /> 
          </CardBody>
        </Card>           
      </Container>
    );
  }
}

//export default EditImagenes;
export default withRouter(EditImagenes);
