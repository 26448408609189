import React from "react";
import { Link, withRouter } from 'react-router-dom';

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  // Col,
  Container,
  // Button,
  // Label,
  // FormGroup,
  // CustomInput,
  // Form,
  // Input,
  // Modal,
  // ModalBody,
  // ModalFooter,
  // ModalHeader
} from "reactstrap";

import GraphLines from "../../components/GraphLines";
import FormEdit from "../../components/FormEdit";

class EditErrores extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      
      fields: [
        {
          name: "id",
          label: "Id",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },
        {
          name: "id_usuario",
          label: "Usuario",
          type: "controlledRouteDynamicSelect",
          hidden: false,
          readOnly: true,
          //"table": "familias",
          //options: options_id_familia
          routeApi: "/api/_generic/select_usuarios.php"
        },
        {
          name: "script",
          label: "Script",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },
        {
          name: "tabla",
          label: "Tabla",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },
        {
          name: "accion",
          label: "Acción",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },        
        {
          name: "valor",
          label: "Valor",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },        
        {
          name: "error",
          label: "Error",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },        
        {
          name: "mensaje",
          label: "Mensaje",
          type: "controlledTextarea",
          hidden: false,
          readOnly: true,
        },        
        {
          name: "ip",
          label: "IP",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },    
        {
          name: "estado_ant",
          label: "Estado ant.",
          type: "controlledTextarea",
          hidden: false,
          readOnly: true,
        },            
        {
          name: "estado",
          label: "Estado",
          type: "controlledTextarea",
          hidden: false,
          readOnly: true,
        },            
        {
          name: "log",
          label: "Log",
          type: "controlledTextarea",
          hidden: false,
          readOnly: true,
        },            
        {
          name: "fecha",
          label: "Fecha",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },
        {
          name: "hora",
          label: "Hora",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },
        {
          name: "int_status",
          label: "Estado",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
        },
      ],
      /**********CAMPOS GENERALES  ************/
      component: "EditErrores",
      routeList: "/observabilidad/errores/",
      tablename: "_admin_acciones",
      idField: "id",
      deleteField: "int_status",
      routeApiControl: "/api/_generic/control.php",
      routeApiDeleteFile: "/api/_generic/delete_file.php",
      routeApiDeleteImage: "/api/_generic/delete_image.php",
      routeApiDeleteImageFile: "/api/_generic/delete_image_file.php",
      routeApiActions: "/api/_generic/actions.php",
      numberFiles: 0,
      numberImages: 0, 
      readOnly: true,
      options: "bt_save bt_duplicate bt_back bt_delete",
      informativeHidden: true,
      autoSave: false,
      initialFetch: true,
      skipRedirectSubmit: false,
      submitButtonText: "Guardar",

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES A EXCLUIR DEL ENVIO A LA API
      dataExcludeParentComponent: [
        // "options_provincia",
        // "routeThumbnail1",
        // "routeImage1",
      ],

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES TIPO HIDDEN O READONLY DEL ESTADO A INCLUIR DEL ENVIO A LA API
      dataIncludeParentComponent: []
    }
  }

  render () {

    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Acciones</h1>
        <Card>
          <CardHeader>
            <CardTitle tag="h5">Editar acción</CardTitle>
            <h6 className="card-subtitle text-muted">
              Consulta la información referente a esta acción.
            </h6>
          </CardHeader>
          <CardBody>
            <FormEdit 
              component={this.state.component}
              fields={this.state.fields} 
              routeList={this.state.routeList} 
              tablename={this.state.tablename} 
              idField={this.state.idField} 
              deleteField={this.state.deleteField}
              idValue={this.props.match.params.id}
              routeApiControl={this.state.routeApiControl} 
              routeApiDeleteFile={this.state.routeApiDeleteFile} 
              routeApiDeleteImage={this.state.routeApiDeleteImage} 
              routeApiDeleteImageFile={this.state.routeApiDeleteImageFile} 
              routeApiActions={this.state.routeApiActions} 
              numberFiles={this.state.numberFiles} 
              numberImages={this.state.numberImages}
              readOnly={this.state.readOnly} 
              options={this.state.options}
              informativeHidden={this.state.informativeHidden}
              autoSave={this.state.autoSave}
              dataExcludeParentComponent={this.state.dataExcludeParentComponent}
              dataIncludeParentComponent={this.state.dataIncludeParentComponent}
              initialFetch={this.state.initialFetch}
            /> 
          </CardBody>
        </Card>           
      </Container>
    );
  }
}

//export default EditPresupuestos;
export default withRouter(EditErrores);
