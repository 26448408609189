import React from "react";
import { Link, withRouter } from 'react-router-dom';

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  // Col,
  Container,
  // Button,
  // Label,
  // FormGroup,
  // CustomInput,
  // Form,
  // Input,
  // Modal,
  // ModalBody,
  // ModalFooter,
  // ModalHeader
} from "reactstrap";

import GraphLines from "../../components/GraphLines";
import FormEdit from "../../components/FormEdit";

class EditSliderFoodgradepaint extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      
      fields: [
        {
          name: "id",
          label: "Id",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },
        {
          name: "nombre",
          label: "Nombre",
          type: "controlledText",
          hidden: false,
          readOnly: false,
          required: true
        },
        {
          name: "enlace",
          label: "Enlace",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "descripcion",
          label: "Descripción",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "imagen1",
          label: "Imagen",
          type: "image",
          imageNumber: "1",
          hidden: false,
          readOnly: false,
        },
        {
          name: "orden",
          label: "Orden",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "int_status",
          label: "Estado",
          type: "controlledInformative",
          hidden: true,
          readOnly: true,
          defaultValue: 3,
        },
      ],
      /**********CAMPOS GENERALES  ************/
      component: "EditSliderFoodgradepaint",
      routeList: "/recursos/slider_foodgradepaint/",
      tablename: "slider_pinturaalimentaria_ing",
      idField: "id",
      deleteField: "int_status",
      routeApiControl: "/api/_generic/control.php",
      routeApiDeleteFile: "/api/_generic/delete_file.php",
      routeApiDeleteImage: "/api/_generic/delete_image.php",
      routeApiDeleteImageFile: "/api/_generic/delete_image_file.php",
      routeApiActions: "/api/_generic/actions.php",
      numberFiles: 0,
      numberImages: 1, 
      readOnly: false,
      options: "bt_save bt_duplicate bt_back bt_delete",
      informativeHidden: true,
      autoSave: false,
      initialFetch: true,
      skipRedirectSubmit: false,
      submitButtonText: "Guardar",

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES A EXCLUIR DEL ENVIO A LA API
      dataExcludeParentComponent: [
        // "options_provincia",
        // "routeThumbnail1",
        // "routeImage1",
      ],

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES TIPO HIDDEN O READONLY DEL ESTADO A INCLUIR DEL ENVIO A LA API
      dataIncludeParentComponent: []
    }
  }

  render () {

    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Slider foodgradepaint.com</h1>
        <Card>
          <CardHeader>
            <CardTitle tag="h5">Editar slide</CardTitle>
            <h6 className="card-subtitle text-muted">
              Introduce la información referente a este slide.
            </h6>
          </CardHeader>
          <CardBody>
            <FormEdit 
              component={this.state.component}
              fields={this.state.fields} 
              routeList={this.state.routeList} 
              tablename={this.state.tablename} 
              idField={this.state.idField} 
              deleteField={this.state.deleteField}
              idValue={this.props.match.params.id}
              routeApiControl={this.state.routeApiControl} 
              routeApiDeleteFile={this.state.routeApiDeleteFile} 
              routeApiDeleteImage={this.state.routeApiDeleteImage} 
              routeApiDeleteImageFile={this.state.routeApiDeleteImageFile} 
              routeApiActions={this.state.routeApiActions} 
              numberFiles={this.state.numberFiles} 
              numberImages={this.state.numberImages} 
              readOnly={this.state.readOnly} 
              options={this.state.options}
              informativeHidden={this.state.informativeHidden}
              autoSave={this.state.autoSave}
              dataExcludeParentComponent={this.state.dataExcludeParentComponent}
              dataIncludeParentComponent={this.state.dataIncludeParentComponent}
              initialFetch={this.state.initialFetch}
            /> 
          </CardBody>
        </Card>           
      </Container>
    );
  }
}

//export default EditSliderFoodgradepaint;
export default withRouter(EditSliderFoodgradepaint);
