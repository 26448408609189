import React from "react";
import { Link, withRouter } from 'react-router-dom';

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  // Col,
  Container,
  // Button,
  // Label,
  // FormGroup,
  // CustomInput,
  // Form,
  // Input,
  // Modal,
  // ModalBody,
  // ModalFooter,
  // ModalHeader
} from "reactstrap";

import GraphLines from "../../components/GraphLines";
import FormEdit from "../../components/FormEdit";

class EditThreads extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      
      fields: [
        {
          name: "id",
          label: "Id",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },
        {
          name: "id_assistant",
          label: "Asistente",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
        },
        {
          name: "id_user",
          label: "Usuario",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
        },
        {
          name: "reference",
          label: "Referencia",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
        },
        {
          name: "name",
          label: "Nombre",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "date",
          label: "Fecha",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
        },
        {
          name: "time",
          label: "Hora",
          type: "controlledInformative",
          hidden: false,
          readOnly: true,
        },
        {
          name: "chat",
          label: "Mensajes",
          type: "controlledChat",
          hidden: false,
          readOnly: false,
          routeList: "/threads/",
          tablename: "messages",
          idField: "id",
          deleteField: "int_status",
          routeApiList: "/api/messages/list.php",
          routeApiActions: "/api/messages/actions.php",
          fetchApi: false,   
          action: "create",
        },
        // {
        //   name: "int_status",
        //   label: "Estado",
        //   type: "controlledInformative",
        //   hidden: true,
        //   readOnly: true,
        //   defaultValue: 3,
        // }
      ],
      /**********CAMPOS GENERALES  ************/
      // id: "1",
      component: "EditThreads",
      routeList: "/assistants/threads/",
      tablename: "_ai_threads",
      idField: "id",
      deleteField: "status",
      routeApiControl: "/api/threads/control.php",
      routeApiDeleteFile: "/api/_generic/delete_file.php",
      routeApiDeleteImage: "/api/_generic/delete_image.php",
      routeApiDeleteImageFile: "/api/_generic/delete_image_file.php",
      routeApiActions: "/api/threads/actions.php",
      numberFiles: 0,
      numberImages: 0, 
      readOnly: false,
      options: "bt_save bt_back bt_delete",
      informativeHidden: false,
      autoSave: false,
      initialFetch: true,
      skipRedirectSubmit: false,
      submitButtonText: "Guardar",

      // initialMessages: [
      //   {
      //     position: "right",
      //     name: "You",
      //     avatar: "",
      //     time: "2:33 am",
      //     content: "Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset te vix."
      //   },
      //   {
      //     position: "left",
      //     name: "Bertha Martin",
      //     avatar: "",
      //     time: "2:34 am",
      //     content: "Sit meis deleniti eu, pri vidit meliore docendi ut, an eum erat animal commodo."
      //   },
      //   // ... Repite para los demás mensajes
      // ],

    }
  }

  render () {

    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Asistentes</h1>
        <Card>
          <CardHeader>
            <CardTitle tag="h5">Conversación</CardTitle>
            <h6 className="card-subtitle text-muted">
              Charla con nuestro asistente para conocer los productos y procesos a aplicar a la hora de pintar, renovar y proteger cualquier tipo de superficie.
            </h6>
          </CardHeader>
          <CardBody>
            <FormEdit 
              component={this.state.component}
              fields={this.state.fields} 
              routeList={this.state.routeList} 
              tablename={this.state.tablename} 
              idField={this.state.idField} 
              deleteField={this.state.deleteField}
              idValue={this.props.match.params.id}
              // idValue={this.state.id}
              routeApiControl={this.state.routeApiControl} 
              routeApiDeleteFile={this.state.routeApiDeleteFile} 
              routeApiDeleteImage={this.state.routeApiDeleteImage} 
              routeApiDeleteImageFile={this.state.routeApiDeleteImageFile} 
              routeApiActions={this.state.routeApiActions} 
              numberFiles={this.state.numberFiles} 
              numberImages={this.state.numberImages} 
              readOnly={this.state.readOnly} 
              options={this.state.options}
              informativeHidden={this.state.informativeHidden}
              autoSave={this.state.autoSave}
              dataExcludeParentComponent={this.state.dataExcludeParentComponent}
              dataIncludeParentComponent={this.state.dataIncludeParentComponent}
              initialFetch={this.state.initialFetch}
              skipRedirectSubmit={this.state.skipRedirectSubmit}
              submitButtonText={this.state.submitButtonText}
              // initialMessages={this.state.initialMessages}
            /> 
          </CardBody>
        </Card>           
      </Container>
    );
  }
}

//export default EditThreads;
export default withRouter(EditThreads);
