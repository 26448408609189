import React from "react";
import { Link, Redirect } from "react-router-dom";

import {
  // Button,
  Card,
  CardBody,
  // Form,
  // FormGroup,
  // Col,
  // Label,
  // Input,
  // CustomInput
} from "reactstrap";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFolderOpen } from "@fortawesome/free-regular-svg-icons";
// import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import logo from "../assets/img/logo.png";
// import logo from "../assets/img/logo_calculith_vertical5.gif";
import FormLogin from "../components/FormLogin";


class LoginSignIn extends React.Component {
  
  constructor(props) {
    
    super(props);
    
    this.state = {

      fields: [
        {
          name: "email",
          label: "Email",
          placeholder: "Introduzca su email",
          type: "controlledText",
          hidden: false,
          readOnly: false,
          required: true,
          fetchApi: false,
        },
        {
          name: "password",
          label: "Contraseña",
          placeholder: "Introduzca su contraseña",
          type: "controlledPassword",
          hidden: false,
          readOnly: false,
          required: true,
          fetchApi: false,
        },
        {
          name: "olvideContraseña",
          label: "Olvidé mi contraseña",
          type: "olvideContraseña",
          hidden: false,
          readOnly: false,
        },
        {
          name: "recordar",
          label: "Mantener la sesión iniciada en este navegador",
          type: "checkbox",
          hidden: false,
          readOnly: false,
          defaultValue: true,
        },

      ],

      /**********CAMPOS GENERALES  ************/
      component: "LoginSignIn",
      action: "validate_login",
      routeList: "/",
      tablename: "_calculith_usuarios",
      idField: "id",
      deleteField: "int_status",
      // routeApiControl: "/api/_generic/control.php",
      routeApiControl: "/api/login/actions.php",
      routeApiDeleteFile: "/api/_generic/delete_file.php",
      routeApiDeleteImage: "/api/_generic/delete_image.php",
      routeApiDeleteImageFile: "/api/_generic/delete_image_file.php",
      routeApiActions: "/api/login/actions.php",
      numberFiles: 0,
      numberImages: 0, 
      readOnly: false,
      options: "bt_sign_in",
      informativeHidden: true,
      autoSave: false,
      updateListForm: false,
      isSignInForm: true,
      initialFetch: false,

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES A EXCLUIR DEL ENVIO A LA API
      dataExcludeParentComponent: [
        "recordar",
        // "routeList",
        // "routeApiControl",
        // "routeApiDeleteFile",
        // "routeApiDeleteImage",
        // "routeApiActions",
        // "informativeHidden",
        // "autoSave",
      ],

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES TIPO HIDDEN O READONLY DEL ESTADO A INCLUIR DEL ENVIO A LA API
      dataIncludeParentComponent: []
      
    };
  }

  
  render() {

    return (

      <React.Fragment>
        <div className="text-center mt-4">
          <img
            src={logo}
            alt="CALCULITH"
            // className="img-fluid rounded-circle mb-3"
            // className="img-fluid mb-3"
            className="img-fluid mb-3"
            width="260"
            height="260"
          />
          {/* <h2>Welcome to admin login</h2> */}
          <h2>Panel de administración</h2>
          <p className="lead">Introduzca sus datos de acceso al sistema</p>
        </div>
        <Card>
          <CardBody>
            <div className="m-sm-4">
              <FormLogin 
                fields={this.state.fields} 
                routeList={this.state.routeList} 
                tablename={this.state.tablename} 
                idField={this.state.idField} 
                deleteField={this.state.deleteField}
                idValue={this.props.match.params.id}
                routeApiControl={this.state.routeApiControl} 
                routeApiDeleteFile={this.state.routeApiDeleteFile} 
                routeApiDeleteImage={this.state.routeApiDeleteImage} 
              routeApiDeleteImageFile={this.state.routeApiDeleteImageFile} 
                routeApiActions={this.state.routeApiActions} 
                numberFiles={this.state.numberFiles} 
                numberImages={this.state.numberImages}
                readOnly={this.state.readOnly} 
                options={this.state.options}
                informativeHidden={this.state.informativeHidden}
                autoSave={this.state.autoSave}
                dataExcludeParentComponent={this.state.dataExcludeParentComponent}
                dataIncludeParentComponent={this.state.dataIncludeParentComponent}
                updateListForm={this.state.updateListForm}
                action={this.state.action}
                isSignInForm={this.state.isSignInForm}
                initialFetch={this.state.initialFetch}
              />

            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default LoginSignIn;
