/*

COMPONENTE PARA EL INPUT DE ARCHIVOS RELACIONADOS A UN REGISTRO EN LOS FORMULARIOS

PROPIEDADES:

  - id > EL NÚMERO DEL ARCHIVO ASOCIADO AL REGISTRO
  - url > LA URL AL ARCHIVO
  - titleInputName > NOMBRE DEL CAMPO PARA EL TÍTULO
  - withTitle > SI SE MUESTRA EL CAMPO DE TÍTULO O NO
  - title > EL VALOR DEL TÍTULO
  - handleFileChange > FUNCIÓN A EJECUTAR CUANDO SE SELECCIONE UN ARCHIVO
  - handleFileDelete > FUNCIÓN A EJECUTAR CUANDO SE ELIMINE UN ARCHIVO
  - name > EL VALOR DEL NOMBRE DEL INPUT EN EL FORMULARIO
  - route > EL VALOR DE LA RUTA DEL ARCHIVO
  - readOnly > SI SE PUEDE EDITAR O NO

FUNCIONES:

  - render > RENDERIZADO

*/
import React from "react";
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen, faFile, faTrashAlt} from "@fortawesome/free-regular-svg-icons";

// import {
//   // AvForm,
//   Input,
//   // AvGroup,
//   // AvInput
//   // AvFeedback,
//   // AvRadioGroup,
//   // AvRadio,
//   // AvCheckboxGroup,
//   // AvCheckbox
// } from "availity-reactstrap-validation";
import {
  // Card,
  // CardBody,
  // CardHeader,
  // CardTitle,
  Col,
  // Container,
  Button,
  Label,
  FormGroup,
  Input
  // CustomInput
} from "reactstrap";

class InputFile extends React.Component {

  constructor() {
    super();
    this.state = {
    };
  }

  render () {

    return (
      <div>
        { ((this.props.withTitle)) ?
          <Input name={this.props.titleInputName} id={this.props.titleInputName} defaultValue={this.props.title} readOnly={this.props.readOnly} className="container-fluid mb-1" />
        : 
          <Input name={this.props.titleInputName} id={this.props.titleInputName} defaultValue={this.props.title} readOnly={this.props.readOnly} className="container-fluid mb-1" hidden />
        }
        { ((this.props.url !== undefined) && (this.props.url !== "")) ? 
          <FormGroup row>
            <Col sm={6}>
              <Input type="text" id="route" name="route" className="container-fluid mr-1 mb-1" value={this.props.route} readOnly />
              <input name={this.props.name} id={this.props.id} type="file" ref={file => this.file = file} onChange={this.props.handleFileChange} hidden />
            </Col>
            <Col sm={6}>
              <Button name="bt_select_file" color="primary" className="mr-1 mb-1" onClick={() => this.file.click()} disabled={this.props.readOnly} >
                <FontAwesomeIcon icon={faFolderOpen} /> Seleccionar
              </Button>
              <Button name="bt_view_file" color="success" className="mr-1 mb-1" tag={Link} to="/#" onClick={(event) => {event.preventDefault(); window.open(`${process.env.REACT_APP_API_DOMAIN}${this.props.url}`);}} >
                <FontAwesomeIcon icon={faFile} /> Visualizar
              </Button>
              <Button name="bt_delete_file" id={this.props.id} color="danger" className="mr-1 mb-1" tag={Link} to="/#" onClick={this.props.handleFileDelete}  disabled={this.props.readOnly} >
                <FontAwesomeIcon icon={faTrashAlt} /> Eliminar
              </Button>                            
            </Col>
          </FormGroup>
        :
          <FormGroup row>
            <Col sm={6}>
              <Input type="text" id="route" name="route" className="container-fluid mr-1 mb-1" value={this.props.route} readOnly />
              <input name={this.props.name} id={this.props.id} type="file" ref={file => this.file = file} onChange={this.props.handleFileChange} hidden />
            </Col>
            <Col sm={6}>
              <Button name="bt_select_file" color="primary" className="mr-1 mb-1" onClick={() => this.file.click()} disabled={this.props.readOnly} >
                <FontAwesomeIcon icon={faFolderOpen} /> Seleccionar
              </Button>
              <Button name="bt_view_file" color="success" className="mr-1 mb-1" tag={Link} to="/#" onClick={(event) => {event.preventDefault(); window.open(`${process.env.REACT_APP_API_DOMAIN}/${this.props.url}`);}} disabled >
                <FontAwesomeIcon icon={faFile}  /> Visualizar
              </Button>
              <Button name="bt_delete_file" id={this.props.id} color="danger" className="mr-1 mb-1" tag={Link} to="/#" onClick={this.props.handleFileDelete} disabled >
                <FontAwesomeIcon icon={faTrashAlt} /> Eliminar
              </Button>                            
            </Col>
          </FormGroup>
        }
      </div>
    )
  }
}

export default InputFile;
