import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Container, Row, Col, Button} from "reactstrap";


import FormList from "../../components/FormList";
import ListFamilias from "./ListFamilias";
import ListRankingFamilias from "./ListRankingFamilias";

class DashboardFamilias extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      
      component: "DashboardUsuarios",

    }
  }

  render () {

    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Familias</h1>
        <ListFamilias />
        <ListRankingFamilias />
      </Container>
    );
  }
}

export default DashboardFamilias;
