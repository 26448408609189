// import { extend } from "jquery";
import React from "react";
import { Router, Link, withRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import store, { saveState, loadState } from "./redux/store/index";
import Routes from "./routes/Routes";
import RoutesLogin from "./routes/RoutesLogin";
// import RoutesLogout from "./routes/RoutesLogout";
import { toastr } from "react-redux-toastr";
import { Alert } from "bootstrap";

import {
  // modalToggle,
  // modalCancel,
  // modalConfirm,
  // handleFileChange,
  // handleFileDelete,
  // handleImageChange,
  // handleImageFileChange,
  // handleImageDelete,
  // handleImageFileDelete,
  // handleDelete,
  // handleDuplicate,
  // handleSendEmail,
  // deleteRegister,
  // duplicateRegister,
  // sendRegister,
  // redirectRegister,
  // updateForm,
  // initialState,

  // handleValidSubmit,
  // handleChange,
  // handleChangeOnSelect,
  // handleChangeOnEditor,
  // handleChangeOnSwitch,
  // handleChangeOnEmail,
  handleErrors,
  showToastr,
  // validateEmail,
  // validateFields,
  excludeDataFetch,
  fetchApi,
} from "./components/Functions";
import { Redirect } from "react-router-dom";

class App extends React.Component {

  constructor(props) {

    super(props);

    this.state = {

      routeApiActions: "/api/login/actions.php",
      action: "validate_session",
      token: localStorage.getItem("token") || sessionStorage.getItem("token"),     

      // CAMPOS LOADING
      isLoading: true,
      isLoadingModal: true,
      
      // CAMPOS ERRORES (DEBEN CONTENER "error" AL PRINCIPIO PARA QUE NO SE EXCLUYAN AL ENVIAR A LA API)
      errorValidation: false,
      errorForm: "",
      toastrIsOpen: false,

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES A EXCLUIR DEL ENVIO A LA API
      // dataExcludeParentComponent: props.dataExcludeParentComponent,
      dataExcludeComponent: [
        "token",
        "routeApiActions",
        "isLoading",
        "modalType",
        "modalTitle",
        "modalText",
        "modalIsOpen",
        "isLoadingModal",
        "errorValidation",
        "errorForm",
        "toastrIsOpen",
        "dataExcludeParentComponent",
        "dataExcludeComponent",
        "dataIncludeParentComponent",
        "dataIncludeComponent"
      ],

      // ARRAY CON LOS NOMBRES DE LAS VARIABLES A INCLUIR EN EL ENVIO A LA API
      dataIncludeComponent: [],

    };

    this.getPayloadFromToken = this.getPayloadFromToken.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.handleErrors = handleErrors.bind(this);
    this.showToastr = showToastr.bind(this);
    this.excludeDataFetch = excludeDataFetch.bind(this);
    this.fetchApi = fetchApi.bind(this);
  }

  getPayloadFromToken(token) {
        
    const encodedPayload = token.split('.')[1];
    // const decodedPayload = JSON.parse(atob(encodedPayload));
    // alert("encodedPayload: " + JSON.parse(atob(encodedPayload)));
    // alert("id: " + decodedPayload["id"]);
    // alert("hora: " + decodedPayload["hora"]);
    // console.log(decodedPayload["name"]);
    return JSON.parse(atob(encodedPayload));
  }

  async componentDidMount() {

    // SI EXISTE EL TOKEN LO ENVIAMOS A COMPROBAR JUNTO CON EL ID DEL USUARIO
    if (this.state.token) {

      this.setState({ isLoadingModal: true });

      let user = this.getPayloadFromToken(this.state.token);

      await this.setState({ id: user["id"] })

      await this.fetchApi(`${this.state.routeApiActions}`);
      
      if (this.state["errorForm"]) {

        localStorage.clear();
        // sessionStorage.clear();

        // LO DESHABILITAMOS PORQUE NO DA TIEMPO A VERSE
        // this.showToastr("Error", this.state["errorForm"]);

        // window.location.reload();
      }
    }

    this.setState({ isLoading: false })
    
  }


  render() {
   
    if (this.state.isLoading) { 
      return null;
    } else {

      // SI SE HA VERIFICADO EL USUARIO CARGAMOS TODAS LAS RUTAS 
      // alert("TOKEN EN RENDER APP:" + this.state.token);
      if (this.state.token !== null) {

        // alert("ROUTES");
        return (
          <Provider store={store}>
            {/* <Routes redirect={this.state.redirect}/> */}
            <Routes/>
            <ReduxToastr
              timeOut={5000}
              newestOnTop={true}
              position="top-right"
              transitionIn="fadeIn"
              transitionOut="fadeOut"
              progressBar
              closeOnToastrClick
            />
          </Provider>
        );
        
      } else {

        // alert("ROUTESLOGIN");
        return (
          <Provider store={store}>
            <RoutesLogin />
            <ReduxToastr
              timeOut={5000}
              newestOnTop={true}
              position="top-right"
              transitionIn="fadeIn"
              transitionOut="fadeOut"
              progressBar
              closeOnToastrClick
              />
          </Provider>
        );
        
      };

    }

  }
}

export default App;
// export default withRouter(App);
