import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Container, Row, Col, Button} from "reactstrap";


import FormList from "../../components/FormList";
import ListProvincias from "./ListProvincias";
import ListRankingProvincias from "./ListRankingProvincias";

class DashboardProvincias extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      
      component: "DashboardProvincias",

    }
  }

  render () {

    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Provincias</h1>
        <ListProvincias />
        <ListRankingProvincias />
      </Container>
    );
  }
}

export default DashboardProvincias;
