import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Container, Row, Col, Button} from "reactstrap";


import FormList from "../../components/FormList";
import ListRankingEmpresas from "./ListRankingEmpresas";
import ListEmpresas from "./ListEmpresas";

class DashboardEmpresas extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      
      component: "DashboardEmpresas",

    }
  }

  render () {

    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Empresas</h1>
        <ListEmpresas />
        <ListRankingEmpresas />
      </Container>
    );
  }
}

export default DashboardEmpresas;
