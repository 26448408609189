import React from "react";
import { BrowserRouter, Link, Redirect, withRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../redux/store/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Routes from "../routes/RoutesLogout";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
  DropdownItem
} from "reactstrap";
import { Route, NavLink } from "react-router-dom";
// import RoutesLogout from "../routes/RoutesLogout";
import { LogOut } from "react-feather";


class LogOutDropdownItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
    //this.props.shareMethods(this.handleLogout.bind(this));
    this.clearSession = this.clearSession.bind(this);
    this.redirect = this.redirect.bind(this);
    this.reload = this.reload.bind(this);
  }

  componentDidMount() {
    //this.props.onRef(this.handleLogout.bind(this));
  }

  async clearSession() {
    await localStorage.clear();
    await sessionStorage.clear();
  }

  async redirect() {
    // window.location.reload();
    // alert("LLEGO!");
    await this.props.history.push('/');
  }

  async reload() {
    window.location.reload();    
  }

  async handleLogout() {

    await this.clearSession();
    // localStorage.clear();
    // sessionStorage.clear();
    await this.redirect();
    await this.reload();
    // await this.props.history.push('/');
    // window.location.reload();
  }

  render() {

    return (

      // <Provider store={store}>
      // <div onClick={() => this.handleLogout()}>
      //   <a color="primary" size="lg">
      //     Sign out
      //   </a>
      // </div>
      // <BrowserRouter>
      //  <Link to={"/"}> 

      <DropdownItem onClick={() => this.handleLogout()}>
        {/* <Link to={""} > */}
          <LogOut size={18} className="align-middle mr-2" />
          Cerrar sesión
        {/* </Link> */}
      </DropdownItem>

      // </BrowserRouter>
      // </Provider>
    );
  }
}

// export default LogOutDropdownItem;
export default withRouter(LogOutDropdownItem);
