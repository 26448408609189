import React from "react";
import { Link, withRouter } from 'react-router-dom';

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  // Col,
  Container,
  // Button,
  // Label,
  // FormGroup,
  // CustomInput,
  // Form,
  // Input,
  // Modal,
  // ModalBody,
  // ModalFooter,
  // ModalHeader
} from "reactstrap";

import GraphLines from "../../components/GraphLines";
import FormEdit from "../../components/FormEdit";

class EditVideos extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      
      fields: [
        {
          name: "id",
          label: "Id",
          type: "controlledText",
          hidden: false,
          readOnly: true,
        },
        {
          name: "nombre",
          label: "Nombre cas.",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "nombre_ing",
          label: "Nombre ing.",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "descripcion",
          label: "Descripción cas.",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "descripcion_ing",
          label: "Descripción ing.",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "version_cas",
          label: "Versión cas.",
          type: "controlledSwitch",
          hidden: false,
          readOnly: false,
        },
        {
          name: "version_ing",
          label: "Versión ing.",
          type: "controlledSwitch",
          hidden: false,
          readOnly: false,
        },
        {
          name: "orden",
          label: "Orden",
          type: "controlledText",
          hidden: false,
          readOnly: false,
        },
        {
          name: "int_status",
          label: "Estado",
          type: "controlledInformative",
          hidden: true,
          readOnly: true,
          defaultValue: 3,
        },
        {
          name: "videos_sistemas",
          label: "Sistemas relacionados",
          type: "controlledList",
          hidden: false,
          readOnly: false,
          routeList: "/recursos/videos/sistemas/",
          tablename: "videos_sistemas",
          idField: "id",
          deleteField: "int_status",
          routeApiList: "/api/videos/list_videos_sistemas.php",
          routeApiActions: "/api/_generic/actions.php",
          numberFiles: 0,
          numberImages: 0,
          rowOptions: "bt_edit_row bt_delete_row",
          columns: [
            {
              dataField: "nombre",
              text: "Nombre cas.",
              sort: true,
              headerStyle: { "width": "27%" }
            },
            {
              dataField: "nombre_ing",
              text: "Nombre ing.",
              sort: true,
              headerStyle: { "width": "27%" }
            },
            {
              dataField: "orden",
              text: "Orden",
              sort: true,
              headerStyle: { "width": "18%" }
            }            
          ]          
        },
        {
          name: "videos_familias",
          label: "Familias relacionadas",
          type: "controlledList",
          hidden: false,
          readOnly: false,
          routeList: "/recursos/videos/familias/",
          tablename: "videos_familias",
          idField: "id",
          deleteField: "int_status",
          routeApiList: "/api/videos/list_videos_familias.php",
          routeApiActions: "/api/_generic/actions.php",
          numberFiles: 0,
          numberImages: 0,
          rowOptions: "bt_edit_row bt_delete_row",   
          columns: [
            {
              dataField: "nombre",
              text: "Nombre cas.",
              sort: true,
              headerStyle: { "width": "27%" }
            },
            {
              dataField: "nombre_ing",
              text: "Nombre ing.",
              sort: true,
              headerStyle: { "width": "27%" }
            },
            {
              dataField: "orden",
              text: "Orden",
              sort: true,
              headerStyle: { "width": "18%" }
            }            
          ]          
        },
        {
          name: "videos_productos",
          label: "Productos relacionados",
          type: "controlledList",
          hidden: false,
          readOnly: false,
          routeList: "/recursos/videos/productos/",
          tablename: "videos_productos",
          idField: "id",
          deleteField: "int_status",
          routeApiList: "/api/videos/list_videos_productos.php",
          routeApiActions: "/api/_generic/actions.php",
          numberFiles: 0,
          numberImages: 0,
          rowOptions: "bt_edit_row bt_delete_row",  
          columns: [
            {
              dataField: "nombre",
              text: "Nombre cas.",
              sort: true,
              headerStyle: { "width": "27%" }
            },
            {
              dataField: "nombre_ing",
              text: "Nombre ing.",
              sort: true,
              headerStyle: { "width": "27%" }
            },
            {
              dataField: "orden",
              text: "Orden",
              sort: true,
              headerStyle: { "width": "18%" }
            }
          ]          
        },
      ],
      /**********CAMPOS GENERALES  ************/
      component: "EditVideos",
      routeList: "/recursos/videos/",
      tablename: "videos",
      idField: "id",
      deleteField: "int_status",
      routeApiControl: "/api/_generic/control.php",
      routeApiDeleteFile: "/api/_generic/delete_file.php",
      routeApiDeleteImage: "/api/_generic/delete_image.php",
      routeApiDeleteImageFile: "/api/_generic/delete_image_file.php",
      routeApiActions: "/api/_generic/actions.php",
      numberFiles: 0,
      numberImages: 0, 
      readOnly: false,
      options: "bt_save bt_duplicate bt_back bt_delete",
      informativeHidden: true,
      autoSave: false,
      initialFetch: true,
      skipRedirectSubmit: false,
      submitButtonText: "Guardar",
    }
  }

  render () {

    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Vídeos</h1>
        <Card>
          <CardHeader>
            <CardTitle tag="h5">Editar vídeo</CardTitle>
            <h6 className="card-subtitle text-muted">
              Introduce la información referente a este vídeo.
            </h6>
          </CardHeader>
          <CardBody>
            <FormEdit 
              component={this.state.component}
              fields={this.state.fields} 
              routeList={this.state.routeList} 
              tablename={this.state.tablename} 
              idField={this.state.idField} 
              deleteField={this.state.deleteField}
              idValue={this.props.match.params.id}
              routeApiControl={this.state.routeApiControl} 
              routeApiDeleteFile={this.state.routeApiDeleteFile} 
              routeApiDeleteImage={this.state.routeApiDeleteImage} 
              routeApiDeleteImageFile={this.state.routeApiDeleteImageFile} 
              routeApiActions={this.state.routeApiActions} 
              numberFiles={this.state.numberFiles} 
              numberImages={this.state.numberImages} 
              readOnly={this.state.readOnly} 
              options={this.state.options}
              informativeHidden={this.state.informativeHidden}
              autoSave={this.state.autoSave}
              dataExcludeParentComponent={this.state.dataExcludeParentComponent}
              dataIncludeParentComponent={this.state.dataIncludeParentComponent}
              initialFetch={this.state.initialFetch}
            /> 
          </CardBody>
        </Card>           
      </Container>
    );
  }
}

//export default EditVideos;
export default withRouter(EditVideos);
