/*

COMPONENTE DE LA VENTANA MODAL UTILIZADA MAYORMENTE PARA CONFIRMACIÓN DE ACCIONES DE ELIMINAR O DUPLICAR

PROPIEDADES:

  - modalType > TIPO DE LA VENTANA MODAL: confirmModal, acceptModal
  - modalTitle > TÍTULO DE LA VENTANA MODAL
  - modalText > TEXTO DE LA VENTANA MODAL
  - isOpen > VARIABLE PARA CONTROLAR SI ESTÁ ABIERTA O NO
  - modalToggle > FUNCIÓN QUE ABRE O CIERRA LA VENTANA MODAL
  - modalConfirm > FUNCIÓN A EJECUTAR CUANDO SE LE DE A CONFIRMAR
  - modalCancel > FUNCIÓN A EJECUTAR CUANDO SE LE DE A CANCELAR

FUNCIONES:

  - render > RENDERIZADO

*/
import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";

class ModalWindow extends React.Component {

    constructor(props) {
      super(props);
  
      this.state = {
        //isOpen: true
      };
    }
  
    // toggle() {
    //   this.setState({ isOpen: !this.state.isOpen });
    // };
  
    // UNSAFE_componentWillMount() {
    //   colors.forEach((color, index) => {
    //     this.setState(() => ({
    //       isOpen: false
    //     }));
    //   });
    // }
  
    // shouldComponentUpdate() {
    //   return false;
    // }
  
    render() {
      // alert("RENDERIZO MODAL");
      return (
        <Modal
            isOpen={this.props.isOpen}
            //toggle={() => this.toggle()}
            toggle={this.props.toggleFunction}
            centered
        >
            <ModalHeader toggle={this.props.toggleFunction}>
              {this.props.modalTitle}
            </ModalHeader>
            <ModalBody className="text-center m-3">
              <p className="mb-0">
                  {this.props.modalText}
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" className="mr-1" onClick={this.props.modalCancel} hidden={ (this.props.modalType=="confirmModal") ? false : true } >
                  Cancelar
              </Button>
              <Button color="primary" onClick={this.props.modalConfirm} hidden={ (this.props.modalType=="confirmModal") ? false : true } >
                  Confirmar
              </Button>
              <Button color="primary" onClick={this.props.modalAccept} hidden={ (this.props.modalType=="acceptModal") ? false : true } >
                  Aceptar
              </Button>
            </ModalFooter>

        </Modal>
      );
    }
  }
  
  export default ModalWindow;